import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = 'http://localhost:4000/api';
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {}

  //create review
  createReview(data: any): Observable<any> {
    let url = `${this.baseUrl}/create`; //mongo url
    return this.http.post(url, data).pipe(catchError(this.errorMgmt));
  }

  //getAll Reviews
  getReviews() {
    return this.http.get(`${this.baseUrl}`);
  }

  //get Review
  getReview(id: any): Observable<any> {
    let url = `${this.baseUrl}/read/${id}`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  //update Review
  updateReview(id: any, data: any): Observable<any> {
    let url = `${this.baseUrl}/update/${id}`;
    return this.http
      .put(url, data, { headers: this.headers })
      .pipe(catchError(this.errorMgmt));
  }

  //delete Review
  deleteReview(id: any): Observable<any> {
    let url = `${this.baseUrl}/delete/${id}`;
    return this.http
      .delete(url, { headers: this.headers })
      .pipe(catchError(this.errorMgmt));
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
