<div class="container my-3" style="border-radius: 25px;">
    <form [formGroup]="deleteForm" class="example-form">
<div class="row">
    <div class="col-md-12 text-center">
        <mat-icon>error</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
        <h3><b>Are you sure you want to Delete</b></h3>
        <h3><b>your account?</b></h3>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <h3>Confirm by typing "DELETE" below</h3>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>DELETE</mat-label>
            <input matInput formControlName="deleteaccount">
            <mat-error 
                            class="error-message" 
                            *ngIf="deleteForm.get('deleteaccount')?.hasError('pattern')">
                              Please enter DELETE(uppercase letters)
                            </mat-error>
          
          </mat-form-field>
    </div>
</div>
<div class="row">
    <div style=" margin-bottom: 40px" class="col-md-12 example-button">
        
            <button type="button" class="btn btn-lg btn-outline-dark w-100" (click)="onCancel()">Cancel</button>
            <button class="btn btn-lg btn-danger w-100" 
            type="submit" 
            (click)="onDelete()"  
            [disabled]="!deleteForm.valid">Delete</button> 
        
      </div>
</div>
</form>
</div>