import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pauseaccount',
  templateUrl: './pauseaccount.component.html',
  styleUrl: './pauseaccount.component.css'
})
export class PauseaccountComponent {
  constructor(private dialogRef: MatDialog){}
  onPause(){
    this.dialogRef.closeAll();
  }

  onCancel(){
    this.dialogRef.closeAll();
  }
}
