<div class="container" style="padding: 20px;">
  <div class="row">
    <div class="col-sm-12" style="text-align: center;">
      <span class="material-icons-outlined" style="text-align: center;">
        error_outline
        </span>
      <h1>Report this Review</h1>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-sm-12">
  <form [formGroup]="reportReviewForm" (ngSubmit)="onreportFormSubmit()">
    <mat-form-field appearance="outline" class="example-full-width custom-field">
      <mat-label>Reason for reporting this review</mat-label>
      <input matInput placeholder="Enter your reason here" formControlName="reportReason">
    </mat-form-field>
    <button mat-stroked-button
    type="button" mat-dialog-close
    class="cancel-but">
    Cancel
    </button>
    <button
    mat-raised-button
    type="submit"
    class="submit-reply"
    mat-dialog-close
    >
    Submit
    </button>
  </form>
</div>
</div>
</div>
