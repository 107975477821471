import { animate, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GalleryService } from 'src/app/_services/gallery.services';

interface Item {
  galleryImageUrl: string;
  galleryDescription: string;
  imageAlt: string;
  likesCount: any;
  mediaType: any;
}
@Component({
  selector: 'app-gallery-lightbox',
  templateUrl: './gallery-lightbox.component.html',
  styleUrl: './gallery-lightbox.component.css',
  animations:[
    trigger('animation', [
      transition('void => visible', [
        style({tranform: 'scale(0.5)'}),
        animate('150ms', style({transform:'scale(1)'}))
      ]),
      transition('visible => void', [
        style({tranform: 'scale(1)'}),
        animate('150ms', style({transform:'scale(0.5)'}))
      ]),
    ]),
    trigger('animation2' ,[
      transition(':leave',[
        style({opacity: 1}),
        animate('50ms', style({opacity:0.8}))
      ])
  ])
]
})
export class GalleryLightboxComponent {
@Input() galleryData: Item[]= [];
@Input() showCount = false;
@Input() profileid: string;
@Output() onImageChange = new EventEmitter<any>();



previewImage = false;
showMask = false;
currentLightboxImage: Item = this.galleryData[0];
currentIndex = 0;
controls = true;
totalImageCount= 0;
value: any;
gallery: any[] = [];


constructor(public galleryService: GalleryService,
  public router: Router,
){
}

ngOnInit(): void {
this.totalImageCount = this.galleryData?.length
}

onPreviewImage(index: number): void{
  this.showMask = true;
  this.previewImage = true;
this.currentIndex = index;
this.currentLightboxImage = this.galleryData[index]
}

onAnimationEnd(event: AnimationEvent){
  if(event.toState === 'void'){
    this.showMask = false;
  }
}

onClosePreview(){
  this.previewImage = false;
}

next():void{
this.currentIndex = this.currentIndex +1;
if(this.currentIndex > this.galleryData.length - 1)
  {
    this.currentIndex = 0;
  }
  this.currentLightboxImage = this.galleryData[this.currentIndex]
}

prev():void{
this.currentIndex = this.currentIndex -1;
if(this.currentIndex < 0){
  this.currentIndex = this.galleryData?.length - 1;
}
this.currentLightboxImage = this.galleryData[this.currentIndex]

}

getGalleryImages() {
  this.galleryService
    .getListingGalleryById(this.profileid)
    .subscribe((data: any) => {
      this.gallery = data?.items;
    });
}

likeImage(data: any) {
this.onImageChange.emit(data);

  }

}

