<div class="gallery-lightbox-container">
  <div class="gallery">
    <div *ngFor="let data of taggedData; let i= index" class="gallery-img">
      <div *ngIf="data.mediaType == 'imageUpload'">
        <img (click)="onPreviewImage(i)" [src]="data.galleryImageUrl" [alt]="data.imageAlt" *ngIf="data.galleryImageUrl">
        <img (click)="onPreviewImage(i)" [src]="data.imageUrl" [alt]="data.imageAlt" *ngIf="data.imageUrl">
      </div>
        <video  style="width: 100%; margin-bottom: 30px" controls *ngIf="data.mediaType == 'videoUpload'">
          <source src="{{data.galleryImageUrl}}" type="video/mp4" *ngIf="data.galleryImageUrl">
          <source src="{{data.imageUrl}}" type="video/mp4" *ngIf="data.imageUrl">
        </video>
      <div>
    </div>
      <button mat-mini-fab  (click)="likeImage(data)" class="like-button"

      aria-label="Example icon button ">
      <span *ngIf="data.imageLikes >= 1" class="material-icons-outlined like-btn-size" style="color: red;">
        favorite
      </span>
      <span *ngIf="data.imageLikes == 0" class="material-icons-outlined like-btn-size" >
        favorite_border
      </span>
      <span *ngIf="data.imageLikes">{{data.imageLikes}}</span>
      <span *ngIf="data.likesCount >= 1" class="material-icons-outlined like-btn-size" style="color: red;">
        favorite
      </span>
      <span *ngIf="data.likesCount == 0" class="material-icons-outlined like-btn-size" >
        favorite_border
      </span>
      <span *ngIf="data.likesCount">{{data.likesCount}}</span>
    </button>
    </div>
  </div>
  <div *ngIf="showMask" class="lightbox" @animation2>
    <span *ngIf="showCount" class="count">{{currentIndex + 1}}/{{totalImageCount}}</span>
    <button *ngIf="previewImage" class="close-btn" (click)="onClosePreview()">
      <i class="far fa-times"></i>
    </button>
    <button *ngIf="controls" class="btn-lightbox-carousel btn-prev" (click)="prev()">
      <i class="fas fa-arrow-circle-left icon-lightbox-carousel icon-prev"></i>
    </button>
    <button *ngIf="controls" class="btn-lightbox-carousel btn-next" (click)="next()">
      <i class="fas fa-arrow-circle-right icon-lightbox-carousel icon-next"></i>

    </button>

    <div *ngIf="previewImage"
    [@animation]="{value: 'visible'}"
    (@animation.done)="onAnimationEnd($event)"
    class="lightbox-img">
      <img [src]="currentLightboxImage.galleryImageUrl" [alt]="currentLightboxImage.imageAlt" *ngIf="currentLightboxImage.galleryImageUrl" style="object-fit: contain;">
      <img [src]="currentLightboxImage.imageUrl" [alt]="currentLightboxImage.imageAlt" *ngIf="currentLightboxImage.imageUrl" style="object-fit: contain;">
      <div class="container" style="width: 400px;">
      <div style="font-size: larger; color: #fff; margin-top: -50px;" class="scroll-gallery">
        <span style="background-color: rgba(0, 0, 0, 0.575); padding: 5px; line-height: 1.6; border-radius: 5px; width: 920px;" *ngIf="currentLightboxImage.galleryDescription">{{currentLightboxImage.galleryDescription}}</span>
        <span style="background-color: rgba(0, 0, 0, 0.575); padding: 5px; line-height: 1.6; border-radius: 5px; width: 920px;" *ngIf="currentLightboxImage.imageDescription">{{currentLightboxImage.imageDescription}}</span>
      </div>
      </div>
    </div>
  </div>
  </div>
