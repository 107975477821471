import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrl: './deleteaccount.component.css'
})
export class DeleteaccountComponent {
  constructor(private _formBuilder: FormBuilder, private router: Router, private dialogRef: MatDialog) {}
  buildForm() {
    this.deleteForm;
    this.deleteForm.valueChanges.subscribe(() => this.onValueChanged());
  }


  // public validation_messages = {
  //   delet: [{ type: 'required', message: 'First Name is required' }],
  // }

  deleteForm = this._formBuilder.group({
    deleteaccount: new FormControl(
      '',
      Validators.compose(
        [
          Validators.required,
          Validators.pattern('DELETE')
        ]
      )
    )
  })

  private onValueChanged() {
    if (!this.deleteForm) {
      return;
    }
  }


  onDelete() {
    this.dialogRef.closeAll();
  }

  onCancel(){
    this.dialogRef.closeAll();
  }
}
