import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { IListingInformations } from '../_models/listingInformation.model';

@Injectable({
  providedIn: 'root',
})
export class CustomersInfoService {
  constructor(private httpClient: HttpClient) {}



  //getAll listings
  getAllCustomers() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getCustomer(customerId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getCustomerByCustomerID(customerId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/customer/profile/details?customerId=${customerId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
