<div class="conatiner" style="padding: 20px; background-color: #fff;">
  <mat-dialog-content>
  <div class="row"  style="padding-top: 15px; padding-bottom: 15px; ">
    <div class="col col-9">
      <h1 *ngIf="savedByUserType==='Customer'"><b>Contact {{displayCustomerName}}</b></h1>
      <h1 *ngIf="savedByUserType==='Provider'"><b>Contact {{displayProviderName}}</b></h1>
    </div>
    <div class="col col-3">
      <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

<form [formGroup]="sendMessageForm" (ngSubmit)="onMessageFormSubmit()" class="my-4">
  <div class="row">
    <p *ngIf="savedByUserType === 'Customer'" style="margin-left: 10px;">Customer Name</p>
    <p *ngIf="savedByUserType === 'Provider'" style="margin-left: 10px;">Listing Name</p>
<mat-form-field appearance="outline">
  <input matInput formControlName="firstName" readonly>
</mat-form-field>
<p style="margin-left: 10px;">Subject</p>
<mat-form-field appearance="outline">
  <input matInput formControlName="subjectContent" placeholder="Enter subject">
  <mat-error *ngIf="sendMessageForm.controls['subjectContent'].hasError('required')">
    Subject field is required.
  </mat-error>
</mat-form-field>
<p style="margin-left: 10px;">Message</p>
<mat-form-field appearance="outline">
  <textarea matInput formControlName="messageContent" placeholder="Enter Message"></textarea>
  <mat-error *ngIf="sendMessageForm.controls['messageContent'].hasError('required')">
    Message field is required.
  </mat-error>
</mat-form-field>
</div>
<button mat-raised-button *ngIf="!sendMessageForm.valid" 
style="float: right; border-radius: 10px; width:150px; height: 50px;" type="submit" [disabled]="!sendMessageForm.valid" mat-dialog-close>
Submit
</button>
<button mat-raised-button *ngIf="sendMessageForm.valid"  style="float: right; border-radius: 10px; width:150px; height: 50px;
 background-color: #525EB1; color:#fff" type="submit" mat-dialog-close>
Submit
</button>
<button mat-raised-button type="button" mat-dialog-close style="float: right; border-radius: 10px; width:150px; height: 50px;" class="mx-2">
Cancel
</button>
</form>
</mat-dialog-content>
</div>
