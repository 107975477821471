import { Component, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Reviews } from '../../../_models/review.model';

import { ApiService } from '../../../_services/reviews.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-review-edit',
  templateUrl: './review-edit.component.html',
  styleUrls: ['./review-edit.component.css'],
})
export class ReviewEditComponent {
  submitted = false;
  editForm: FormGroup | any;
  reviewsData: Reviews[] = [];
  ReviewRating: any = ['1', '2', '3', '4', '5'];
  selectedRating = 1;

  constructor(
    // public formBuilder: FormBuilder,
    // private actRoute: ActivatedRoute,
    // private router: Router,
    // private apiService: ApiService
  ) {}

  // ngOnInit() {
  //   this.updateReview();
  //   let id = this.actRoute.snapshot.paramMap.get('id');
  //   this.getReview(id);
  //   this.editForm = this.formBuilder.group({
  //     salonName: ['', [Validators.required]],
  //     address: ['', [Validators.required]],
  //     phone: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
  //     message: ['', [Validators.required]],
  //     rating: ['', [Validators.required]],
  //   });
  // }

  // updateRating(e: any){
  //   this.editForm.get('rating')?.setValue(e, {onlySelf: true})
  // }

  // updateRating(data: any) {
  //   this.selectedRating = data;
  //   const x = this.editForm.get('rating');
  //   if (x) {
  //     x.setValue(data, {
  //       onlySelf: true,
  //     });
  //   }
  // }

  // get myForm() {
  //   return this.editForm.controls;
  // }

  // getReview(id: any) {
  //   this.apiService.getReview(id).subscribe((data) => {
  //     this.editForm.setValue({
  //       salonName: data['salonName'],
  //       phone: data['phone'],
  //       rating: data['rating'],
  //       address: data['address'],
  //       message: data['message'],
  //       date: data['date'],
  //     });
  //   });
  // }
  // updateReview() {
  //   this.editForm = this.formBuilder.group({
  //     salonName: ['', [Validators.required]],
  //     address: ['', [Validators.required]],
  //     phone: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
  //     message: ['', [Validators.required]],
  //     rating: ['', [Validators.required]],
  //   });
  // }

  // onSubmit() {
  //   this.submitted = true;
  //   if (!this.editForm.valid) {
  //     return false;
  //   } else {
  //     if (window.confirm('Are you sure to update?')) {
  //       let id = this.actRoute.snapshot.paramMap.get('id');
  //       this.apiService.updateReview(id, this.editForm.value).subscribe(
  //         (res) => {
  //           this.router.navigateByUrl('/reviews');
  //           console.log('data is updated');
  //         },
  //         (error) => {
  //           console.log(error);
  //         }
  //       );
  //     }
  //   }
  // }
}
