import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services/user.service';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { CoreService } from './../../../_services/core.services';

@Component({
  selector: 'app-read-more-dialog',
  templateUrl: './read-more-dialog.component.html',
  styleUrl: './read-more-dialog.component.css'
})
export class ReadMoreDialogComponent {
  businessid: string;
  listings: any;
  profile: any;
  selectedTab: string = 'Profile';


  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any, private listingInformationService: ListingInformationService,
    private coreService: CoreService) {

  }
  ngOnInit() {
    this.profile = this.data;
  }
}
