<div mat-mdc-dialog-surface mdc-dialog__surface>
<div class="container-fluid" style="padding-top: 10px; background-color: #fff;">

    <mat-dialog-content>
        <div class="row">
            <div class="col-md-9 ">
                <h1>{{profile?.boxType}}</h1>
            </div>
            <div class="col-md-3">
                <button mat-mini-fab mat-dialog-close class="dialog-close-button" style="float: right; background-color: #fff; color: #333333;">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div>
            <p [innerHTML]="profile?.profileData | lineBreak" style="margin-top: 20px;">
            </p>
        </div>

    </mat-dialog-content>

</div>
</div>
