import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { ICategory } from '../_models/category.model';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(private httpClient: HttpClient) { }

  //create Categories
  signup(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/publicUri/businessprovider/create`, obj, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  checkUserNameExists(userName: string) {
    return this.httpClient.get<boolean>(`${environment.apiUrl}/publicUri/businessprovider/existing-user/check?userName=${userName}`);
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
