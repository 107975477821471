import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreService } from './../../../_services/core.services';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReviewService } from 'src/app/_services/review.services';
import { UserService } from 'src/app/_services/user.service';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-submit-review',
  templateUrl: './submit-review.component.html',
  styleUrl: './submit-review.component.css',
})
export class SubmitReviewComponent {
  starCountQuality: any = 5;
  ratingQuality: any = 0;
  starCountPrice: any = 5;
  ratingPrice: any = 0;
  starCountOverall: any = 5;
  ratingOverall: any = 0;
  starCountServices: any = 5;
  ratingServices: any = 0;
  starCountPunctuality: any = 5;
  ratingPunctuality: any = 0;
  starColor: StarRatingColor = StarRatingColor.accent;
  starColorP: StarRatingColor = StarRatingColor.primary;
  starColorW: StarRatingColor = StarRatingColor.warn;


  currentRate = 0;
  reviews: any;
  listings: any;
  businessid: any;
  listingid: any;
  listingTitle: any;
  addReviewForm: FormGroup;
  listingName: string;
  customerUserName: string;
  userInfo: any;
  listingAddress:string;


  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
    public _formBuilder: FormBuilder,
    public reviewService: ReviewService,
    private coreService: CoreService,
    private snackBar: MatSnackBar,

  ) {
  }

  ngOnInit() {
    this.mainForm();
    this.userInfo = this.coreService.getUserDetails();
    this.businessid = this.coreService.getBusinessId();
    this.customerUserName = this.userInfo?.username;
    this.setListingAddress();
    this.listingid = this.data.listingId;
    this.listingName = this.listings?.filter(
      (e: any) => e.id == this.listingid)[0].listingName;
    this.listings?.forEach((e: any) => {
      if(e.id === this.listingid){
        this.listingAddress = `${e.cityName} ${e.state}`
      }
    })
    this.readReview();
  }

  setListingAddress(){

    let sortedDataList = this.data.listingData.map((element:any) => {
      if(element.cityName && this.isValidJSON(element.cityName)){
        element.cityName = JSON.parse(element.cityName).name
      }
      if(element.state && this.isValidJSON(element.state)){
        element.state = JSON.parse(element.state).name
      }
      if(element.country && this.isValidJSON(element.country)){
        element.country = JSON.parse(element.country).name
      }
      return element;
    });
    this.listings = sortedDataList;
  }
isValidJSON(str:any) {
try {
  JSON.parse(str);
  return true;
} catch (e) {
  return false;
}
}

  // files: any;
  // fileName: string;
  // getFile(e: any) {
  //   this.files = e;
  //   this.fileName = e.name;
  // }

  files: any[] = [];
//fileName: any[] = [];
getFile(e: any) {
  this.files.push(e);
 // this.fileName.push(e.name);
}

  mainForm() {
    this.addReviewForm = this._formBuilder.group({

      reviewTitle: [''],
      reviewDescription: [''],

    });
  }

  readReview() {
    this.reviewService.getReview().subscribe((data) => {
      this.reviews = data;
      this.ratingPunctuality = this.reviews.punctuality
    });
  }

  onRatingChanged(rating: any, type: string) {
    switch (type) {
      case 'punctuality':
        this.ratingPunctuality = rating;
        break;
      case 'price':
        this.ratingPrice = rating;
        break;
      case 'quality':
        this.ratingQuality = rating;
        break;
      case 'services':
        this.ratingServices = rating;
        break;
      case 'overall':
        this.ratingOverall = rating;
        break;
    }
  }


  onReviewFormSubmit() {
    const category = 'review'
    const status = 'active'
    this.reviewService.createReview(
      this.businessid,
      this.customerUserName,
      this.listingid,
      this.listingName,
      this.listingAddress,
      this.addReviewForm.value.reviewTitle,
      this.ratingPunctuality,
      this.ratingServices,
      this.ratingQuality,
      this.ratingPrice,
      this.ratingOverall,
      this.addReviewForm.value.reviewDescription,
      status,
      category,
      this.files).subscribe((data: any) => {
        this.showSnackbarTopPosition('Review submitted successfully', 'Done', '2000');

      });
  }

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  onListChange(event: any) {
    this.listingid = event.value;
    // this.reviewToListingId = event.value;
    this.listingName = this.listings.filter(
      (e: any) => e.id == event.value
    )[0].listingName;
    this.listings.forEach((element:any) => {
      if(element.id === event.value){
        this.listingAddress = `${element.cityName} ${element.state}`;
      }
    });
    // this.listingAddress = this.listings.filter(
    //   (e: any) => e.id == event.value
    // )[0].;
  }


}

