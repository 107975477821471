import { HttpEventType } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
import { ListDataModal } from 'src/app/_models/list-modal';
import { CoreService } from 'src/app/_services/core.services';

@Component({
  selector: 'app-multiple-drag-and-drop-img',
  templateUrl: './multiple-drag-and-drop-img.component.html',
  styleUrls: ['./multiple-drag-and-drop-img.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultipleDragAndDropImgComponent,
      multi: true,
    },
  ],
})
export class MultipleDragAndDropImgComponent implements ControlValueAccessor, OnInit {
  @Input() businessid: string;
  @Input() listingid: string;
  @Input() category: string;
  @Input() isReadOnly: boolean;
  @Output() sendFile = new EventEmitter();

  @ViewChild('callDeleteConfirmationDialog')
  callDeleteConfirmationDialog: TemplateRef<any>;
  @ViewChild('cancelConfirmationDialog')
  cancelConfirmationDialog: TemplateRef<any>;
  @ViewChild('MultifileDropRef')
  MultifileDropRef: ElementRef<any>;

  data: ListDataModal = {} as ListDataModal;
  public files: any[] = [];
  listOfFiles: string[] = [];
  fileToBeDeleted: string;
  displayedColumns: string[] = ['fileName', 'action'];
  galleryimage: any[] = [];
  bannerimagurl: any;
  promotionimage: string = '';
  categoryType: string;
  onChange: (files: any) => void = () => {};

  constructor(
    private filesApiService: UploadFilesService,
    public dialog: MatDialog,
    public coreApiService: CoreService,
    private snackBar: MatSnackBar
  ) {}

  // Angular form control value accessor
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit() {
    this.getFilesUploaded();
  }

  displayDragAndDropArea() {
    if (this.isReadOnly) {
      return false;
    } else {
      return true;
    }
  }

  getFilesUploaded() {
    if (this.listingid) {
      this.filesApiService
        .getFiles(this.listingid)
        .subscribe((response) => {
          console.log(response);
          if(response && response?.data && response?.data?.galleryImageUrl){
            this.galleryimage = response?.data?.galleryImageUrl;
          }
          if(response && response?.data && response?.data?.promotionImageUrl){
            this.promotionimage = response?.data?.promotionImageUrl
          }
          // if(response && response?.data && response?.data?.coverUrl){
          // this.bannerImageUrl = response?.data?.coverUrl;
          // }

          // if(response && response?.data && response?.data?.coverURL){
          //   this.bannerimagurl = response?.data?.coverURL;
          // }
          // if(response && response?.data && response?.data?.profileURL){
          //      this.profileimageurl = response?.data?.profileURL;
          //   }
          //   if(response && response?.data && response?.data?.videoURL){
          //       this.videourl = response?.data?.videoURL;
          //   }
          this.handleFormValue();
        });
    }
  }

  uploadFiles(file: any, category:any) {
    this.sendFile.emit(file);
    this.getFilesUploaded();

  }

  onFileDropped(file: any,category?:any) {
    this.prepareFilesList(file,category);
  }

  fileBrowseHandler(e: any, category?:any) {
    const file= e.target.files;

    if (file.length === 0)return;

    for(let i = 0; i< file.length; i++){
      const reader = new FileReader();
    reader.readAsDataURL(file[i]);
    reader.onload = (_event) => {
        this.galleryimage.push(reader.result);
    }
    }

    this.prepareFilesList(file, category);
  }


  prepareFilesList(files: Array<any>, category?:any) {
    let fileUploadSizeExceeds = false;
    let fileUploadSizeFailedList = '';
    for (const item of files) {
      item.completed = false;
      if (Number(item.size) < 8e+11) {
        this.files.push(item);
        this.uploadFiles(item,category);
      } else {
        if (fileUploadSizeExceeds) {
          fileUploadSizeFailedList += ',  ';
        }
        fileUploadSizeFailedList += item.name;
        fileUploadSizeExceeds = true;
      }
    }
    if (fileUploadSizeExceeds) {
      let snackBarMessage =
        fileUploadSizeFailedList +
        ' is greater than 10 MB. Please upload file less than 10 MB ';
      this.snackBar.open(snackBarMessage, 'X', {
        duration: 7 * 1000,
        panelClass: ['error-snackbar'],
      });
    }
    this.handleFormValue();
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 100240;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // openDeleteConfirmationDialog(listingid: string) {
  //   this.fileToBeDeleted = listingid;
  //   let dialogRef = this.dialog.open(this.callDeleteConfirmationDialog);

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result !== undefined) {
  //       if (result === 'yes') {
  //         this.filesApiService
  //           .deleteFile(this.listingid)
  //           .subscribe((response) => {
  //             if (response.result === true) {
  //               let dialogRef2 = this.dialog.open(
  //                 this.cancelConfirmationDialog
  //               );
  //               dialogRef2.afterClosed().subscribe((result) => {
  //                 if (result === undefined) {
  //                   this.getFilesUploaded();
  //                 }
  //               });
  //               this.handleFormValue();
  //             }
  //           });

  //         for (const i in this.files) {
  //           if (listingid === this.files[i].name) {
  //             this.files.splice(Number(i), 1);
  //           }
  //         }
  //       }
  //     }
  //   });
  // }

  handleFormValue() {
    if (this.files.length + this.data.total > 0) {
      this.onChange(this.files.length + this.data.total);
    } else {
      this.onChange(null);
    }
  }

  delImg(){
    this.galleryimage = [];
  }

}
