<div class="container">
  <div class="row">
    <div class="col-md-6" style="padding-left: 100px; padding-right: 100px">
      <span
        style="
          float: left;
          margin-top: 40px;
          margin-left: -120px;
          text-align: center;
          display: flex;
          align-items: center;
          cursor: pointer;
        "
        ><mat-icon>arrow_back_ios</mat-icon> <b> Back</b></span
      >
      <div class="logo text-center">
        <img src="./././assets/qupstyle-logo.png" class="my-4 login-logo" />
      </div>
      <div class="col-md-12 text-center">
        <span class="mx-y"
          ><h1><b>Business Sign In</b></h1></span
        >
        <p class="para-style">Welcome back! Please enter your details</p>
      </div>

      <div class="my-4">
        <form [formGroup]="loginForm" class="example-form">
          <div class="col-md-12">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Email</mat-label>
              <input
                matInput
                formControlName="emailAddress"
                placeholder="Enter your email address"
                required
              />
              <mat-error
                *ngFor="let validation of validation_messages.emailAddress"
              >
                <mat-error
                  class="error-message"
                  *ngIf="
                    loginForm.get('emailAddress')?.hasError(validation.type) &&
                    (loginForm.get('emailAddress')?.dirty ||
                      loginForm.get('emailAddress')?.touched)
                  "
                >
                  {{ validation.message }}</mat-error
                >
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-12">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Password</mat-label>
              <input
                matInput
                formControlName="password"
                placeholder="Enter your Password"
                required
              />
              <mat-error
                *ngFor="let validation of validation_messages.password"
              >
                <mat-error
                  class="error-message"
                  *ngIf="
                    loginForm.get('password')?.hasError(validation.type) &&
                    (loginForm.get('password')?.dirty ||
                      loginForm.get('password')?.touched)
                  "
                >
                  {{ validation.message }}</mat-error
                >
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-slide-toggle>Remember me</mat-slide-toggle>
            </div>
            <div class="col-md-6">
              <!-- <a href="" style="float: right;">Forgot Password?
        </a> -->
              <a
                routerLink="/forgot-password"
                routerLinkActive="active"
                ariaCurrentWhenActive="page"
                style="float: right"
                ><b> Forgot Password?</b></a
              >
            </div>
          </div>
          <button
            mat-raised-button
            class="my-4 example-full-width"
            style="height: 50px; background-color: #525EB1; color: #fff;"
            (click)="logIn()"
            [disabled]="!loginForm.valid"
          >
            Sign In
          </button>
        </form>
        <div
          class="row"
          style="text-align: center; display: flex; align-items: center"
        >
          <div class="col-md-4">
            <mat-divider></mat-divider>
          </div>
          <div class="col-md-4">or sign in with</div>
          <div class="col-md-4">
            <mat-divider></mat-divider>
          </div>
        </div>
        <div>
          <button mat-stroked-button  class="my-4 btn-style">
            <img src="./././assets/images/google-icon.png" />
            <span class="mx-3">Google</span>
          </button>
          <button mat-stroked-button  class="btn-style">
            <img src="./././assets/images/facebook-icon.png" />
            <span class="mx-3">Facebook</span>
          </button>
          <p style="text-align: center" class="my-3">
            Don’t have an account?
            <a
              routerLink="/signup"
              routerLinkActive="active"
              ariaCurrentWhenActive="page"
              ><b> Sign up here</b></a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="vl col-md-1"></div> -->
    <div class="col">
      <img
        src="./././assets/images/signin-img.png"
        style="width: 100%; height: 100vh"
      />
    </div>
  </div>
</div>
