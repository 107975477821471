<div class="container-fluid">
  <div class="row" style="margin-top: 10px">
    <div class="col col-9">
      <h1>Reviews</h1>
    </div>
    <div class="col col-3">
      <button
        mat-mini-fab
        mat-dialog-close
        style="float: right; background-color: #fff; color: #333333"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-card class="card-style1" style="margin-top: 45px;">
    <div class="row" style="padding: 10px">
      <div class="col-md-3">
        <img src="../../../assets/images/grey.jpg" alt="Avatar" style="height: 120px; width: 120px; border-radius: 10px; float: left; object-fit: cover;"  *ngIf="!reviewData?.customerProfileUrl"/>
        <img
        *ngIf="reviewData?.customerProfileUrl"
          src="{{ reviewData?.customerProfileUrl }}"
          alt="Avatar"
          style="
            height: 120px;
            width: 120px;
            border-radius: 10px;
            object-fit: contain;
            background-color: #f0f2f5;
          "
        />
        <h1 class="txt-captial">{{ reviewData?.customerUserName }}</h1>
        <p style="display: flex; align-items: center; color: #7d7d7d">
          <mat-icon class="material-icons-outlined"> location_on </mat-icon
          ><span
            >{{reviewData?.listingAddress}}</span
          >
        </p>
        <p style="display: flex; align-items: center; color: #7d7d7d">
          <mat-icon class="material-icons-outlined"> phone </mat-icon
          >&nbsp;&nbsp;&nbsp; {{ reviewData?.listingPhoneNumber }}
        </p>
        <p style="display: flex; align-items: center; color: #7d7d7d">
          <mat-icon class="material-icons-outlined"> storefront </mat-icon
          ><span style="text-align: left; padding-left: 10px">{{
            reviewData?.listingName
          }}</span>
        </p>
      </div>
      <div class="col-sm-6" style="padding-right: 110px">
        <h2>"{{ reviewData?.reviewTitle }}"</h2>
        <p [innerHTML]="formattedReviewDescription"></p>
        <div class="row">
          <div
            class="col-sm-3"
            *ngFor="
              let reviewImageUrls of reviewData?.reviewImageUrls
            "
          >
            <img
              src="{{reviewImageUrls}}"
              class="review-img"
              style="background-color: #f0f2f5;"
            />
          </div>
        </div>
        <div class="col-sm-12">
          <mat-chip
            style="
              align-items: center;
              display: inline;
              float: right;
              padding: 5px;
              background-color: #f4f5ff;
              margin-top: 10px;
            "
            >{{ reviewData.createdDate | date : "dd-MMM-yyyy" }}</mat-chip
          >
        </div>
      </div>

      <div class="col-sm-3">
        <app-overall-star
                [rating]="reviewData?.overall"
                [starCount]="starCount"
                [color]="starColor"
                [isReviewShow] = true
                [reviews]="reviewData?.Overall"
                ></app-overall-star>
                <mat-divider></mat-divider>
        <p style="display: flex; align-items: center;"><span class="reviwscss">Punctuality</span>
          <app-star-rating
          [rating]="reviewData?.punctuality"
          [starCount]="starCount"
          [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex;  align-items: center; "><span class="reviwscss">Service</span>
          <app-star-rating
          [rating]="reviewData?.service"
          [starCount]="starCount"
          [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex;  align-items: center;"><span class="reviwscss">Quality</span>
          <app-star-rating
          [rating]="reviewData?.quality"
          [starCount]="starCount"
          [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex;  align-items: center;"><span class="reviwscss">Price</span>
          <app-star-rating
          [rating]="reviewData?.price"
          [starCount]="starCount"
          [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex;  align-items: center;"><span class="reviwscss">Overall</span>
          <app-star-rating
          [rating]="reviewData?.overall"
          [starCount]="starCount"
          [color]="starColor"
          ></app-star-rating>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="row">
      <div class="col-sm-2 my-4" style="text-align: center">
        <!-- <img
          src="../../../assets/images/grey.jpg"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="!reviewData?.listingProfileUrl"
        /> -->

        <img
          src="{{ reviewData?.listingProfileUrl }}"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="reviewDetails"
        />
        <p *ngIf="reviewDetails">{{ reviewData?.listingName }}</p>
      </div>
      <div class="col-sm-10 my-4">
        <div>
          <p class="txt-captial" *ngIf="reviewDetails">
            <b>Reply to {{ reviewData?.customerUserName }}</b>
          </p>
          <!-- <h3>{{reviewDetails?.replyContent}}</h3> -->
          <p [innerHTML]="formattedReplyContent"></p>
          <!-- <span><mat-icon *ngIf="reviewDetails?.replyContent" style="float: right; display: flex; align-items: center;">more_vert</mat-icon></span> -->
        </div>
      </div>
    </div>
  </mat-card>
</div>
