import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReviewService } from 'src/app/_services/review.services';

@Component({
  selector: 'app-review-report-dialog',
  templateUrl: './review-report-dialog.component.html',
  styleUrl: './review-report-dialog.component.css'
})
export class ReviewReportDialogComponent {


  reportReviewForm: FormGroup;


  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
  private reviewService: ReviewService,
  public formBuilder: FormBuilder,
  private snackBar: MatSnackBar,
    ){

  }

  ngOnInit(){
    this.mainForm();
  }


  mainForm() {
    this.reportReviewForm = this.formBuilder.group({
      reportReason: [''],
    });
  }


  onreportFormSubmit(){
    let obj =  {
      reviewId: this.data.reviewId,
      reportReason: this.reportReviewForm.value.reportReason,
      reported: true,
    }
    this.reviewService.reportReview(obj).subscribe((data)=>{
      this.showSnackbarTopPosition('Review Reported successfully', 'Done', '1000');
    })
  }
  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "right"
    });
  }

}
