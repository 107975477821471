import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest, } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import { Reviews } from '../_models';


@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor(private httpClient: HttpClient) {}

  createReview(
    reviewFromCustomerId: string,
    customerUserName: string,
    reviewToListingId: string,
    listingName: string,
    listingAddress: string,
    reviewTitle: string,
    punctuality: string,
    service: string,
    quality: string,
    price: string,
    overall: string,
    reviewDescription: string,
    status: string,
    category: string,
    files: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    for(let i = 0;i< files.length;i++){
      formData.append('files', files[i]);
    }
    formData.append('reviewFromCustomerId', reviewFromCustomerId);
    formData.append('customerUserName', customerUserName);
    formData.append('reviewToListingId', reviewToListingId);
    formData.append('listingName', listingName);
    formData.append('listingAddress', listingAddress);
    formData.append('reviewTitle', reviewTitle);
    formData.append('punctuality', punctuality);
    formData.append('service', service);
    formData.append('quality', quality);
    formData.append('price', price);
    formData.append('overall', overall);
    formData.append('reviewDescription', reviewDescription);
    formData.append('status', status);
    formData.append('category', category);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/review`,
      formData
    );

    return this.httpClient.request(req);
  }

  getReview() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/review/received/provider`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getUnreadReview(){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/review/received/provider?reviewRead=Unread`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  reviewsByListing(listingId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/publicUri/review/received/provider/listing?listingId=${listingId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  customerReviews(customerId: string){
    return this.httpClient
      .get<any>(`${environment.apiUrl}/review/submitted/customer/profile?customerId=${customerId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  deleteReviewReply(reviewreplyId: string){
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/review/reply/${reviewreplyId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getSortedReview(listingId: string) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/review/received/provider/listing?listingId=${listingId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
  reviewById(reviewId: string){
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
    .get<any>(`${environment.apiUrl}/reviewbyid/${reviewId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }



  updateReviewStatusReadById(reviewId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/review/update/read/${reviewId}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  reportReview(obj: any){
    return this.httpClient
      .put<any>(`${environment.apiUrl}/review/report`, obj)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  sendUsefullReview(reviewId: string, useful: boolean){
    return this.httpClient
      .put<any>(`${environment.apiUrl}/review/useful?reviewId=${reviewId}&useful=${useful}`, {reviewId, useful})
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  sendReviewReply(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/review/reply/create`,obj , { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateReviewReply(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/review/reply`, obj , { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getReviewReply(reviewreplyId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/publicUri/review/reply?reviewId=${reviewreplyId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  reviewsByCustomer(){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/review/submitted/customer`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  updateReview(id: any, reviewInfo: Reviews): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/promotions`,  { reviewInfo, id }, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }



  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
