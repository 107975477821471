<!-- <app-nav-bar class="nav"></app-nav-bar> -->
<div class="container-fluid" style="background-color: #f0f2f5">
  <mat-card
    *ngIf="profile?.coverURL"
    class="banner-style"
    style="background-image: url({{profile?.coverURL}});
    height: 500px;
    width: 100%;
    object-fit: cover;
    z-index: 98"
  >
  </mat-card>
  <div *ngIf="!profile?.coverURL"></div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-3" style="z-index: 100" *ngIf="profile?.coverURL">
      <img class="image-style" [src]="profile?.profileURL" />
    </div>
    <div
      class="col-md-3"
      style="z-index: 100; margin-top: 80px"
      *ngIf="profile?.profileURL && !profile?.coverURL"
    >
      <img
        class="image-style"
        [src]="profile?.profileURL"
        style="object-fit: content; background-color: #f1f0f5"
      />
    </div>
    <div class="col-md-6 my-4 profileInfo">
      <h1>
        <span class="listing-name">{{ profile?.listingName }}</span
        >&nbsp;
        <span class="user-name">&#64;{{ profile?.listingUserName }}</span>
      </h1>
      <p
        style="font-size: 16px; font-weight: 500"
        class="details tagline-style"
      >
        {{ profile?.tagLine }}
      </p>
      <p
        class="details add-details"
        *ngIf="!profile?.countryCodeNoAddressLine1"
      >
        {{ profile?.addressLine1 }},&nbsp;{{ profile?.cityName }},&nbsp;{{
          profile?.state
        }},&nbsp;{{ profile?.country }}&nbsp;<img
          *ngIf="!profile?.countryCodeNoAddressLine1"
          alt="Country Flag"
          [src]="profile?.flag"
          style="width: 25px; height: 15px"
        />
      </p>
      <p class="details add-details" *ngIf="profile?.countryCodeNoAddressLine1">
        {{ profile?.addressLine1 }}{{ profile?.cityName }},&nbsp;{{
          profile?.state
        }},&nbsp;{{ profile?.countryCodeNoAddressLine1 }}&nbsp;<img
          *ngIf="profile?.countryCodeNoAddressLine1"
          alt="Country Flag"
          [src]="profile?.flag"
          style="width: 25px; height: 15px"
        />
      </p>
      <div class="details">
        <button
          *ngFor="let pro of newHashTags"
          mat-stroked-button
          class="my-1"
          style="margin-right: 10px; text-transform: uppercase"
        >
          {{ pro }}
        </button>
      </div>
    </div>
    <div class="col-md-2 icon-shift" *ngIf="profile?.profileURL">
      <div class="icon-style">
        <div class="example-button-container">
          <button
            mat-mini-fab
            class="icon-btn-style"
            [matMenuTriggerFor]="menu"
            class="icon-btn-style mx-4"
          >
            <span class="material-icons" style="color: #525eb1"> share </span>
          </button>
        </div>
        <mat-menu #menu="matMenu">
          <button (click)="copyLink()" mat-menu-item>
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_10372_472052)">
                <path
                  d="M12.6328 10.6875L12.6328 12.9375C12.6328 13.8326 12.2772 14.691 11.6443 15.324C11.0114 15.9569 10.1529 16.3125 9.25781 16.3125C8.36271 16.3125 7.50426 15.9569 6.87133 15.324C6.23839 14.691 5.88281 13.8326 5.88281 12.9375L5.88281 10.6875M5.88281 7.3125L5.88281 5.0625C5.88281 4.16739 6.23839 3.30895 6.87133 2.67601C7.50426 2.04308 8.36271 1.6875 9.25781 1.6875C10.1529 1.6875 11.0114 2.04308 11.6443 2.67601C12.2772 3.30895 12.6328 4.16739 12.6328 5.0625L12.6328 7.3125M9.25781 12.2593L9.25781 5.67035"
                  stroke="#333333"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_10372_472052">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.257812 18) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
            Copy Link
          </button>
        </mat-menu>
        <div class="example-button-container">
          <button
            id="saveListingBtn"
            mat-mini-fab
            class="icon-btn-style mx-2"
            (click)="saveListing()"
            *ngIf="businessid !== profile?.providerId && !alreadySaved?.saved"
          >
            <mat-icon class="material-icons-outlined">bookmark_border</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button
            mat-mini-fab
            class="icon-btn-style mx-2"
            disabled
            *ngIf="businessid === profile?.providerId && !alreadySaved?.saved"
          >
            <mat-icon class="material-icons-outlined">bookmark_border</mat-icon>
          </button>
        </div>

        <button
          *ngIf="alreadySaved?.saved"
          matTooltip="This listing is already saved"
          mat-mini-fab
          disabled
          class="icon-btn-style mx-4"
        >
          <span class="material-icons" style="color: #525eb1"> bookmark </span>
        </button>
      </div>
    </div>
<div class="tab-height">
    <mat-tab-group dynamicHeight>
      <mat-tab label="Home">
        <div class="row">
          <div class="col-md-3">
            <div>
              <mat-card class="card-style content-style my-4">
                <div class="row" style="display: flex; align-items: center">
                  <div class="col-md-7">
                    <app-star-rating
                      [rating]="profile?.overallReceivedReview"
                      [starCount]="starCount"
                      [color]="starColor"
                      [isReviewShow]="true"
                    ></app-star-rating>
                  </div>
                  <div class="col review-details">
                    <p class="review-details-text">
                      <u *ngIf="reviews?.length > 0"
                        >{{ reviewsCount }} Reviews</u
                      >
                    </p>
                  </div>
                </div>
                <button
                  mat-stroked-button
                  class="my-3 submit-button"
                  (click)="submitreview()"
                  disabled
                >
                  <span class="material-icons icon-star"> star_border </span
                  >&nbsp;<span class="submit-txt">Submit Review</span>
                </button>
                <mat-divider></mat-divider>
                <p
                  class="my-2"
                  style="display: flex; align-items: center"
                  *ngIf="profile?.views"
                >
                  <mat-icon class="material-icons-outlined">
                    visibility </mat-icon
                  >&nbsp;{{ profile?.views }}
                  Views
                </p>
                <p
                  class="my-2"
                  style="display: flex; align-items: center"
                  *ngIf="!profile?.views"
                >
                  <mat-icon class="material-icons-outlined">
                    visibility </mat-icon
                  >&nbsp;0 Views
                </p>
                <p
                  style="display: flex; align-items: center"
                  *ngIf="received?.length > 0"
                >
                  <span class="material-icons"> star_border </span>&nbsp;{{
                    received?.length
                  }}
                  Endorsements
                </p>
                <p
                  style="display: flex; align-items: center"
                  *ngIf="received?.length <= 0"
                >
                  <span class="material-icons-outlined"> diamond </span>&nbsp;0
                  Endorsements
                </p>
                <p
                  style="display: flex; align-items: center"
                  *ngIf="totalCustomers > 0"
                >
                  <span class="material-icons-outlined"> bookmarks </span
                  >&nbsp;{{ totalCustomers.toString().padStart(2, "0") }}
                  Saves
                </p>
                <p
                  style="display: flex; align-items: center"
                  *ngIf="!totalCustomers"
                >
                  <span class="material-icons-outlined"> bookmarks </span
                  >&nbsp;0 Saves
                </p>
                <!-- <p style="display: flex; align-items: center;">
                      <span class="material-icons-outlined mx-2">
                        share
                        </span>58 Shares
                    </p> -->
              </mat-card>
            </div>
          </div>
          <div class="col-md-9 intro-card">
            <mat-card class="card-style introduction-content my-4">
              <div class="row">
                <div class="col-md-9">
                  <h3 style="display: flex; align-items: center">
                    <mat-icon class="material-icons-outlined">
                      description </mat-icon
                    ><b class="mx-1 heading-text-style">Introduction</b>
                  </h3>
                </div>
                <div
                  class="col-md-3 button-shift"
                  *ngIf="profile?.introVideoURL || profile?.introYoutubeLink"
                >
                  <button
                    mat-stroked-button
                    class="alignment-style"
                    (click)="video()"
                  >
                    <mat-icon class="material-icons-outlined">videocam</mat-icon
                    >Watch Intro Video
                  </button>
                </div>

                <mat-divider></mat-divider>
                <mat-chip-list aria-label="Tab Selection" class="my-3">
                  <button
                    mat-stroked-button
                    style="border-radius: 50px"
                    class="mx-2"
                    [selected]="selectedTab === 'Profile'"
                    [style.background]="
                      selectedTab === 'Profile' ? '#333333' : ''
                    "
                  >
                    <a
                      (click)="changeTab('Profile')"
                      [style.color]="
                        selectedTab === 'Profile' ? '#fff' : '#333333'
                      "
                      style="display: flex; align-items: center"
                      ><span class="material-icons-outlined"> person </span
                      >&nbsp;Profile</a
                    >
                  </button>
                  <button
                    *ngIf="profile?.experience"
                    mat-stroked-button
                    style="border-radius: 50px"
                    [selected]="selectedTab === 'Experience'"
                    [style.background]="
                      selectedTab === 'Experience' ? '#333333' : ''
                    "
                  >
                    <a
                      (click)="changeTab('Experience')"
                      [style.color]="
                        selectedTab === 'Experience' ? '#fff' : '#333333'
                      "
                      style="display: flex; align-items: center"
                      ><span class="material-icons-outlined">
                        business_center </span
                      >&nbsp;Experience</a
                    >
                  </button>
                  <button
                    *ngIf="profile?.training"
                    mat-stroked-button
                    style="border-radius: 50px"
                    class="mx-2"
                    [selected]="selectedTab === 'Training'"
                    [style.background]="
                      selectedTab === 'Training' ? '#333333' : ''
                    "
                  >
                    <a
                      (click)="changeTab('Training')"
                      [style.color]="
                        selectedTab === 'Training' ? '#fff' : '#333333'
                      "
                      style="display: flex; align-items: center"
                      ><span class="material-icons-outlined">
                        model_training </span
                      >&nbsp;Training</a
                    >
                  </button>
                </mat-chip-list>
              </div>
              <!-- <p id="introduction" *ngIf="selectedTab === 'Profile'">
                    {{profile?.introduction | lineBreak}}
                  </p> -->

              <p
                [innerHTML]="
                  truncateText(profile?.introduction | lineBreak, 150)
                "
                *ngIf="selectedTab === 'Profile'"
              ></p>

              <p
                *ngIf="
                  selectedTab === 'Profile' &&
                  profile?.introduction?.length > 150
                "
                style="display: flex; align-items: center"
              >
                <span style="cursor: pointer" (click)="readmore('Introduction')"
                  ><strong>Read More</strong></span
                >
                <span class="material-icons"> navigate_next </span>
              </p>
              <!-- <p *ngIf="selectedTab === 'Experience'">
                    {{profile?.experience}}
                  </p> -->

              <p
                [innerHTML]="truncateText(profile?.experience | lineBreak, 150)"
                *ngIf="selectedTab === 'Experience'"
              ></p>
              <p
                *ngIf="
                  selectedTab === 'Experience' &&
                  profile?.experience?.length > 150
                "
                style="display: flex; align-items: center"
              >
                <span style="cursor: pointer" (click)="readmore('Experience')"
                  ><strong>Read More</strong></span
                >
                <span class="material-icons"> navigate_next </span>
              </p>
              <!-- <p *ngIf="selectedTab === 'Training'">
                    {{profile?.training}}
                  </p> -->
              <p
                [innerHTML]="truncateText(profile?.training | lineBreak, 150)"
                *ngIf="selectedTab === 'Training'"
              ></p>
              <p
                *ngIf="
                  selectedTab === 'Training' && profile?.training?.length > 150
                "
                style="display: flex; align-items: center"
              >
                <span style="cursor: pointer" (click)="readmore('Training')"
                  ><strong>Read More</strong></span
                >
                <span class="material-icons"> navigate_next </span>
              </p>
              <mat-divider></mat-divider>

              <div class="row">
                <div class="col-md-9 my-4">
                  <p
                    *ngIf="profile?.portfolioURL != ''"
                    (click)="openPortfolio(profile?.portfolioURL)"
                    mat-stroked-button
                    style="
                      display: inline-flex;
                      align-items: center;
                      color: #333333;
                      text-decoration: none;
                      border-radius: 20px;
                      border: 1px solid #ccc;
                      padding: 8px 12px;
                      cursor: pointer;
                    "
                  >
                    <span class="material-icons">link</span>&nbsp;{{
                      profile?.portfolioURL
                    }}
                  </p>
                  <p
                    *ngIf="profile?.websiteURL != ''"
                    (click)="openPortfolio(profile?.websiteURL)"
                    class="mx-2"
                    mat-stroked-button
                    style="
                      display: inline-flex;
                      align-items: center;
                      color: #333333;
                      text-decoration: none;
                      border-radius: 20px;
                      border: 1px solid #ccc;
                      padding: 8px 12px;
                      cursor: pointer;
                    "
                  >
                    <span class="material-icons">link</span>&nbsp;{{
                      profile?.websiteURL
                    }}
                  </p>
                </div>
                <div class="col-md-3 my-4 icons-style">
                  <a
                    href="{{ profile?.facebookURL }}"
                    target="_blank"
                    style="color: #333333"
                    *ngIf="profile?.facebookURL"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="float: right"
                      enable-background="new 0 0 24 24"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <rect fill="none" height="24" width="24" />
                      <path d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z"
                      /></svg></a>
                  <a
                    href="{{ profile?.twitterURL }}"
                    target="_blank"
                    style="color: #333333"
                    *ngIf="profile?.twitterURL"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      width="22px"
                      height="22px"
                      viewBox="0 0 22 22"
                      style="enable-background: new 0 0 24 24; float: right"
                      xml:space="preserve"
                    >
                      <g>
                        <polygon
                          points="12.153992,10.729553 8.088684,5.041199 5.92041,5.041199 10.956299,12.087097 11.59021,12.97345    15.900635,19.009583 18.068909,19.009583 12.785217,11.615906  "
                        />
                        <path
                          d="M21.15979,1H2.84021C1.823853,1,1,1.823853,1,2.84021v18.31958C1,22.176147,1.823853,23,2.84021,23h18.31958   C22.176147,23,23,22.176147,23,21.15979V2.84021C23,1.823853,22.176147,1,21.15979,1z M15.235352,20l-4.362549-6.213013   L5.411438,20H4l6.246887-7.104675L4,4h4.764648l4.130127,5.881958L18.06958,4h1.411377l-5.95697,6.775635L20,20H15.235352z"
                        />
                    </g></svg></a>
                  <a href="{{ profile?.linkedInURL }}"
                    target="_blank"
                    style="color: #333333"
                    *ngIf="profile?.linkedInURL"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" style="float: right" width="24" height="24"viewBox="0 0 24 24">
                      <path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"/></svg>
                    </a>
                  <a
                    (click)="openLink(profile?.instagramURL)"
                    style="color: #333333; cursor: pointer"
                    *ngIf="profile?.instagramURL"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="float: right"
                      width="24"
                      height="24"
                      viewBox="0 0 29 30"
                    >
                      <path
                        fill="currentColor"
                        d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z"
                      /></svg></a>
                  <a
                    href="{{ profile?.youtubeURL }}"
                    target="_blank"
                    style="color: #333333"
                    *ngIf="profile?.youtubeURL"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="float: right"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M15.32 4.06c-.434-.772-.905-.914-1.864-.968C12.498 3.027 10.089 3 8.002 3c-2.091 0-4.501.027-5.458.091-.957.055-1.429.196-1.867.969C.23 4.831 0 6.159 0 8.497v.008c0 2.328.23 3.666.677 4.429.438.772.909.912 1.866.977.958.056 3.368.089 5.459.089 2.087 0 4.496-.033 5.455-.088.959-.065 1.43-.205 1.864-.977.451-.763.679-2.101.679-4.429v-.008c0-2.339-.228-3.667-.68-4.438zM6 11.5v-6l5 3-5 3z"
                        clip-rule="evenodd"
                      /></svg></a>
                  <a
                    href="{{ profile?.tiktokURL }}"
                    target="_blank"
                    style="color: #333333"
                    *ngIf="profile?.tiktokURL"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="float: right"
                      width="24"
                      height="24"
                      viewBox="0 0 85 110"
                    >
                      <path
                        fill="currentColor"
                        d="M74.66,20.573c-4.218-4.904-6.428-11.241-6.253-17.693L52.643,2.5c0,0,0,0.673,0,1.579v65.887
                             c-4.244,18.913-31.616,13.978-28.876-5.265c1.529-8.79,10.972-14.198,19.365-11.141c0,0,0-16.084,0-16.084
                             C24.861,34.295,7.546,48.837,7.728,67.364c1.597,40.179,59.226,40.185,60.825,0c-0.403-1.438-0.178-28.214-0.235-30.472
                             c7.168,4.46,15.508,6.689,23.954,6.405V26.685C84.464,26.685,78.505,24.609,74.66,20.573z"
                      /></svg></a>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="container" *ngIf="featureImages?.length > 0">
          <div class="col-md-12">
            <mat-card class="card-style" style="padding: 20px">
              <div class="row" style="padding: 15px">
                <div class="col-md-4">
                  <h2 style="display: flex; align-items: center">
                    <mat-icon class="material-icons-outlined">
                      star_outline
                    </mat-icon>
                    <b class="mx-1 heading-text-style">Featured</b>
                  </h2>
                </div>
              </div>
              <drag-scroll [scroll-x-wheel-enabled]="true">

              <div class="row scroll-row feature-contnet">
                <div
                drag-scroll-item
                  class="col-sm-4 scroll-row set"
                  *ngFor="let featureImg of featureImages"
                >
                  <div
                    class="container3"
                    style="background-color: #f1f0f5; border-radius: 20px"
                  >
                    <img
                      src="{{ featureImg.galleryImageUrl }}"
                      style="object-fit: contain"
                      alt="Avatar"
                      class="image3"
                      (click)="openImageDetails(featureImg)"
                    />
                    <button
                      mat-mini-fab
                      (click)="likeImage(featureImg)"
                      style="
                        margin-top: -370px;
                        float: right;
                        margin-right: 10px;
                        background-color: #1f1f1f81;
                        color: #fff;
                      "
                      aria-label="Example icon button "
                    >
                      <span
                        *ngIf="featureImg.likesCount >= 1"
                        class="material-icons-outlined"
                        style="color: red"
                      >
                        favorite
                      </span>
                      <span
                        *ngIf="featureImg.likesCount == 0"
                        class="material-icons-outlined"
                      >
                        favorite_border
                      </span>
                      <span>{{ featureImg.likesCount }}</span>
                    </button>
                    <div>
                      <div class="overlay3">
                        {{ featureImg?.galleryDescription }}
                        <span
                          style="cursor: pointer"
                          (click)="openImageDetails(featureImg)"
                          >Read More...</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </drag-scroll>
            </mat-card>
          </div>
        </div>
        <div class="container my-4">
          <div class="row">
            <div
              class="col-md-8"
              *ngIf="activities?.length > 0 && profilePromotion?.length > 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all-both">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span
                      class="material-icons-outlined activity-icon"
                      style="float: right"
                    >
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="2"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                class="image"
                                style="object-fit: contain"
                              />
                              <div class="overlay1">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>

                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="activities?.length > 0 && profilePromotion?.length <= 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <!-- <app-heic-image></app-heic-image> -->
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>
                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-4"
              *ngIf="profilePromotion?.length > 0 && activities?.length > 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-8">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col-md-4 see-all-promotion">
                    <span
                      class="material-icons-outlined iconstyle"
                      style="float: right"
                    >
                      arrow_forward
                    </span>
                    <span
                      style="float: right; cursor: pointer; margin-top: 2px"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;&nbsp;&nbsp;</span
                    >
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="1"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="containerPromotion"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="pro-img"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="profilePromotion?.length > 0 && activities?.length <= 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-11">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="3"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="container gall-card" *ngIf="gallery && gallery.length > 0">
          <div class="row">
            <div class="col-md-12">
              <mat-card class="card-style" style="padding-bottom: 10px">
                <div class="row" style="padding: 15px">
                  <div class="col-md-2">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        collections </mat-icon
                      ><b class="mx-1 heading-text-style">Gallery</b>
                    </h2>
                  </div>
                  <div class="col-md-7"></div>
                  <div class="col-md-3 gallery-tabs">
                    <mat-chip-list aria-label="Tab Selection">
                      <button
                        (click)="changeTab1('My Posts')"
                        [style.color]="
                          selectedTab1 === 'My Posts' ? '#fff' : '#333333'
                        "
                        class="mx-2 mat-stroked-button btn-shift"
                        style="
                          border-radius: 30px;
                          height: 40px;
                          width: 100px;
                          margin: 10px;
                          cursor: pointer;
                        "
                        [selected]="selectedTab1 === 'My Posts'"
                        [style.background]="
                          selectedTab1 === 'My Posts' ? '#333333' : ''
                        "
                        *ngIf="gallery.length > 0"
                      >
                        My Posts
                      </button>

                      <button
                        (click)="changeTab1('Tagged Posts')"
                        [style.color]="
                          selectedTab1 === 'Tagged Posts' ? '#fff' : '#333333'
                        "
                        class="mx-2 mat-stroked-button btn-shift-2"
                        style="
                          border-radius: 30px;
                          height: 40px;
                          width: 140px;
                          cursor: pointer;
                        "
                        [selected]="selectedTab1 === 'Tagged Posts'"
                        [style.background]="
                          selectedTab1 === 'Tagged Posts' ? '#333333' : ''
                        "
                        *ngIf="taggedImg && taggedImg.length > 0"
                      >
                        Tagged Posts
                      </button>
                    </mat-chip-list>
                  </div>
                </div>

                <div *ngIf="selectedTab1 === 'My Posts'" class="scroll-gallery">
                  <app-gallery-lightbox
                    [galleryData]="gallery"
                    [showCount]="true"
                    [profileid]="profileid"
                    (onImageChange)="likeImage($event)"
                  >
                  <!-- (onImageChange)="onImageLike($event)" -->
                  </app-gallery-lightbox>
                </div>
                <div
                  class="row scroll-gallery"
                  *ngIf="selectedTab1 === 'Tagged Posts'"
                >
                  <app-tagged-image-lightbox
                    [taggedData]="taggedImg"
                    [showCount]="true"
                    [profileid]="profileid"
                    (onImageChange)="likeImage($event)"

                  >
                  </app-tagged-image-lightbox>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="container my-4">
          <div class="row">
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData.length > 0">
                  <mat-card class="card-style pro-given">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  class="pro-endorsement-img"
                                />
                              </div>
                              <div
                                class="col-sm-8 pro-endorsement-name"
                                style="padding: 10px"
                              >
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }},
                                  {{ endorsementGiven?.country }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of newEndorsementHashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>

                <div
                  class="col-md-4 endor-received-card"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card
                    class="card-style pro-received"
                    style="height: 475px"
                  >
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"

                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                        <b
                                        (click)="getListingDataById(msg?.id)"
                                        style="cursor: pointer"
                                        >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;

                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-4" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card
                              class="card-style"
                              style="
                                text-align: left;
                                padding: 10px;
                                height: 350px;
                              "
                            >
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />

                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name name-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div class="rating style-rating">
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p>{{ review?.reviewDescription }}</p>
                                <div class="row">
                                  <div
                                    class="col-sm-4"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      class="review-img"
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div
                  class="col-md-8"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >

                                  <div class="row">
                                    <div
                                    class="col-md-1" style="text-align:left; margin-right: 10px"
                                    >
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        1000
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      1000
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style cust-review">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card class="card-style content-review">
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}
                                        </b>
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        >
                                        </app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div
                  class="col-md-4"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style"
                                style="
                                  text-align: left;
                                  padding: 10px;
                                  height: 350px;
                                "
                              >
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}</b
                                        >
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        ></app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="givenData?.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Given</b
                        >
                      </h2>
                      <div class="search-bar-container">
                        <mat-form-field
                          appearance="outline"
                          class="search-field"
                        >
                          <input
                            id="searchQueryInput"
                            matInput
                            placeholder="Search Provider"
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="clearFilter()"
                            *ngIf="searchQuery !== ''"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Search"
                            (click)="onSearch($event)"
                            *ngIf="searchQuery === ''"
                          >
                            <mat-icon>search</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                      <div class="scroll">
                        <mat-card
                          *ngFor="let endorsementGiven of givenData"
                          style="margin-top: 10px"
                        >
                          <div class="row">
                            <div class="col-sm-4">
                              <img
                                src="{{ endorsementGiven?.profileURL }}"
                                style="
                                  border-radius: 50px;
                                  width: 100px;
                                  height: 100px;
                                  padding: 10px;
                                  border: solid 1px;
                                "
                              />
                            </div>
                            <div class="col-sm-8" style="padding: 10px">
                              <b
                                (click)="
                                  getListingDataById(endorsementGiven?.id)
                                "
                                style="cursor: pointer"
                                >{{ endorsementGiven?.listingName }}</b
                              >
                              <p>
                                {{ endorsementGiven?.cityName }},
                                {{ endorsementGiven?.state }}
                              </p>
                              <span
                                *ngFor="
                                  let hashTags of endorsementGiven?.hashTags
                                "
                              >
                                <button
                                  mat-stroked-button
                                  style="margin-bottom: 10px"
                                >
                                  {{ hashTags }}</button
                                >&nbsp;
                              </span>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="getreceivedendorsements.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Received</b
                        >
                      </h2>
                      <p-carousel
                        [value]="getreceivedendorsements"
                        [numVisible]="1"
                        [numScroll]="1"
                        [circular]="true"
                      >
                      <ng-template let-msg pTemplate="items">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style endorsementsReceivedBox"
                              >
                                <div class="row">
                                  <div
                                  class="col-md-1" style="text-align:left; margin-right: 10px"
                                  >
                                    <img
                                      src="{{ msg?.profileURL }}"
                                      style="
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                  <div class="col-md-10">
                                    <p
                                      style="text-align: left;"
                                    >
                                    <b
                                    (click)="getListingDataById(msg?.id)"
                                    style="cursor: pointer"
                                    >{{ msg?.listingName }}</b>
                                    </p>
                                    <p
                                      style="
                                        text-align: left;
                                        font-size: 13px;
                                      "
                                    >
                                      {{ msg?.cityName }}, {{ msg?.state }}
                                    </p>
                                  </div>
                                </div>
                                <p
                                  style="text-align: justify; font-size: 12px"
                                  [innerHTML]="
                                    truncateText(
                                      msg?.endorsementDescription | lineBreak,
                                      1000
                                    )
                                  "
                                ></p>
                                <p
                                  *ngIf="
                                    msg?.endorsementDescription?.length > 1000
                                  "
                                >
                                  <span
                                    style="
                                      cursor: pointer;
                                      font-size: 14px;
                                      float: left;
                                    "
                                    (click)="
                                      openReadMoreRecvdEndorsementDescritionDialog(
                                        msg?.endorsementDescription,
                                        msg?.profileURL,
                                        msg?.listingName,
                                        msg?.cityName,
                                        msg?.state
                                      )
                                    "
                                  >
                                    <strong>Read More</strong></span
                                  >
                                  <span
                                    style="float: left"
                                    class="material-icons"
                                  >
                                    navigate_next
                                  </span>
                                </p>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="col-md-12 reviews-card" *ngIf="reviews?.length > 0">
                <mat-card class="card-style cust-review">
                  <div class="row" style="padding: 12px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          auto_awesome
                        </mat-icon>
                        <b class="mx-1 heading-text-style">Customer Reviews</b>
                      </h2>
                    </div>
                  </div>
                  <p-carousel
                    [value]="reviews"
                    [numVisible]="1"
                    [numScroll]="1"
                    [circular]="true"
                  >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card class="card-style content-review">
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />
                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name2 listing-style customer-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div
                                      class="rating2 listing-rating customer-rating"
                                    >
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p (click)="openReadReviewDialog(review)">
                                  {{ review?.reviewDescription }}
                                </p>
                                <div class="row">
                                  <div
                                    class="col-sm-2"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 10px;
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
        <div class="container my-1" *ngIf="teams?.length > 0">
          <div class="row">
            <div class="col-md-12">
              <mat-card class="card-style">
                <div class="row" style="padding: 15px">
                  <div class="col-md-4">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        group </mat-icon
                      ><b class="mx-1 heading-text-style">Team</b>
                    </h2>
                  </div>
                  <div class="row scroll-row">
                    <div
                      class="col-md-2 scroll-row"
                      *ngFor="let team of teams"
                      style="text-align: center"
                    >
                      <div
                        (click)="
                          openTeamListing(team.requestToPrimaryListingId)
                        "
                      >
                        <img
                          src="{{ team?.memberPhotoURL }}"
                          style="
                            border-radius: 50px;
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            border: solid 1px;
                          "
                        />
                        <h4
                          class="text-style"
                          *ngIf="team.requestToPrimaryListingId"
                          style="cursor: pointer"
                        >
                          <b
                            >{{ team.memberFirstName }}
                            {{ team?.memberLastName }}</b
                          >
                        </h4>
                        <h4 *ngIf="!team.requestToPrimaryListingId">
                          <b
                            >{{ team.memberFirstName }}
                            {{ team?.memberLastName }}</b
                          >
                        </h4>
                        <!-- <p (click)="teamProfile(team?.requestTo)" style="display: flex; text-align: center; margin-left:58px;">VIEW<span class="material-icons">
                          arrow_right_alt
                          </span></p> -->
                        <p>{{ team?.serviceName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <div
          class="container my-4"
          *ngIf="
            profile?.faqQuestions?.length > 0 &&
            profile.faqQuestions[0].question &&
            profile.faqQuestions[0].answer
          "
        >
          <div class="row">
            <div class="col-md-12">
              <mat-card class="card-style">
                <div class="row" style="padding: 15px">
                  <div class="col-md-4">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        description </mat-icon
                      ><b class="mx-1 heading-text-style">FAQ</b>
                    </h2>
                  </div>
                </div>
                <mat-accordion>
                  <mat-expansion-panel
                    *ngFor="let pro of profile?.faqQuestions"
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title style="font-size: large">
                        {{ pro?.question }}
                      </mat-panel-title>
                      <!-- <mat-panel-description>
                      Currently I am {{panelOpenState ? 'open' : 'closed'}}
                    </mat-panel-description> -->
                    </mat-expansion-panel-header>
                    <p>
                      {{ pro?.answer }}
                    </p>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="container my-4">
          <div class="row">
            <div class="col-md-4">
              <mat-card class="card-style location-card">
                <div class="row" style="padding-top: 15px">
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon
                        class="material-icons-outlined"
                        style="margin-left: 10px"
                      >
                        location_on
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Location</b>
                    </h2>
                    <div>
                      <button
                        mat-raised-button
                        (click)="openMap()"
                        class="centered"
                        *ngIf="profile?.googleMapURL"
                      >
                        View Map
                      </button>
                      <button
                        mat-raised-button
                        disabled
                        class="centered"
                        *ngIf="!profile?.googleMapURL"
                      >
                        View Map
                      </button>

                      <img src="./././assets/map.jpg" class="map-sty" />
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
            <div class="col-md-4">
              <mat-card class="card-style business-Info-card">
                <div class="row" style="padding: 15px">
                  <!-- Sarath Code Changes -->
                  <div class="col-md-12">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        description
                      </mat-icon>
                      <b class="heading-text-style">&nbsp;Business Info</b>
                    </h2>
                    <!-- <p style="display: flex; align-items: center" *ngIf="profile?.contactInfo">
                        <span class="material-icons-outlined"> email </span>&nbsp; {{ profile?.contactInfo }}
                      </p> -->
                    <p
                      style="display: flex; align-items: center"
                      *ngIf="profile?.phoneNumber"
                    >
                      <mat-icon class="material-icons-outlined">
                        phone </mat-icon
                      >&nbsp; {{ profile?.phoneNumber }}
                    </p>
                    <p
                      style="display: flex; align-items: center"
                      *ngIf="profile?.websiteURL"
                    >
                      <mat-icon class="material-icons-outlined"> link </mat-icon
                      >&nbsp; {{ profile?.websiteURL }}
                    </p>
                    <!-- Sarath Code Changes -->
                    <p style="display: flex; align-items: center">
                      <span
                        ><mat-icon class="material-icons-outlined">
                          location_on
                        </mat-icon></span
                      >
                      <span
                        style="float: right; margin-left: 10px"
                        *ngIf="!profile?.countryCodeNoAddressLine1"
                      >
                        {{ profile?.addressLine1 }},&nbsp;{{ profile?.cityName }},&nbsp;{{profile?.state}}</span
                      >
                      <span
                        style="float: right; margin-left: 10px"
                        *ngIf="profile?.countryCodeNoAddressLine1"
                        >{{ profile?.addressLine1 }}{{ profile?.cityName }},&nbsp;{{ profile?.state }}</span
                      >
                    </p>
                  </div>
                  <div
                    style="margin-right: 40px; margin-top: 20px; width: 100%"
                  >
                    <button
                      mat-raised-button
                      style="
                        width: 100%;
                        background-color: #333333;
                        border-radius: 10px;
                        height: 45px;
                        color: #fff;
                      "
                      *ngIf="businessid !== profile?.providerId"
                      (click)="contactUs(profile)"
                    >
                      <mat-icon class="material-icons-outlined">
                        email
                      </mat-icon>
                      Contact
                    </button>
                    <button
                      mat-raised-button
                      disabled
                      style="
                        width: 100%;
                        background-color: #37373740;
                        border-radius: 10px;
                        height: 45px;
                        color: #fff;
                      "
                      *ngIf="businessid == profile?.providerId"
                    >
                      <mat-icon class="material-icons-outlined">
                        email
                      </mat-icon>
                      Contact
                    </button>
                  </div>
                </div>
              </mat-card>
            </div>
            <!-- if available-->
            <div class="col-md-4">
              <mat-card class="card-style business-hours-card">
                <div class="row" style="padding: 15px">
                  <div class="col-md-12">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        schedule
                      </mat-icon>
                      <span
                        ><b class="mx-1 heading-text-style"
                          >Business Hours</b
                        ></span
                      >
                    </h2>
                    <h2>
                      <div class="my-4 business-hours-card">
                        <ng-container
                          *ngFor="let pro of profile?.businessHours"
                        >
                          <div class="row">
                            <div class="col-sm-2 days-style">
                              <span style="font-size: 14px">{{
                                pro?.day
                              }}</span>
                            </div>
                            <div
                              class="col-sm-10"
                              *ngIf="!pro?.dayClosed && !pro?.secondTimeSlot"
                            >
                              <span
                                style="
                                  float: right;
                                  font-size: 14px;
                                  color: grey;
                                "
                              >
                                Closed
                              </span>
                            </div>
                            <div
                              class="col-sm-10 time-style"
                              *ngIf="pro?.dayClosed && pro?.secondTimeSlot"
                            >
                              <span style="float: right; font-size: 14px">
                                {{ pro?.openTime }} - {{ pro?.closeTime }} |
                                {{ pro?.secondTimeSlotOpen }} -
                                {{ pro?.secondTimeSlotClose }}</span
                              >
                            </div>
                            <div
                              class="col-sm-10"
                              *ngIf="pro?.dayClosed && !pro?.secondTimeSlot"
                            >
                              <span style="float: right; font-size: 14px">
                                {{ pro?.openTime }} - {{ pro?.closeTime }}</span
                              >
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </h2>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Introduction">
        <div class="container my-4">
          <div class="row">
            <div class="col-md-3">
              <div>
                <mat-card class="card-style content-style my-4">
                  <div class="row" style="display: flex; align-items: center">
                    <div class="col-md-8">
                      <app-star-rating
                        [rating]="profile?.overallReceivedReview"
                        [starCount]="starCount"
                        [color]="starColor"
                        [isReviewShow]="true"
                      ></app-star-rating>
                    </div>
                    <div class="col review-details">
                      <p class="review-details-text">
                        <!-- <u *ngIf="reviews?.length > 0">Reviews</u>
                        <u *ngIf="reviews?.length <= 0">0&nbsp;Reviews</u> -->
                        <u *ngIf="reviews?.length > 0"
                          >{{ reviewsCount }} Reviews</u
                        >
                      </p>
                    </div>
                  </div>
                  <button
                    mat-stroked-button
                    class="my-3 submit-button"
                    (click)="submitreview()"
                    disabled
                  >
                    <span class="material-icons icon-star"> star_border </span
                    >&nbsp;<span class="submit-txt">Submit Review</span>
                  </button>
                  <mat-divider></mat-divider>
                  <p
                    class="my-2"
                    style="display: flex; align-items: center"
                    *ngIf="profile?.views"
                  >
                    <mat-icon class="material-icons-outlined">
                      visibility </mat-icon
                    >&nbsp;{{ profile?.views }}
                    Views
                  </p>
                  <p
                    class="my-2"
                    style="display: flex; align-items: center"
                    *ngIf="!profile?.views"
                  >
                    <mat-icon class="material-icons-outlined">
                      visibility </mat-icon
                    >&nbsp;0 Views
                  </p>
                  <p
                    style="display: flex; align-items: center"
                    *ngIf="received?.length > 0"
                  >
                    <span class="material-icons"> star_border </span>&nbsp;{{
                      received?.length
                    }}
                    Endorsements
                  </p>
                  <p
                    style="display: flex; align-items: center"
                    *ngIf="received?.length <= 0"
                  >
                    <span class="material-icons-outlined"> diamond </span
                    >&nbsp;0 Endorsements
                  </p>
                  <p
                    style="display: flex; align-items: center"
                    *ngIf="totalCustomers > 0"
                  >
                    <span class="material-icons-outlined"> bookmarks </span
                    >&nbsp;{{ totalCustomers.toString().padStart(2, "0") }}
                    Saves
                  </p>
                  <p
                    style="display: flex; align-items: center"
                    *ngIf="!totalCustomers"
                  >
                    <span class="material-icons-outlined"> bookmarks </span
                    >&nbsp;0 Saves
                  </p>
                  <!-- <p style="display: flex; align-items: center;">
                        <span class="material-icons-outlined mx-2">
                          share
                          </span>58 Shares
                      </p> -->
                </mat-card>
              </div>
            </div>
            <div class="col-md-9 intro-card">
              <mat-card class="card-style introduction-content my-4">
                <div class="row">
                  <div class="col-md-9">
                    <h3 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        description </mat-icon
                      ><b class="mx-1 heading-text-style">Introduction</b>
                    </h3>
                  </div>
                  <div
                    class="col-md-3 button-shift"
                    *ngIf="profile?.introVideoURL || profile?.introYoutubeLink"
                  >
                    <button
                      mat-stroked-button
                      class="alignment-style"
                      (click)="video()"
                    >
                      <mat-icon class="material-icons-outlined"
                        >videocam</mat-icon
                      >Watch Intro Video
                    </button>
                  </div>

                  <mat-divider></mat-divider>
                  <mat-chip-list aria-label="Tab Selection" class="my-3">
                    <button
                      mat-stroked-button
                      style="border-radius: 50px"
                      class="mx-2"
                      [selected]="selectedTab === 'Profile'"
                      [style.background]="
                        selectedTab === 'Profile' ? '#333333' : ''
                      "
                    >
                      <a
                        (click)="changeTab('Profile')"
                        [style.color]="
                          selectedTab === 'Profile' ? '#fff' : '#333333'
                        "
                        style="display: flex; align-items: center"
                        ><span class="material-icons-outlined"> person </span
                        >&nbsp;Profile</a
                      >
                    </button>
                    <button
                      *ngIf="profile?.experience"
                      mat-stroked-button
                      style="border-radius: 50px"
                      [selected]="selectedTab === 'Experience'"
                      [style.background]="
                        selectedTab === 'Experience' ? '#333333' : ''
                      "
                    >
                      <a
                        (click)="changeTab('Experience')"
                        [style.color]="
                          selectedTab === 'Experience' ? '#fff' : '#333333'
                        "
                        style="display: flex; align-items: center"
                        ><span class="material-icons-outlined">
                          business_center </span
                        >&nbsp;Experience</a
                      >
                    </button>
                    <button
                      *ngIf="profile?.training"
                      mat-stroked-button
                      style="border-radius: 50px"
                      class="mx-2"
                      [selected]="selectedTab === 'Training'"
                      [style.background]="
                        selectedTab === 'Training' ? '#333333' : ''
                      "
                    >
                      <a
                        (click)="changeTab('Training')"
                        [style.color]="
                          selectedTab === 'Training' ? '#fff' : '#333333'
                        "
                        style="display: flex; align-items: center"
                        ><span class="material-icons-outlined">
                          model_training </span
                        >&nbsp;Training</a
                      >
                    </button>
                  </mat-chip-list>
                </div>
                <!-- <p id="introduction" *ngIf="selectedTab === 'Profile'">
                      {{profile?.introduction | lineBreak}}
                    </p> -->

                <p
                  [innerHTML]="
                    truncateText(profile?.introduction | lineBreak, 150)
                  "
                  *ngIf="selectedTab === 'Profile'"
                ></p>

                <p
                  *ngIf="
                    selectedTab === 'Profile' &&
                    profile?.introduction?.length > 150
                  "
                  style="display: flex; align-items: center"
                >
                  <span
                    style="cursor: pointer"
                    (click)="readmore('Introduction')"
                    ><strong>Read More</strong></span
                  >
                  <span class="material-icons"> navigate_next </span>
                </p>
                <!-- <p *ngIf="selectedTab === 'Experience'">
                      {{profile?.experience}}
                    </p> -->

                <p
                  [innerHTML]="
                    truncateText(profile?.experience | lineBreak, 150)
                  "
                  *ngIf="selectedTab === 'Experience'"
                ></p>
                <p
                  *ngIf="
                    selectedTab === 'Experience' &&
                    profile?.experience?.length > 150
                  "
                  style="display: flex; align-items: center"
                >
                  <span style="cursor: pointer" (click)="readmore('Experience')"
                    ><strong>Read More</strong></span
                  >
                  <span class="material-icons"> navigate_next </span>
                </p>
                <!-- <p *ngIf="selectedTab === 'Training'">
                      {{profile?.training}}
                    </p> -->
                <p
                  [innerHTML]="truncateText(profile?.training | lineBreak, 150)"
                  *ngIf="selectedTab === 'Training'"
                ></p>
                <p
                  *ngIf="
                    selectedTab === 'Training' &&
                    profile?.training?.length > 150
                  "
                  style="display: flex; align-items: center"
                >
                  <span style="cursor: pointer" (click)="readmore('Training')"
                    ><strong>Read More</strong></span
                  >
                  <span class="material-icons"> navigate_next </span>
                </p>
                <mat-divider></mat-divider>

                <div class="row">
                  <div class="col-md-9 my-4">
                    <p
                      *ngIf="profile?.portfolioURL != ''"
                      (click)="openPortfolio(profile?.portfolioURL)"
                      mat-stroked-button
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: #333333;
                        text-decoration: none;
                        border-radius: 20px;
                        border: 1px solid #ccc;
                        padding: 8px 12px;
                        cursor: pointer;
                      "
                    >
                      <span class="material-icons">link</span>&nbsp;{{
                        profile?.portfolioURL
                      }}
                    </p>
                    <p
                      *ngIf="profile?.websiteURL != ''"
                      (click)="openPortfolio(profile?.websiteURL)"
                      class="mx-2"
                      mat-stroked-button
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: #333333;
                        text-decoration: none;
                        border-radius: 20px;
                        border: 1px solid #ccc;
                        padding: 8px 12px;
                        cursor: pointer;
                      "
                    >
                      <span class="material-icons">link</span>&nbsp;{{
                        profile?.websiteURL
                      }}
                    </p>
                  </div>
                  <div class="col-md-3 my-4 icons-style">
                    <a
                      (click)="openLink(profile?.facebookURL)"
                      style="color: #333333"
                      *ngIf="profile?.facebookURL"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="float: right"
                        enable-background="new 0 0 24 24"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <rect fill="none" height="24" width="24" />
                        <path
                          d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z"
                        /></svg></a>
                    <a
                      (click)="openLink(profile?.twitterURL)"
                      style="color: #333333"
                      *ngIf="profile?.twitterURL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Layer_1"
                        width="22px"
                        height="22px"
                        viewBox="0 0 22 22"
                        style="enable-background: new 0 0 24 24; float: right"
                        xml:space="preserve"
                      >
                        <g>
                          <polygon
                            points="12.153992,10.729553 8.088684,5.041199 5.92041,5.041199 10.956299,12.087097 11.59021,12.97345    15.900635,19.009583 18.068909,19.009583 12.785217,11.615906  "
                          />
                          <path
                            d="M21.15979,1H2.84021C1.823853,1,1,1.823853,1,2.84021v18.31958C1,22.176147,1.823853,23,2.84021,23h18.31958   C22.176147,23,23,22.176147,23,21.15979V2.84021C23,1.823853,22.176147,1,21.15979,1z M15.235352,20l-4.362549-6.213013   L5.411438,20H4l6.246887-7.104675L4,4h4.764648l4.130127,5.881958L18.06958,4h1.411377l-5.95697,6.775635L20,20H15.235352z"
                          />
                        </g></svg></a>
                    <a
                      (click)="openLink(profile?.linkedInURL)"
                      style="color: #333333"
                      *ngIf="profile?.linkedInURL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="float: right"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"
                        /></svg></a>
                    <a
                      (click)="openLink(profile?.instagramURL)"
                      style="color: #333333; cursor: pointer"
                      *ngIf="profile?.instagramURL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="float: right"
                        width="24"
                        height="24"
                        viewBox="0 0 29 30"
                      >
                        <path
                          fill="currentColor"
                          d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z"
                        /></svg></a>
                    <a
                      (click)="openLink(profile?.youtubeURL)"
                      style="color: #333333"
                      *ngIf="profile?.youtubeURL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="float: right"
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M15.32 4.06c-.434-.772-.905-.914-1.864-.968C12.498 3.027 10.089 3 8.002 3c-2.091 0-4.501.027-5.458.091-.957.055-1.429.196-1.867.969C.23 4.831 0 6.159 0 8.497v.008c0 2.328.23 3.666.677 4.429.438.772.909.912 1.866.977.958.056 3.368.089 5.459.089 2.087 0 4.496-.033 5.455-.088.959-.065 1.43-.205 1.864-.977.451-.763.679-2.101.679-4.429v-.008c0-2.339-.228-3.667-.68-4.438zM6 11.5v-6l5 3-5 3z"
                          clip-rule="evenodd"
                        /></svg></a>
                    <a
                      (click)="openLink(profile?.tiktokURL)"
                      style="color: #333333"
                      *ngIf="profile?.tiktokURL"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="float: right"
                        width="24"
                        height="24"
                        viewBox="0 0 85 110"
                      >
                        <path
                          fill="currentColor"
                          d="M74.66,20.573c-4.218-4.904-6.428-11.241-6.253-17.693L52.643,2.5c0,0,0,0.673,0,1.579v65.887
                               c-4.244,18.913-31.616,13.978-28.876-5.265c1.529-8.79,10.972-14.198,19.365-11.141c0,0,0-16.084,0-16.084
                               C24.861,34.295,7.546,48.837,7.728,67.364c1.597,40.179,59.226,40.185,60.825,0c-0.403-1.438-0.178-28.214-0.235-30.472
                               c7.168,4.46,15.508,6.689,23.954,6.405V26.685C84.464,26.685,78.505,24.609,74.66,20.573z"
                        /></svg></a>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Featured" *ngIf="featureImages?.length > 0">
        <div class="container my-4">
          <div class="col-md-12">
            <mat-card class="card-style" style="padding: 20px">
              <div class="row" style="padding: 15px">
                <div class="col-md-4">
                  <h2 style="display: flex; align-items: center">
                    <mat-icon class="material-icons-outlined">
                      star_outline
                    </mat-icon>
                    <b class="mx-1 heading-text-style">Featured</b>
                  </h2>
                </div>
              </div>
              <drag-scroll [scroll-x-wheel-enabled]="true">
              <div class="row scroll-row feature-contnet">
                <div
                drag-scroll-item
                  class="col-sm-4 scroll-row set"
                  *ngFor="let featureImg of featureImages"
                >
                  <div
                    class="container3"
                    style="background-color: #f1f0f5; border-radius: 20px"
                  >
                    <img
                      src="{{ featureImg.galleryImageUrl }}"
                      style="object-fit: contain"
                      alt="Avatar"
                      class="image3"
                      (click)="openImageDetails(featureImg)"
                    />
                    <button
                      mat-mini-fab
                      (click)="likeImage(featureImg)"
                      style="
                        margin-top: -370px;
                        float: right;
                        margin-right: 10px;
                        background-color: #1f1f1f81;
                        color: #fff;
                      "
                      aria-label="Example icon button "
                    >
                      <span
                        *ngIf="featureImg.likesCount >= 1"
                        class="material-icons-outlined"
                        style="color: red"
                      >
                        favorite
                      </span>
                      <span
                        *ngIf="featureImg.likesCount == 0"
                        class="material-icons-outlined"
                      >
                        favorite_border
                      </span>
                      <span>{{ featureImg.likesCount }}</span>
                    </button>
                    <div class="overlay3">
                      {{ featureImg?.galleryDescription }}
                      <span
                        style="cursor: pointer"
                        (click)="openImageDetails(featureImg)"
                        >Read More...</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </drag-scroll>
            </mat-card>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Activity" *ngIf="activities?.length > 0">
        <div class="container my-4">
          <div class="row">
            <div
              class="col-md-8"
              *ngIf="activities?.length > 0 && profilePromotion?.length > 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all-both">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="2"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                class="image"
                                style="object-fit: contain"
                              />
                              <div class="overlay1">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>

                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="activities?.length > 0 && profilePromotion?.length <= 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <!-- <app-heic-image></app-heic-image> -->
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>
                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-4"
              *ngIf="profilePromotion?.length > 0 && activities?.length > 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-8">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col-md-4 see-all-promotion">
                    <span
                      class="material-icons-outlined iconstyle"
                      style="float: right"
                    >
                      arrow_forward
                    </span>
                    <span
                      style="float: right; cursor: pointer; margin-top: 2px"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;&nbsp;&nbsp;</span
                    >
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="1"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="containerPromotion"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="pro-img"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="profilePromotion?.length > 0 && activities?.length <= 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-11">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="3"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Promotions" *ngIf="profilePromotion?.length > 0">
        <div class="container my-4">
          <div class="row">
            <div
              class="col-md-8"
              *ngIf="activities?.length > 0 && profilePromotion?.length > 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all-both">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="2"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                class="image"
                                style="object-fit: contain"
                              />
                              <div class="overlay1">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>

                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="activities?.length > 0 && profilePromotion?.length <= 0"
            >
              <mat-card class="card-style activity-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-10">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        layers
                      </mat-icon>
                      <b class="mx-1 heading-text-style">Activity</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllActivity()"
                      >See&nbsp;All&nbsp;&nbsp;&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="activities"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="true"
                >
                  <ng-template let-activity pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div
                            class="col-md-12"
                            *ngIf="activity?.activityImageURL"
                          >
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ activity?.activityImageURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <!-- <app-heic-image></app-heic-image> -->
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      activity?.title
                                    }}</span
                                    ><br />
                                    <span class="description">{{
                                      activity?.description
                                    }}</span>
                                  </p>
                                  <p>
                                    {{
                                      activity?.createdDate
                                        | date : "dd-MMM-yyyy"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-12"
                            *ngIf="!activity?.activityImageURL"
                          >
                            <!-- <span><img  src="{{activity.activityImageURL}}" style="width: 250px; height:250px; border-radius: 10px;"></span> -->
                            <div class="container2">
                              <div class="text2">
                                <p>
                                  <span class="title">{{
                                    activity?.title
                                  }}</span
                                  ><br />
                                  <span class="description">{{
                                    activity?.description
                                  }}</span>
                                </p>
                                <p>
                                  <span>{{
                                    activity?.createdDate | date : "dd-MMM-yyyy"
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-4"
              *ngIf="profilePromotion?.length > 0 && activities?.length > 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-8">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col-md-4 see-all-promotion">
                    <span
                      class="material-icons-outlined iconstyle"
                      style="float: right"
                    >
                      arrow_forward
                    </span>
                    <span
                      style="float: right; cursor: pointer; margin-top: 2px"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;&nbsp;&nbsp;</span
                    >
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="1"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="1"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="containerPromotion"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="pro-img"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
            <div
              class="col-md-12"
              *ngIf="profilePromotion?.length > 0 && activities?.length <= 0"
            >
              <mat-card class="card-style promotion-cont-style">
                <div
                  class="row"
                  style="
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 15px;
                  "
                >
                  <div class="col-md-11">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        mic_none </mat-icon
                      ><b class="mx-1 heading-text-style">Promotions</b>
                    </h2>
                  </div>
                  <div class="col see-all">
                    <span
                      style="float: right; cursor: pointer"
                      (click)="seeAllPromotion()"
                      >See All&nbsp;</span
                    ><span class="material-icons-outlined" style="float: right">
                      arrow_forward
                    </span>
                  </div>
                </div>
                <p-carousel
                  [value]="profilePromotion"
                  [numVisible]="3"
                  [responsiveOptions]="responsiveOptions"
                  [numScroll]="3"
                  [circular]="false"
                >
                  <ng-template let-promotion pTemplate="item">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="row">
                          <div class="col-md-12">
                            <div
                              class="container1"
                              style="
                                background-color: #f1f0f5;
                                border-radius: 10px;
                              "
                            >
                              <img
                                src="{{ promotion?.imagePromotionURL }}"
                                style="object-fit: contain"
                                class="image"
                              />
                              <div class="overlay">
                                <div class="text">
                                  <p>
                                    <span class="title">{{
                                      promotion?.promotionTitle
                                    }}</span
                                    ><br />
                                    <span class="description"
                                      >{{
                                        promotion?.startDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}
                                      to
                                      {{
                                        promotion.endDateTime
                                          | date : "dd-MMM-yyyy"
                                      }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        label="Endorsements"
        *ngIf="
          givenData?.length > 0 || reviews?.length > 0 || received?.length > 0
        "
      >
        <div class="container my-4">
          <div class="row">
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData.length > 0">
                  <mat-card class="card-style pro-given">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  class="pro-endorsement-img"
                                />
                              </div>
                              <div
                                class="col-sm-8 pro-endorsement-name"
                                style="padding: 10px"
                              >
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }},
                                  {{ endorsementGiven?.country }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>

                <div
                  class="col-md-4 endor-received-card"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card
                    class="card-style pro-received"
                    style="height: 475px"
                  >
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"

                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                        <b
                                        (click)="getListingDataById(msg?.id)"
                                        style="cursor: pointer"
                                        >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;

                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-4" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card
                              class="card-style"
                              style="
                                text-align: left;
                                padding: 10px;
                                height: 350px;
                              "
                            >
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />

                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name name-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div class="rating style-rating">
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p>{{ review?.reviewDescription }}</p>
                                <div class="row">
                                  <div
                                    class="col-sm-4"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      class="review-img"
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div
                  class="col-md-8"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >

                                  <div class="row">
                                    <div
                                    class="col-md-1" style="text-align:left; margin-right: 10px"
                                    >
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        1000
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      1000
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style cust-review">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card class="card-style content-review">
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}
                                        </b>
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        >
                                        </app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div
                  class="col-md-4"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style"
                                style="
                                  text-align: left;
                                  padding: 10px;
                                  height: 350px;
                                "
                              >
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}</b
                                        >
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        ></app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="givenData?.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Given</b
                        >
                      </h2>
                      <div class="search-bar-container">
                        <mat-form-field
                          appearance="outline"
                          class="search-field"
                        >
                          <input
                            id="searchQueryInput"
                            matInput
                            placeholder="Search Provider"
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="clearFilter()"
                            *ngIf="searchQuery !== ''"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Search"
                            (click)="onSearch($event)"
                            *ngIf="searchQuery === ''"
                          >
                            <mat-icon>search</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                      <div class="scroll">
                        <mat-card
                          *ngFor="let endorsementGiven of givenData"
                          style="margin-top: 10px"
                        >
                          <div class="row">
                            <div class="col-sm-4">
                              <img
                                src="{{ endorsementGiven?.profileURL }}"
                                style="
                                  border-radius: 50px;
                                  width: 100px;
                                  height: 100px;
                                  padding: 10px;
                                  border: solid 1px;
                                "
                              />
                            </div>
                            <div class="col-sm-8" style="padding: 10px">
                              <b
                                (click)="
                                  getListingDataById(endorsementGiven?.id)
                                "
                                style="cursor: pointer"
                                >{{ endorsementGiven?.listingName }}</b
                              >
                              <p>
                                {{ endorsementGiven?.cityName }},
                                {{ endorsementGiven?.state }}
                              </p>
                              <span
                                *ngFor="
                                  let hashTags of endorsementGiven?.hashTags
                                "
                              >
                                <button
                                  mat-stroked-button
                                  style="margin-bottom: 10px"
                                >
                                  {{ hashTags }}</button
                                >&nbsp;
                              </span>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="getreceivedendorsements.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Received</b
                        >
                      </h2>
                      <p-carousel
                        [value]="getreceivedendorsements"
                        [numVisible]="1"
                        [numScroll]="1"
                        [circular]="true"
                      >
                      <ng-template let-msg pTemplate="items">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style endorsementsReceivedBox"
                              >
                                <div class="row">
                                  <div
                                  class="col-md-1" style="text-align:left; margin-right: 10px"
                                  >
                                    <img
                                      src="{{ msg?.profileURL }}"
                                      style="
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                  <div class="col-md-10">
                                    <p
                                      style="text-align: left;"
                                    >
                                    <b
                                    (click)="getListingDataById(msg?.id)"
                                    style="cursor: pointer"
                                    >{{ msg?.listingName }}</b>
                                    </p>
                                    <p
                                      style="
                                        text-align: left;
                                        font-size: 13px;
                                      "
                                    >
                                      {{ msg?.cityName }}, {{ msg?.state }}
                                    </p>
                                  </div>
                                </div>
                                <p
                                  style="text-align: justify; font-size: 12px"
                                  [innerHTML]="
                                    truncateText(
                                      msg?.endorsementDescription | lineBreak,
                                      1000
                                    )
                                  "
                                ></p>
                                <p
                                  *ngIf="
                                    msg?.endorsementDescription?.length > 1000
                                  "
                                >
                                  <span
                                    style="
                                      cursor: pointer;
                                      font-size: 14px;
                                      float: left;
                                    "
                                    (click)="
                                      openReadMoreRecvdEndorsementDescritionDialog(
                                        msg?.endorsementDescription,
                                        msg?.profileURL,
                                        msg?.listingName,
                                        msg?.cityName,
                                        msg?.state
                                      )
                                    "
                                  >
                                    <strong>Read More</strong></span
                                  >
                                  <span
                                    style="float: left"
                                    class="material-icons"
                                  >
                                    navigate_next
                                  </span>
                                </p>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="col-md-12 reviews-card" *ngIf="reviews?.length > 0">
                <mat-card class="card-style cust-review">
                  <div class="row" style="padding: 12px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          auto_awesome
                        </mat-icon>
                        <b class="mx-1 heading-text-style">Customer Reviews</b>
                      </h2>
                    </div>
                  </div>
                  <p-carousel
                    [value]="reviews"
                    [numVisible]="1"
                    [numScroll]="1"
                    [circular]="true"
                  >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card class="card-style content-review">
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />
                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name2 listing-style customer-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div
                                      class="rating2 listing-rating customer-rating"
                                    >
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p (click)="openReadReviewDialog(review)">
                                  {{ review?.reviewDescription }}
                                </p>
                                <div class="row">
                                  <div
                                    class="col-sm-2"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 10px;
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Reviews" *ngIf="reviews?.length > 0">
        <div class="container my-4">
          <div class="row">
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData.length > 0">
                  <mat-card class="card-style pro-given">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  class="pro-endorsement-img"
                                />
                              </div>
                              <div
                                class="col-sm-8 pro-endorsement-name"
                                style="padding: 10px"
                              >
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }},
                                  {{ endorsementGiven?.country }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>

                <div
                  class="col-md-4 endor-received-card"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card
                    class="card-style pro-received"
                    style="height: 475px"
                  >
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"

                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                        <b
                                        (click)="getListingDataById(msg?.id)"
                                        style="cursor: pointer"
                                        >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;

                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-4" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card
                              class="card-style"
                              style="
                                text-align: left;
                                padding: 10px;
                                height: 350px;
                              "
                            >
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />

                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name name-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div class="rating style-rating">
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p>{{ review?.reviewDescription }}</p>
                                <div class="row">
                                  <div
                                    class="col-sm-4"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      class="review-img"
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div
                  class="col-md-8"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >

                                  <div class="row">
                                    <div
                                    class="col-md-1" style="text-align:left; margin-right: 10px"
                                    >
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        1000
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      1000
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div class="col-md-4" *ngIf="givenData?.length > 0">
                  <mat-card class="card-style">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            local_mall
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Given</b
                          >
                        </h2>
                        <div class="search-bar-container">
                          <mat-form-field
                            appearance="outline"
                            class="search-field"
                          >
                            <input
                              id="searchQueryInput"
                              matInput
                              placeholder="Search Provider"
                            />
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Clear"
                              (click)="clearFilter()"
                              *ngIf="searchQuery !== ''"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                            <button
                              matSuffix
                              mat-icon-button
                              aria-label="Search"
                              (click)="onSearch($event)"
                              *ngIf="searchQuery === ''"
                            >
                              <mat-icon>search</mat-icon>
                            </button>
                          </mat-form-field>
                        </div>
                        <div class="scroll">
                          <mat-card
                            *ngFor="let endorsementGiven of givenData"
                            style="margin-top: 10px"
                          >
                            <div class="row">
                              <div class="col-sm-4">
                                <img
                                  src="{{ endorsementGiven?.profileURL }}"
                                  style="
                                    border-radius: 50px;
                                    width: 100px;
                                    height: 100px;
                                    padding: 10px;
                                    border: solid 1px;
                                  "
                                />
                              </div>
                              <div class="col-sm-8" style="padding: 10px">
                                <b
                                  (click)="
                                    getListingDataById(endorsementGiven?.id)
                                  "
                                  style="cursor: pointer"
                                  >{{ endorsementGiven?.listingName }}</b
                                >
                                <p>
                                  {{ endorsementGiven?.cityName }},
                                  {{ endorsementGiven?.state }}
                                </p>
                                <span
                                  *ngFor="
                                    let hashTags of endorsementGiven?.hashTags
                                  "
                                >
                                  <button
                                    mat-stroked-button
                                    style="margin-bottom: 10px"
                                  >
                                    {{ hashTags }}</button
                                  >&nbsp;
                                </span>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style cust-review">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card class="card-style content-review">
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}
                                        </b>
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        >
                                        </app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length > 0
              "
            >
              <div class="row">
                <div
                  class="col-md-4"
                  *ngIf="getreceivedendorsements.length > 0"
                >
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            diamond
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Pro Endorsements Received</b
                          >
                        </h2>
                        <p-carousel
                          [value]="getreceivedendorsements"
                          [numVisible]="1"
                          [numScroll]="1"
                          [circular]="true"
                        >
                        <ng-template let-msg pTemplate="items">
                          <div class="product-item">
                            <div class="product-item-content">
                              <div class="col-sm-12">
                                <mat-card
                                  class="card-style endorsementsReceivedBox"
                                >
                                  <div class="row">
                                    <div class="col-md-2" style="text-align:left">
                                      <img
                                        src="{{ msg?.profileURL }}"
                                        style="
                                          width: 50px;
                                          height: 50px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                        "
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <p
                                        style="text-align: left;"
                                      >
                                      <b
                                      (click)="getListingDataById(msg?.id)"
                                      style="cursor: pointer"
                                      >{{ msg?.listingName }}</b>
                                      </p>
                                      <p
                                        style="
                                          text-align: left;
                                          font-size: 13px;
                                        "
                                      >
                                        {{ msg?.cityName }}, {{ msg?.state }}
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    style="
                                      text-align: justify;
                                      font-size: 12px;
                                    "
                                    [innerHTML]="
                                      truncateText(
                                        msg?.endorsementDescription
                                          | lineBreak,
                                        500
                                      )
                                    "
                                  ></p>
                                  <p
                                    *ngIf="
                                      msg?.endorsementDescription?.length >
                                      500
                                    "
                                  >
                                    <span
                                      style="
                                        cursor: pointer;
                                        font-size: 14px;
                                        float: left;
                                      "
                                      (click)="
                                        openReadMoreRecvdEndorsementDescritionDialog(
                                          msg?.endorsementDescription,
                                          msg?.profileURL,
                                          msg?.listingName,
                                          msg?.cityName,
                                          msg?.state
                                        )
                                      "
                                    >
                                      <strong>Read More</strong></span
                                    >
                                    <span
                                      style="float: left"
                                      class="material-icons"
                                    >
                                      navigate_next
                                    </span>
                                  </p>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                        </p-carousel>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-md-8 reviews-card" *ngIf="reviews?.length > 0">
                  <mat-card class="card-style" style="height: 475px">
                    <div class="row" style="padding: 12px">
                      <div class="col-md-12">
                        <h2 style="display: flex; align-items: center">
                          <mat-icon class="material-icons-outlined">
                            auto_awesome
                          </mat-icon>
                          <b class="mx-1 heading-text-style"
                            >Customer Reviews</b
                          >
                        </h2>
                      </div>
                    </div>
                    <p-carousel
                      [value]="reviews"
                      [numVisible]="1"
                      [numScroll]="1"
                      [circular]="true"
                    >
                      <ng-template let-review pTemplate="item">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style"
                                style="
                                  text-align: left;
                                  padding: 10px;
                                  height: 350px;
                                "
                              >
                                <div class="scroll">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <img
                                        src="../../../assets/images/grey.jpg"
                                        alt="Avatar"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 50px;
                                          float: left;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="!review?.customerProfileUrl"
                                      />
                                      <img
                                        src="{{ review?.customerProfileUrl }}"
                                        style="
                                          width: 80px;
                                          height: 80px;
                                          border-radius: 50px;
                                          object-fit: cover;
                                          border: solid 1px;
                                          padding: 5px;
                                        "
                                        *ngIf="review?.customerProfileUrl"
                                      />
                                    </div>
                                    <div class="col-md-9">
                                      <h2>
                                        <b
                                          class="customer-name1 listing-customer-name listing-style-name"
                                          style="cursor: pointer"
                                          (click)="openReadReviewDialog(review)"
                                          >{{ review?.customerUserName }}</b
                                        >
                                      </h2>
                                      <div
                                        class="rating1 listing-customer-rating listing-style-rating"
                                      >
                                        <app-star-rating
                                          [rating]="review.overall"
                                          [starCount]="starCount"
                                          [color]="starColor"
                                          [isReviewShow]="true"
                                        ></app-star-rating>
                                      </div>
                                    </div>
                                  </div>
                                  <h4>
                                    <b
                                      (click)="openReadReviewDialog(review)"
                                      style="cursor: pointer"
                                      >{{ review?.reviewTitle }}</b
                                    >
                                  </h4>
                                  <p>{{ review?.reviewDescription }}</p>
                                  <div class="row">
                                    <div
                                      class="col-sm-2"
                                      *ngFor="
                                        let reviewImageUrls of review?.reviewImageUrls
                                      "
                                    >
                                      <img
                                        src="{{ reviewImageUrls }}"
                                        style="
                                          height: 80px;
                                          width: 80px;
                                          border-radius: 10px;
                                        "
                                      />
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-carousel>
                  </mat-card>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length > 0 &&
                received?.length <= 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="givenData?.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Given</b
                        >
                      </h2>
                      <div class="search-bar-container">
                        <mat-form-field
                          appearance="outline"
                          class="search-field"
                        >
                          <input
                            id="searchQueryInput"
                            matInput
                            placeholder="Search Provider"
                          />
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="clearFilter()"
                            *ngIf="searchQuery !== ''"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <button
                            matSuffix
                            mat-icon-button
                            aria-label="Search"
                            (click)="onSearch($event)"
                            *ngIf="searchQuery === ''"
                          >
                            <mat-icon>search</mat-icon>
                          </button>
                        </mat-form-field>
                      </div>
                      <div class="scroll">
                        <mat-card
                          *ngFor="let endorsementGiven of givenData"
                          style="margin-top: 10px"
                        >
                          <div class="row">
                            <div class="col-sm-4">
                              <img
                                src="{{ endorsementGiven?.profileURL }}"
                                style="
                                  border-radius: 50px;
                                  width: 100px;
                                  height: 100px;
                                  padding: 10px;
                                  border: solid 1px;
                                "
                              />
                            </div>
                            <div class="col-sm-8" style="padding: 10px">
                              <b
                                (click)="
                                  getListingDataById(endorsementGiven?.id)
                                "
                                style="cursor: pointer"
                                >{{ endorsementGiven?.listingName }}</b
                              >
                              <p>
                                {{ endorsementGiven?.cityName }},
                                {{ endorsementGiven?.state }}
                              </p>
                              <span
                                *ngFor="
                                  let hashTags of endorsementGiven?.hashTags
                                "
                              >
                                <button
                                  mat-stroked-button
                                  style="margin-bottom: 10px"
                                >
                                  {{ hashTags }}</button
                                >&nbsp;
                              </span>
                            </div>
                          </div>
                        </mat-card>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                getreceivedendorsements.length > 0 &&
                reviews?.length <= 0
              "
            >
              <div class="col-md-12" *ngIf="getreceivedendorsements.length > 0">
                <mat-card class="card-style">
                  <div class="row" style="padding: 15px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          local_mall
                        </mat-icon>
                        <b class="mx-1 heading-text-style"
                          >Pro Endorsements Received</b
                        >
                      </h2>
                      <p-carousel
                        [value]="getreceivedendorsements"
                        [numVisible]="1"
                        [numScroll]="1"
                        [circular]="true"
                      >
                      <ng-template let-msg pTemplate="items">
                        <div class="product-item">
                          <div class="product-item-content">
                            <div class="col-sm-12">
                              <mat-card
                                class="card-style endorsementsReceivedBox"
                              >
                                <div class="row">
                                  <div
                                  class="col-md-1" style="text-align:left; margin-right: 10px"
                                  >
                                    <img
                                      src="{{ msg?.profileURL }}"
                                      style="
                                        width: 50px;
                                        height: 50px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                  <div class="col-md-10">
                                    <p
                                      style="text-align: left;"
                                    >
                                    <b
                                    (click)="getListingDataById(msg?.id)"
                                    style="cursor: pointer"
                                    >{{ msg?.listingName }}</b>
                                    </p>
                                    <p
                                      style="
                                        text-align: left;
                                        font-size: 13px;
                                      "
                                    >
                                      {{ msg?.cityName }}, {{ msg?.state }}
                                    </p>
                                  </div>
                                </div>
                                <p
                                  style="text-align: justify; font-size: 12px"
                                  [innerHTML]="
                                    truncateText(
                                      msg?.endorsementDescription | lineBreak,
                                      1000
                                    )
                                  "
                                ></p>
                                <p
                                  *ngIf="
                                    msg?.endorsementDescription?.length > 1000
                                  "
                                >
                                  <span
                                    style="
                                      cursor: pointer;
                                      font-size: 14px;
                                      float: left;
                                    "
                                    (click)="
                                      openReadMoreRecvdEndorsementDescritionDialog(
                                        msg?.endorsementDescription,
                                        msg?.profileURL,
                                        msg?.listingName,
                                        msg?.cityName,
                                        msg?.state
                                      )
                                    "
                                  >
                                    <strong>Read More</strong></span
                                  >
                                  <span
                                    style="float: left"
                                    class="material-icons"
                                  >
                                    navigate_next
                                  </span>
                                </p>
                              </mat-card>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                      </p-carousel>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div
              *ngIf="
                givenData?.length <= 0 &&
                received?.length <= 0 &&
                reviews?.length > 0
              "
            >
              <div class="col-md-12 reviews-card" *ngIf="reviews?.length > 0">
                <mat-card class="card-style cust-review">
                  <div class="row" style="padding: 12px">
                    <div class="col-md-12">
                      <h2 style="display: flex; align-items: center">
                        <mat-icon class="material-icons-outlined">
                          auto_awesome
                        </mat-icon>
                        <b class="mx-1 heading-text-style">Customer Reviews</b>
                      </h2>
                    </div>
                  </div>
                  <p-carousel
                    [value]="reviews"
                    [numVisible]="1"
                    [numScroll]="1"
                    [circular]="true"
                  >
                    <ng-template let-review pTemplate="item">
                      <div class="product-item">
                        <div class="product-item-content">
                          <div class="col-sm-12">
                            <mat-card class="card-style content-review">
                              <div class="scroll">
                                <div class="row">
                                  <div class="col-md-3">
                                    <img
                                      src="../../../assets/images/grey.jpg"
                                      alt="Avatar"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 50px;
                                        float: left;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="!review?.customerProfileUrl"
                                    />
                                    <img
                                      src="{{ review?.customerProfileUrl }}"
                                      style="
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 50px;
                                        object-fit: cover;
                                        border: solid 1px;
                                        padding: 5px;
                                      "
                                      *ngIf="review?.customerProfileUrl"
                                    />
                                  </div>
                                  <div class="col-md-9">
                                    <h2>
                                      <b
                                        class="customer-name2 listing-style customer-style"
                                        style="cursor: pointer"
                                        (click)="openReadReviewDialog(review)"
                                        >{{ review?.customerUserName }}</b
                                      >
                                    </h2>
                                    <div
                                      class="rating2 listing-rating customer-rating"
                                    >
                                      <app-star-rating
                                        [rating]="review.overall"
                                        [starCount]="starCount"
                                        [color]="starColor"
                                        [isReviewShow]="true"
                                      ></app-star-rating>
                                    </div>
                                  </div>
                                </div>
                                <h4>
                                  <b
                                    (click)="openReadReviewDialog(review)"
                                    style="cursor: pointer"
                                    >{{ review?.reviewTitle }}</b
                                  >
                                </h4>
                                <p (click)="openReadReviewDialog(review)">
                                  {{ review?.reviewDescription }}
                                </p>
                                <div class="row">
                                  <div
                                    class="col-sm-2"
                                    *ngFor="
                                      let reviewImageUrls of review?.reviewImageUrls
                                    "
                                  >
                                    <img
                                      src="{{ reviewImageUrls }}"
                                      style="
                                        height: 80px;
                                        width: 80px;
                                        border-radius: 10px;
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                            </mat-card>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-carousel>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Gallery" *ngIf="gallery && gallery.length > 0">
        <div class="container my-4">
          <div class="row">
            <div class="col-md-12">
              <mat-card class="card-style" style="padding-bottom: 10px">
                <div class="row" style="padding: 15px">
                  <div class="col-md-2">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        collections </mat-icon
                      ><b class="mx-1 heading-text-style">Gallery</b>
                    </h2>
                  </div>
                  <div class="col-md-7"></div>
                  <div class="col-md-3 gallery-tabs">
                    <mat-chip-list aria-label="Tab Selection">
                      <button
                        (click)="changeTab1('My Posts')"
                        [style.color]="
                          selectedTab1 === 'My Posts' ? '#fff' : '#333333'
                        "
                        class="mx-2 mat-stroked-button"
                        style="
                          border-radius: 30px;
                          height: 40px;
                          width: 100px;
                          margin: 10px;
                          cursor: pointer;
                        "
                        [selected]="selectedTab1 === 'My Posts'"
                        [style.background]="
                          selectedTab1 === 'My Posts' ? '#333333' : ''
                        "
                        *ngIf="gallery.length > 0"
                      >
                        My Posts
                      </button>

                      <button
                        (click)="changeTab1('Tagged Posts')"
                        [style.color]="
                          selectedTab1 === 'Tagged Posts' ? '#fff' : '#333333'
                        "
                        class="mx-2 mat-stroked-button"
                        style="
                          border-radius: 30px;
                          height: 40px;
                          width: 140px;
                          cursor: pointer;
                        "
                        [selected]="selectedTab1 === 'Tagged Posts'"
                        [style.background]="
                          selectedTab1 === 'Tagged Posts' ? '#333333' : ''
                        "
                        *ngIf="taggedImg && taggedImg.length > 0"
                      >
                        Tagged Posts
                      </button>
                    </mat-chip-list>
                  </div>
                </div>

                <div *ngIf="selectedTab1 === 'My Posts'" class="scroll-gallery">
                  <app-gallery-lightbox
                    [galleryData]="gallery"
                    [showCount]="true"
                    [profileid]="profileid"
                    (onImageChange)="likeImage($event)"
                  >
                  </app-gallery-lightbox>
                </div>
                <div
                  class="row scroll-gallery"
                  *ngIf="selectedTab1 === 'Tagged Posts'"
                >
                  <app-tagged-image-lightbox
                    [taggedData]="taggedImg"
                    [showCount]="true"
                    [profileid]="profileid"
                    (onImageChange)="likeImage($event)"
                  >
                  </app-tagged-image-lightbox>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Team" *ngIf="teams?.length > 0">
        <div class="container my-4">
          <div class="row">
            <div class="col-md-12">
              <mat-card class="card-style">
                <div class="row" style="padding: 15px">
                  <div class="col-md-4">
                    <h2 style="display: flex; align-items: center">
                      <mat-icon class="material-icons-outlined">
                        group </mat-icon
                      ><b class="mx-1 heading-text-style">Team</b>
                    </h2>
                  </div>
                  <div class="row scroll-row">
                    <div
                      class="col-md-2 scroll-row"
                      *ngFor="let team of teams"
                      style="text-align: center; cursor: pointer"
                    >
                      <div
                        (click)="
                          openTeamListing(team.requestToPrimaryListingId)
                        "
                      >
                        <img
                          src="{{ team?.memberPhotoURL }}"
                          style="
                            border-radius: 50px;
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            border: solid 1px;
                          "
                        />
                        <h4
                          class="text-style"
                          *ngIf="team.requestToPrimaryListingId"
                          style="cursor: pointer"
                        >
                          <b
                            >{{ team.memberFirstName }}
                            {{ team?.memberLastName }}</b
                          >
                        </h4>
                        <h4 *ngIf="!team.requestToPrimaryListingId">
                          <b
                            >{{ team.memberFirstName }}
                            {{ team?.memberLastName }}</b
                          >
                        </h4>
                        <!-- <p (click)="teamProfile(team?.requestTo)" style="display: flex; text-align: center; margin-left:58px;">VIEW<span class="material-icons">
                          arrow_right_alt
                          </span></p> -->
                        <p>{{ team?.serviceName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>
      <!-- <button mat-raised-button >Contact Me</button> -->
    </mat-tab-group>
  </div>
  </div>
</div>

<ng-template #callFeatureImageDialog>
  <div>
    <form #userForm="ngForm" style="overflow-x: auto; overflow: hidden">
      <div class="row">
        <div class="col-md-6">
          <img
            [src]="featureImgData.galleryImageUrl"
            class="feature-img-style"
            style="background-color: #f1f0f5"
          />
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-8">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <p style="margin-top: 15px">
                    <b class="head-txt">&#64;Featured</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-center">
              <span
                class="material-icons-outlined"
                style="cursor: pointer"
                (click)="closeDialog()"
                >cancel</span
              >
            </div>
          </div>
          <hr style="width: 90%; margin: 0 auto" />
          <p class="p-3 feature-text">
            {{ featureImgData.galleryDescription }}
          </p>
          <hr style="width: 90%; margin: 0 auto" />
          <div class="d-flex align-items-center justify-content-start m-3">
            <span
              class="material-icons-outlined mr-2"
              style="cursor: pointer; color: red"
              *ngIf="featureImgData.likesCount >= 1"
              >favorite</span
            >
            <span class="m-2" *ngIf="featureImgData.likesCount >= 1">{{
              featureImgData.likesCount
            }}</span>
            <span
              *ngIf="featureImgData.likesCount == 0"
              class="material-icons-outlined"
            >
              favorite_border
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #ReadMoreReceivedEndorsementDescriptionDialog>
  <form #userForm="ngForm">
    <div style="padding: 20px; overflow: auto" class="row">
      <div class="col col-9">
        <h1>Endorsements Received</h1>
      </div>
      <div class="col col-3">
        <button
          mat-mini-fab
          mat-dialog-close
          style="float: right; background-color: #fff; color: #333333"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <div style="padding: 10px" class="row">
        <div class="col-md-2">
          <img
            src="{{ endorsementProfileUrl_Value }}"
            style="
              width: 50px;
              height: 50px;
              border-radius: 50px;
              object-fit: cover;
            "
          />
        </div>
        <div class="col-md-9">
          <p style="text-align: left; margin-left: -70px">
            <b>{{ endorsementListingName_Value }}</b>
          </p>
          <p style="text-align: left; font-size: 13px; margin-left: -70px">
            {{ endorsementListingCityName_Value }},
            {{ endorsementLiistingStateName_Value }}
          </p>
        </div>
      </div>
      <p
        style="text-align: justify; padding: 10px; font: 400 16px;"
        [innerHTML]="readMore_ReceivedEndorsement_Description | lineBreak"
      >
        <!-- {{ readMore_ReceivedEndorsement_Description}} -->
      </p>
    </mat-dialog-content>
  </form>
</ng-template>
