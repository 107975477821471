<div class="container" >
<div class="row" style="padding: 20px;">
  <div class="col-sm-6">
<h1>Submit Review</h1>
  </div>
  <div class="col-sm-6">
    <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
      <span class="material-icons">
        cancel
        </span>
    </button>
      </div>
  </div>
  <form [formGroup]="addReviewForm" (ngSubmit)="onReviewFormSubmit()">
    <div class="row">
    <mat-form-field appearance="outline"
    >
  <mat-label>Select Listing Title</mat-label>
  <mat-select [value]="listingid"
            (selectionChange)="onListChange($event)"
          >
          <mat-option *ngFor="let list of listings" [value]="list.id"><b>{{
            list.listingName
          }}</b>-<p>{{list.addressLine1}}&nbsp;{{list.cityName}}&nbsp;{{list.state}}</p></mat-option>
          </mat-select>
</mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Review Title</mat-label>
    <input matInput formControlName="reviewTitle">
  </mat-form-field>
  </div>

<div class="row">
  <div class="col">
    <p>Punctuality</p>
<app-star-rating
    [rating]="ratingPunctuality"
    [starCount]="starCountPunctuality"
    [color]="starColor"
    (ratingUpdated)="onRatingChanged($event,'punctuality')"
    >
  </app-star-rating>
</div>
<div class="col">
  <p>Service</p>
  <app-star-rating
    [rating]="ratingServices"
    [starCount]="starCountServices"
    [color]="starColor"
    (ratingUpdated)="onRatingChanged($event,'services')"
    >
  </app-star-rating>
  </div>
  <div class="col">
    <p>Quality</p>
    <app-star-rating
    [rating]="ratingQuality"
    [starCount]="starCountQuality"
    [color]="starColor"
    (ratingUpdated)="onRatingChanged($event,'quality')"
    >
  </app-star-rating>
    </div>
    <div class="col">
      <p>Price</p>
      <app-star-rating
    [rating]="ratingPrice"
    [starCount]="starCountPrice"
    [color]="starColor"
    (ratingUpdated)="onRatingChanged($event,'price')"
    >
  </app-star-rating>
      </div>
      <div class="col">
        <p>Overall</p>
        <app-star-rating
    [rating]="ratingOverall"
    [starCount]="starCountOverall"
    [color]="starColor"
    (ratingUpdated)="onRatingChanged($event,'overall')"
    >
  </app-star-rating>
        </div>
</div>
<mat-form-field class="example-full-width custom-field" appearance="outline">
  <mat-label>Review</mat-label>
  <textarea matInput formControlName="reviewDescription" placeholder="Detail description about your experience"></textarea>
</mat-form-field>
<app-multiple-drag-and-drop-img
  [businessid]="businessid"
  [listingid]="listingid"
  (sendFile)="getFile($event)"
  multipleAppDragAndDropImg>
</app-multiple-drag-and-drop-img>
<br>
  <button
      mat-raised-button
      type="submit"
      style="float: right; border-radius: 10px; width:150px; height: 50px; background-color: #333333; color:#fff"
      mat-dialog-close
    >
      Submit Review
    </button>

    <button mat-raised-button mat-dialog-close type="button" style="float: right; border-radius: 10px; width:150px; height: 50px;" class="mx-2">
      Cancel
    </button>
</form>
</div>
