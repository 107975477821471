<div class="container-fluid" style="padding-top: 10px">
  <h1 class="heading mb-4">Reviews</h1>
  <div class="row">
    <div
      class="col-lg-8"
      style="padding: 10px; display: flex; align-items: flex"
    >
      <mat-chip-list aria-label="Tab Selection">
        <button
          (click)="changeTab('All')"
          [style.color]="selectedTab === 'All' ? '#fff' : '#333333'"
          class="mx-2 mat-stroked-button"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          [selected]="selectedTab === 'All'"
          [style.background]="selectedTab === 'All' ? '#333333' : ''"
        >
          All
        </button>
        <button
          (click)="changeTab('Unread')"
          [style.color]="selectedTab === 'Unread' ? '#fff' : '#333333'"
          class="mx-2 mat-stroked-button"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          [selected]="selectedTab === 'Unread'"
          [style.background]="selectedTab === 'Unread' ? '#333333' : ''"
        >
          Unread
        </button>
      </mat-chip-list>
    </div>
    <div class="col-lg-4">
      <mat-form-field
        class="field-style custom-field example-full-width"
        style="float: right"
        appearance="outline"
      >
        <mat-select
          (selectionChange)="readreviewlistid($event)"
          placeholder="All Business Listings"
        >
          <mat-option (click)="getAllReview()"
            >All Business Listings</mat-option
          >
          <mat-option *ngFor="let list of listings" [value]="list.id">{{
            list.listingName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6" *ngFor="let review of reviews; let i = index">
      <div class="col-md-12">
        <mat-card class="screen-display" style="border-radius: 20px">
          <div class="review-card-style" style="padding-bottom: 20px">
            <div class="leaderboard-icon-style">
              <img
                [src]="review.listingProfileUrl"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="review.listingProfileUrl"
              />
              <img
                src="../../../assets/images/grey.jpg"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="!review.listingProfileUrl"
              />
            </div>
            <div class="recentReview-text-style">
              <h2 class="txt-decor" style="cursor: pointer">
                <span
                  [routerLink]="[
                    '/customer-profile',
                    review.reviewFromCustomerId
                  ]"
                  >{{ review?.customerUserName }}</span
                >
              </h2>
              <p class="star-display">
                <app-star-rating
                  [rating]="review.overall"
                  [starCount]="starCount"
                  [color]="starColor"
                ></app-star-rating>
              </p>
              <p class="reviewName">
                <span
                  class="material-icons-outlined"
                  style="color: #cdcfce; font-size: 25px"
                >
                  storefront
                </span>
                &nbsp;{{ review?.listingName }}
              </p>
              <p
                style="
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  margin-left: 93px;
                "
              >
                <span
                  class="material-icons-outlined"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ review?.listingAddress }}
              </p>
              <div class="row" style="display: flex; align-items: center">
                <div class="col-md-12">
                  <h4>
                    <b class="reviewTitle">"{{ review?.reviewTitle }}"</b>
                  </h4>
                  <p>{{ review?.reviewDescription }}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="row"
            style="display: flex; align-items: center; padding: 10px"
          >
            <div class="col-8">
              <mat-chip
                class="date-chip"
                style="
                  align-items: center;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
              >
                <span style="color: #525eb1">{{
                  review.createdDate | date : "dd-MMM-yyyy"
                }}</span></mat-chip
              >
              &nbsp;&nbsp;

              <mat-chip *ngIf="review.days > 0" class="read-reply"
                ><span style="color: #525eb1"
                  >Replied {{ review.days }} days ago</span
                ></mat-chip
              >

              <mat-chip *ngIf="review.days <= 0" class="read-reply"
                ><span style="color: #525eb1">Replied Today</span></mat-chip
              >
            </div>
            <div class="col-4">
              <span class="edit">
                <button
                  type="button"
                  mat-raised-button
                  class="mx-4 read-review"
                  style="
                    float: right;
                    background-color: #525eb1;
                    color: #fff;
                    border-radius: 10px;
                  "
                  [routerLink]="['/s/pages/create-reviews', review.id]"
                >
                  Read&nbsp;Reviews
                </button>
              </span>
              <span> </span>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
