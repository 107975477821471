import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/_services/user.service';
import { ActivitiesService } from '../../../_services/activity.services';
import { CoreService } from './../../../_services/core.services';

@Component({
  selector: 'app-display-activity-dialog',
  templateUrl: './display-activity-dialog.component.html',
  styleUrl: './display-activity-dialog.component.css'
})
export class DisplayActivityDialogComponent {
  @ViewChild('callActivityImageDialog')
  callActivityImageDialog: TemplateRef<any>;
  businessid: string;
  activities: any;
  Array = Array;
  activityColumns: any;
  activeImageData: any;

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any, private activitiesService: ActivitiesService,
    private userService: UserService,
    private coreService: CoreService,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.businessid = this.coreService.getBusinessId();
    this.getActivities();
  }

  getActivities() {
    this.activitiesService.getActivitiesByListingId(this.data.listingId).subscribe((data) => {
      this.activities = data?.items;
      this.activityColumns=this.activities.reduce((acc: any, item: any, index: any) => {
        const accIndex = index % 4;
        acc[accIndex].push(item);
        return acc;
      }, [[], [], [], []])
    })
  }

  openImageDetails(active: any){
    if(active){
      this.activeImageData = active;
    }
      const dialogRef = this.dialog.open(this.callActivityImageDialog, {
        height: '486px',
        width: '873px',
        disableClose: true
      });
  }


}
