import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ListingInformationService } from './listingInformation.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  constructor(
    private httpClient: HttpClient,
    private listingInformationService: ListingInformationService
  ) { }

  upload(
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if(file){
    formData.append('file', file);
    formData.append('fileName', fileName);
    }
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/listings/uploadImage`,
      formData
    );

    return this.httpClient.request(req);
  }

  uploadVideo(
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    uploadedIntroVideo: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if(file){
    formData.append('file', file);
    formData.append('fileName', fileName);
    }
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('uploadedIntroVideo', uploadedIntroVideo);
    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/listings/uploadImage`,
      formData
    );

    return this.httpClient.request(req);
  }

  uploadGallery(
    listingId: string,
    category: string,
    file: File,
    galleryDescription: string,
    tagUserName: any,
    featured: any,
    mediaType: any,

  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('taggedMembers', tagUserName);
    formData.append('galleryDescription', galleryDescription);
    formData.append('featured', featured);
    formData.append('mediaType', mediaType);
    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/gallery/create/image`,
      formData
    );
    return this.httpClient.request(req);
  }

  updateGallery(
    galleryId: string,
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    status: string,
    file: File,
    galleryDescription: string,
    tagUserName: any,
    featured: any,
    mediaType: any,


  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('id', galleryId);
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('status', status);
    formData.append('taggedMembers', tagUserName);
    formData.append('galleryDescription', galleryDescription);
    formData.append('featured', featured);
    formData.append('mediaType', mediaType);



    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/gallery/update/image`,
      formData
    );

    return this.httpClient.request(req);
  }


  createTeam(
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    memberFirstName: string,
    memberLastName: string,
    requestFrom: string,
    requestTo: string,
    serviceName: string,
    phoneNumber: string,
    emailAddress: string,
    joiningDate: string,
    description: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if(file){
    formData.append('file', file);
    formData.append('fileName', fileName);
    }
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('memberFirstName', memberFirstName);
    formData.append('memberLastName', memberLastName);
    formData.append('requestFrom', requestFrom);
    formData.append('requestTo', requestTo);
    formData.append('phoneNumber', phoneNumber);
    formData.append('emailAddress', emailAddress);
    formData.append('joiningDate', joiningDate);
    formData.append('description', description);
    formData.append('serviceName', serviceName);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/teammembers`,
      formData
    );

    return this.httpClient.request(req);
  }

  updateTeamMember(
    memberid: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    memberFirstName: string,
    memberLastName: string,
    requestFrom: string,
    requestTo: string,
    serviceName: string,
    phoneNumber: string,
    emailAddress: string,
    joiningDate: string,
    description: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if(file){
    formData.append('file', file);
    formData.append('fileName', fileName);
    }
    formData.append('id', memberid);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('memberFirstName', memberFirstName);
    formData.append('memberLastName', memberLastName);
    formData.append('requestFrom', requestFrom);
    if(requestTo){
    formData.append('requestTo', requestTo);
  }
    formData.append('phoneNumber', phoneNumber);
    formData.append('emailAddress', emailAddress);
    formData.append('joiningDate', joiningDate);
    formData.append('description', description);
    formData.append('serviceName', serviceName);

    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/teammembers`,
      formData
    );

    return this.httpClient.request(req);
  }

  createPromotion(
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    promotionTitle: string,
    discountValue: string,
    startDateTime: any,
    endDateTime: any,
    dollar: any,
    percentage: any,
    details: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('promotionTitle', promotionTitle);
    formData.append('discountValue', discountValue);
    formData.append('startDateTime', startDateTime);
    formData.append('endDateTime', endDateTime);
    formData.append('dollar', dollar);
    formData.append('percentage', percentage);
    formData.append('details', details);

    const req = new HttpRequest(
      'POST',
      `${environment.apiUrl}/promotions`,
      formData
    );

    return this.httpClient.request(req);
  }


  updatePromotion(
    promotionId: string,
    businessId: string,
    listingId: string,
    fileName: string,
    category: string,
    file: File,
    promotionTitle: string,
    discountValue: string,
    startDateTime: any,
    endDateTime: any,
    dollar: any,
    percentage: any,
    details: string
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    if(file){
    formData.append('file', file);
    formData.append('fileName', fileName);
  }
    formData.append('id', promotionId)
    formData.append('businessId', businessId);
    formData.append('listingId', listingId);
    formData.append('category', category);
    formData.append('promotionTitle', promotionTitle);
    formData.append('discountValue', discountValue);
    formData.append('startDateTime', startDateTime);
    formData.append('endDateTime', endDateTime);
    formData.append('dollar', dollar);
    formData.append('percentage', percentage);
    formData.append('details', details);

    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/promotions`,
      formData
    );

    return this.httpClient.request(req);
  }

  getFiles(listingid: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/listings/profile/${listingid}`, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  downloadFile(
    productRequestId: string,
    documentId: string,
    providerId: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('providerId', providerId);
    return this.httpClient
      .get(
        `${environment.apiUrl}/request/` +
        productRequestId +
        `/document/` +
        documentId,
        {
          params: params,
          reportProgress: true,
          observe: 'events',
          responseType: 'blob',
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteFile(listingId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.delete<any>(
      `${environment.apiUrl}/listings/${listingId}`
    );
  }
}
