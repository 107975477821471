import { CoreService } from 'src/app/_services/core.services';
import { Component, TemplateRef, ViewChild } from '@angular/core';
// import { ApiService } from 'src/app/_services/reviews.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { ReviewService } from 'src/app/_services/review.services';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { MatDialog } from '@angular/material/dialog';
import { ReviewReportDialogComponent } from '../review-report-dialog/review-report-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-review-create',
  templateUrl: './review-create.component.html',
  styleUrls: ['./review-create.component.css'],
})
export class ReviewCreateComponent {

  @ViewChild('callDeleteReviewReplyDialog')
callDeleteReviewReplyDialog: TemplateRef<any>;

  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;

  submitReplyForm: FormGroup;
  reviewId: string;
  reviewData: any;
  reviews: any;
  reviewDetails: any;
  businessid: string;
  primary : any;
  alllistings: any;
  listingData: any;
  listingDatas: any;
  listingCity: any;
  listingState: any;
  isloader: any = true;
  isEdit: boolean = false;
  updateId: string;
  singleData: any;
  formattedReplyContent: any;
  formattedReviewDescription: any;

  constructor(private activatedRoute: ActivatedRoute,
    public reviewService: ReviewService,
    public formBuilder: FormBuilder,
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    ){

  }

  ngOnInit(){
    this.mainForm();
    this.reviewId = this.activatedRoute.snapshot.params['id'];
    this.businessid = this.coreService.getBusinessId();
    this.updateReviewStatusReadById(this.reviewId);
    this.getReviewById();
    this.getReplyDetails();
    this.getPrimaryList();
  }

  mainForm() {
    this.submitReplyForm = this.formBuilder.group({
      replyContent: [''],
  });
}

getReviewById(){
  this.reviewService.reviewById(this.reviewId).subscribe((data)=>{
    this.reviewData = data?.data;
    // let reviewDetails = this.reviewData.listingAddress.split('\n} {\n');
    // const jsonObjects = reviewDetails.map((str:any, index: any, arr: any) => {
    //   if (index === 0) return JSON.parse(str + '\n}');
    //   if (index === arr.length - 1) return JSON.parse('{\n' + str);
    //   return JSON.parse('{\n' + str + '\n}');
    // });
    // this.listingCity = jsonObjects[0].name;
    // this.listingState = jsonObjects[1].name;
    this.formattedReviewDescription=this.reviewData?.reviewDescription.replace(/\n/g, '<br/>');
    this.getAllListings();
  })
}

getAllListings(){
 // this.isloader = true;
  this.listingInformationService.getListings().subscribe((data)=>{
  this.alllistings = data;
  this.listingDatas = this.alllistings?.filter((r: any) => r.id === this.reviewData?.reviewToListingId);
   let sortedDataList = this.listingDatas;

    // let sortedDataList = this.listingDatas.map((element:any) => {
    //   if(element.cityName && this.isValidJSON(element.cityName)){

    //     element.cityName = JSON.parse(element.cityName).name
    //   }
    //   if(element.state && this.isValidJSON(element.state)){
    //     element.state = JSON.parse(element.state).name
    //   }
    //   if(element.country && this.isValidJSON(element.country)){
    //     element.country = JSON.parse(element.country).name
    //   }
    //   return element;
    // });
    this.listingData = sortedDataList;
  });
}
// isValidJSON(str:any) {
// try {
//   JSON.parse(str);
//   return true;
// } catch (e) {
//   return false;
// }
// };


  onReplySubmit(){
    if (this.isEdit) {
      let obj = {
        "id": this.updateId,
        "replyFromListing": this.primary.id,
        "replyToCustomer": this.reviewData.reviewFromCustomerId,
        "replyContent": this.submitReplyForm.value.replyContent,
        "reviewId": this.reviewId,
        "status": "Active"
      }
      this.reviewService.updateReviewReply(obj).subscribe((data)=>{
        this.showSnackbarTopPosition('Reply updated successfully', 'Done', '1000');
        this.isEdit = false;
        this.getReplyDetails();
      })
  }else{
    let obj = {
      "replyFromListing": this.primary.id,
      "replyToCustomer": this.reviewData.reviewFromCustomerId,
      "replyContent": this.submitReplyForm.value.replyContent,
      "reviewId": this.reviewId,
      "status": "Active"
    }
    this.reviewService.sendReviewReply(obj).subscribe((data)=>{
      this.showSnackbarTopPosition('Reply sent successfully', 'Done', '1000');
      this.getReplyDetails();
    })
  }
  }

  // readReview() {
  //   this.reviewService.getReview().subscribe((data) => {
  //     this.reviews = data?.list;
  //     this.reviewDetails = this.reviews.filter((r:any) => r.id === this.reviewId);
  //     console.log(this.reviewDetails,'rev details')
  //   });
  // }

  getReplyDetails(){
    this.reviewService.getReviewReply(this.reviewId).subscribe((data)=>{
      this.reviewDetails = data?.data;
      this.formattedReplyContent=this.reviewDetails?.replyContent.replace(/\n/g, '<br/>');
      this.singleData =  data?.data;
      if(this.isEdit){
      this.submitReplyForm.patchValue({
        replyContent: this.singleData.replyContent
    })
  }
  })
  }

  getPrimaryList(){
    this.listingInformationService.getPrimary(this.businessid).subscribe((data)=>{
      setTimeout(() =>{
        this.primary = data;
         this.isloader = false;
        },2000)
    })
  }

  reportReview(id:any){
    const dialogRef = this.dialog.open(ReviewReportDialogComponent, {
      data: {
        reviewId: id
      },
      height: '40vh',
      width: '30vw',
    });
  }

  usefullReview(id: any){
    let useful = true
    this.reviewService.sendUsefullReview(id, useful).subscribe((data)=>{
    })
  }

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top",
      horizontalPosition: "right"
    });
  }

  editReply(id: string){
    if (id) {
      this.updateId = id;
      this.isEdit = true;
      this.getReplyDetails()
    }
  }

  getReplyById(id: any){
    this.reviewService.getReview().subscribe((data) => {
      this.reviews = data?.list;
      this.reviewDetails = this.reviews.filter((r:any) => r.id === this.reviewId);
    });
  }


  deleteReply(id: any, index: any) {
    const dialogRef = this.dialog.open(this.callDeleteReviewReplyDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.showSnackbarTopPosition('Review Reply deleted successfully', 'Done', '1000');
        this.reviewService.deleteReviewReply(id).subscribe((data)=> {
          this.reviewDetails.splice(index, 1);
          this.getReplyDetails();
        });
      } else {
        if (result === 'cancel') {
          this.getReplyDetails();
        }
      }
    });
  }

  updateReviewStatusReadById(reviewId: string){
    this.reviewService.updateReviewStatusReadById(reviewId).subscribe((data)=>{
    })
  }
}
