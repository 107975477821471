import { Component, VERSION } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from 'src/app/_services/review.services';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { ApiService } from '../../_services/reviews.service';
import { CoreService } from './../../_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css'],
})
export class ReviewsComponent {
  listingid: string;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;
  isloader: any = true;
  currentRate = 0;
  businessid: string;
  listings: any;
  reviews: any = [];
  selectedTab: string = 'All';
  alllistings: any;
  listingData: any;
  reviewDetails: any;

  constructor(
    private apiService: ApiService,
    private reviewService: ReviewService,
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.businessid = this.coreService.getBusinessId();
    this.readReview();
    this.getListings();
    // this.getReplyDetails();
    // this.claculateDifference();
  }

  getListings() {
    let publishedListings: any;
    this.listingInformationService.getListings().subscribe((data: any) => {
      if (data) {
        publishedListings = data.filter((e: any) => e.status == 'Published');
        this.listings = publishedListings;
        // Sort the listings in ascending order based on listingName
        this.listings.sort((a: any, b: any) => {
          // Use localeCompare for case-insensitive string comparison
          return a.listingName.localeCompare(b.listingName);
        });
      }
    });
  }

  formatdate(date: any) {
    var utcDate = new Date(date);
    // Get the UTC time in milliseconds
    var utcTime = utcDate.getTime();
    // Calculate the IST time by adding 5 hours and 30 minutes (in milliseconds)
    var istTime = utcTime + 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    // Create a new date object with the IST time
    var istDate = new Date(istTime);
    console.log(istDate);
    return istDate;
  }

  readReview() {
    this.reviewService.getReview().subscribe((data) => {
      if (data?.list) {
        let sortedDataList = data?.list;
        this.reviews = sortedDataList;

        if (this.reviews) {
          for (let i = 0; i < this.reviews.length; i++) {
            this.reviewService
              .getReviewReply(this.reviews[i].id)
              .subscribe((data) => {
                if (data) {
                  let date1: any;
                  let date2: any;
                  date1 = this.formatdate(data?.data.createdDate);
                  date2 = new Date();
                  var date1WithoutTime = new Date(
                    date1.getFullYear(),
                    date1.getMonth(),
                    date1.getDate()
                  );
                  var date2WithoutTime = new Date(
                    date2.getFullYear(),
                    date2.getMonth(),
                    date2.getDate()
                  );
                  var difference_ms =
                    date2WithoutTime.getTime() - date1WithoutTime.getTime();
                  var difference_days = Math.floor(
                    difference_ms / (1000 * 60 * 60 * 24)
                  );
                  this.reviews[i]['days'] = difference_days;
                }
              });
          }
        }
      }
    });
  }

  unreadReview() {
    this.reviewService.getUnreadReview().subscribe((data) => {
      let sortedDataList = data?.list;
      let filteredData = sortedDataList.filter(
        (e: any) => e.reviewRead == 'Unread'
      );
      this.reviews = filteredData;
    });

    this.reviews.forEach((element: any) => {
      this.reviewService.getReviewReply(element.id).subscribe((data) => {
        if (data) {
          let date1: any;
          let date2: any;
          date1 = this.formatdate(data?.data.createdDate);
          date2 = new Date();
          var date1WithoutTime = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate()
          );
          var date2WithoutTime = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate()
          );
          var difference_ms =
            date2WithoutTime.getTime() - date1WithoutTime.getTime();
          var difference_days = Math.floor(
            difference_ms / (1000 * 60 * 60 * 24)
          );
          element['days'] = difference_days;
          return difference_days;
        }
      });
    });
  }

  readFullReview() {
    this.router.navigateByUrl('/s/pages/create-reviews');
  }

  changeTab(status: string) {
    this.selectedTab = status;
    if (this.selectedTab == 'All') {
      this.readReview();
    }
    if (this.selectedTab == 'Unread') {
      this.unreadReview();
    }
  }

  readreviewlistid(e: any) {
    this.listingid = e.value;
    this.sortReviewByListing();
  }

  sortReviewByListing() {
    this.reviewService.getSortedReview(this.listingid).subscribe((data) => {
      let sortedDataList = data?.list;
      this.reviews = sortedDataList;
    });
  }

  // getReplyDetails(){
  //   this.reviewService.getReviewReply(this.reviewId).subscribe((data)=>{
  //     this.reviewDetails = data?.data;
  //     console.log(this.reviewDetails,'rv details')
  // })
  // }

  getAllReview() {
    this.readReview();
  }
}
