import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '../material-imports';
import { FormsModule } from '@angular/forms';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { OverallStarComponent } from './overall-star/overall-star.component';
import { TaggedImageLightboxComponent } from './tagged-image-lightbox/tagged-image-lightbox.component';
import { GalleryLightboxComponent } from './gallery-lightbox/gallery-lightbox.component';
import { LineBreak } from './lineBreak.pipe';
import { SignupComponent } from './signup/signup.component';

// import { ClickOutsideModule } from 'ng-click-outside';
@NgModule({
  declarations: [NavBarComponent, SideBarComponent, StarRatingComponent, OverallStarComponent,TaggedImageLightboxComponent, GalleryLightboxComponent, LineBreak, SignupComponent],
  imports: [CommonModule, RouterModule, AngularMaterialModule, FormsModule],
  exports: [NavBarComponent, SideBarComponent, StarRatingComponent, OverallStarComponent, TaggedImageLightboxComponent, GalleryLightboxComponent, LineBreak, SignupComponent],
})
export class SharedModule {}
