import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { IListingInformations } from '../_models/listingInformation.model';
// import * as countrycitystatejson from 'countrycitystatejson';

@Injectable({
  providedIn: 'root',
})
export class ListingInformationService {
  // private countryData = countrycitystatejson;
  constructor(private httpClient: HttpClient) {}

  //create listing
  createListing(
    listingInfo: IListingInformations
  ): Observable<IListingInformations> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/listings`, listingInfo, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //get listings provider
  getListings() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/provider`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getAllListings() {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient.get<any>(`${environment.apiUrl}/listings`).pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getSingleListing() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/provider`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  // getAll
  allListings() {
    return this.httpClient.get<any>(`${environment.apiUrl}/listings`).pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }



  getListingByUserName(listingUserName: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/publicUri/listings/profile/userName/${listingUserName}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //get listing by id
  getListing(listingId: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/listings/profile/${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getAllCustomers() {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/customer`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getOtherProviderListings(providerId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/listings/other/provider?providerId=${providerId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getViewers(listingId: any, order: any, pageSize: any, pageIndex: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/listings/viewedBy?listingId=${listingId}&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  // getPagination(listingId: any, pageSize: any, pageNumber: any)
  //   : Observable<any> {
  //     return this.httpClient
  //       .post<any>(`${environment.apiUrl}/listings/viewedBy?listingId=${listingId}`, pageSize, pageNumber)
  //       .pipe(
  //         catchError((err) => {
  //           console.error(err);
  //           throw err;
  //         })
  //       );
  // }

  getPublishedListing(selectedTab?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/listings/provider?status=${selectedTab}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  setPrimary(listingId: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/setprimary?listingId=${listingId}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  createFavoriteListing(
    savedListingId: string,
    saved: boolean
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/favoritelistings/create`,
        { savedListingId, saved },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  checkAlreadySave(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/favoritelistings/existingcheck?listingId=${listingId}`
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getAllCategories() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/categories/all`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getPrimary(businessId: string) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/listings/primary?businessId=${businessId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getAllPrimaryListings() {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/listings/primary`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //update listing
  updateLocation(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/location`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateListing(id: string, payload: any): Observable<IListingInformations> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/listings`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  selectGalleryTags() {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/published`, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  tagPeopleDropDown() {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/gallery/dropdown/list?eventType=gallery`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getProviderPrimaryListing(id: any) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/listings/other/provider?providerId=${id}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getToDropDownData(eventType: string) {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/gallery/dropdown/list?eventType=${eventType}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getView(viewedListing: any) {
    return this.httpClient
      .post<any>(`${environment.apiUrl}/listings/views`, viewedListing)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }
  getListingByid(listingid: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/publicUri/listings/profile/${listingid}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getListingByidGlobal(listingid: string): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/profile/${listingid}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  media(
    id: string,
    introYoutubeLink: string,
    uploadedIntroVideo: any,
    uploadedYouTubeLink: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/intro/video`,
        { id, introYoutubeLink, uploadedIntroVideo, uploadedYouTubeLink },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateMedia(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/socialmedia`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateBusinessHours(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/businesshours`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateAdditionalDetails(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/additionaldetails`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateStatus(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/status`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updatePrimary(id: string, payload: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/listings/setprimary`,
        { ...payload, id },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //delete listing
  deleteListingData(listingid: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/listings/${listingid}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  searchGlobally(array: any, searchTerm: any) {
    let results: any[] = [];
    array.forEach((obj: any) => {
      // Check if any property of the object matches the search term
      if (
        Object.values(obj).some(
          (value) =>
            (typeof value === 'string' &&
              value.toLowerCase().includes(searchTerm)) ||
            (Array.isArray(value) && value.includes(searchTerm))
        )
      ) {
        results.push(obj);
      } else {
        // Check if any element in nested arrays matches the search term
        Object.values(obj).forEach((val) => {
          if (
            Array.isArray(val) &&
            val.some((item) => item.toLowerCase().includes(searchTerm))
          ) {
            results.push(obj);
          }
        });
      }
    });
    return results;
  }
  //Get All PublishedListings
  getAllPublishedListings() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/published`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
  //Get PrimaryListing
  getPrimaryListing() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/listings/primary`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
