import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ApiService } from './_services/reviews.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigService } from './auth/auth-config.service';
import { AuthGuard } from './auth/auth.guard';
import { DialogConfirmComponent } from './components/file-upload/dialog-confirm/dialog-confirm.component';
import { FileDragNDropDirective } from './components/file-upload/file-drag-n-drop.directive';
import { AngularMaterialModule } from './material-imports';
import { PromotionsComponent } from './pages/promotions/promotions.component';
// import { LocalStorageService } from './_services/local-storage.service';
import { CommonModule, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { CarouselModule } from 'primeng/carousel';
import { RouterComponent } from './components/router/router.component';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
// import * as countrycitystatejson from 'countrycitystatejson';

function initializeKeycloak(
  keycloak: KeycloakService,
  authConfig: AuthConfigService
) {
  return () =>
    keycloak.init({
      config: {
        url: authConfig.auth,
        realm: authConfig.realm,
        clientId: authConfig.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-renew.html',
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: [],
    });
}

@NgModule({
  declarations: [
    AppComponent,
    DialogConfirmComponent,
    FileDragNDropDirective,
    DialogConfirmComponent,
    PromotionsComponent,
    RouterComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    KeycloakAngularModule,
    NgxChartsModule,
    SharedModule,
    NgxMatIntlTelInputComponent,
    MatStepperModule,
    NgxChartsModule,
    AngularEditorModule,
    MatFormFieldModule,
    MatInputModule,
    RxReactiveFormsModule,
    NgIf,
    NgxMatTimepickerModule,
    CarouselModule,
    MatPaginatorModule,
  ],
  providers: [
    ApiService,
    AuthGuard,
    AuthConfigService,
    // LocalStorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthConfigService],
    },
    {
      provide: Window,
      useValue: window,
    },

    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
