<div class="container-fluid">
  <div class="card-orview">
    <button
      class="routerLink"
      [routerLink]="['/s/pages/reviews']"
      routerLinkActive="active"
    >
      <mat-icon>chevron_left</mat-icon>
      <h1 class="mb-0 txt-decor">
        {{ reviewData?.customerUserName }}'s Review
      </h1>
    </button>
  </div>
  <mat-card class="card-style" style="padding: 10px; border-radius: 20px">
    <div class="row" style="padding: 10px">
      <div class="col-xl-2">
        <img
          src="../../../assets/images/grey.jpg"
          alt="Avatar"
          style="height: 120px; width: 120px; border-radius: 10px"
          *ngIf="!reviewData?.customerProfileUrl"
        />
        <img
          src="{{ reviewData?.customerProfileUrl }}"
          alt="Avatar"
          style="height: 120px; width: 120px; border-radius: 10px"
          *ngIf="reviewData?.customerProfileUrl"
        />

        <div class="my-2">
          <h1 class="txt-captial">{{ reviewData?.customerUserName }}</h1>
          <p style="display: flex; align-items: center; color: #7d7d7d">
            <mat-icon class="material-icons-outlined"> location_on </mat-icon
            >&nbsp;{{ reviewData?.listingAddress }}
          </p>
          <p style="display: flex; align-items: center; color: #7d7d7d">
            <mat-icon class="material-icons-outlined"> phone </mat-icon>&nbsp;
            {{ reviewData?.listingPhoneNumber }}
          </p>
          <p style="display: flex; align-items: center; color: #7d7d7d">
            <mat-icon class="material-icons-outlined"> storefront </mat-icon
            >&nbsp;{{ reviewData?.listingName }}
          </p>
        </div>
      </div>
      <div class="col-sm-7" style="padding-right: 110px">
        <h2>"{{ reviewData?.reviewTitle }}"</h2>
        <p [innerHTML]="formattedReviewDescription"></p>
        <!-- <div class="row">
          <div class="col-sm-2" *ngIf="reviewData.useful === false">
        <button mat-stroked-button class="notuseful"  (click)="usefullReview(reviewData.id)"><mat-icon class="material-icons-outlined" style="display: flex; align-items: center;">
          thumb_up
          </mat-icon>Usefull</button>
        </div>
        <div class="col-sm-2" *ngIf="reviewData.useful === true">
          <button mat-flat-button disabled class="useful"><mat-icon class="material-icons-outlined" style="display: flex; align-items: center;">
            thumb_up
            </mat-icon>Usefull</button>
          </div>
        <div class="col-sm-1 vl">

        </div>
          <div class="col-sm-2" *ngIf="reviewData.reviewReported === false">
          <button mat-stroked-button class="report" (click)="reportReview(reviewData.id)"><mat-icon class="material-icons-outlined" style="display: flex; align-items: center;">
            error_outline
            </mat-icon> Report</button>
          </div>
          <div class="col-sm-2" *ngIf="reviewData.reviewReported === true">
            <button mat-flat-button disabled class="reported"><mat-icon class="material-icons-outlined" style="display: flex; align-items: center;">
              error_outline
              </mat-icon> Reported</button>
            </div>
          </div> -->
        <div class="col-sm-12">
          <mat-chip
            style="
              align-items: center;
              display: inline;
              float: right;
              padding: 5px;
              background-color: #f4f5ff;
            "
            >{{ reviewData?.createdDate | date : "dd-MMM-yyyy" }}</mat-chip
          >
        </div>
      </div>
      <div class="col-sm-3">
        <app-overall-star
          [rating]="reviewData?.overall"
          [starCount]="starCount"
          [color]="starColor"
          [isReviewShow]="true"
          [reviews]="reviewData?.Overall"
        ></app-overall-star>
        <mat-divider></mat-divider>
        <p style="display: flex; align-items: center">
          <span class="reviwscss">Punctuality</span>
          <app-star-rating
            [rating]="reviewData?.punctuality"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex; align-items: center">
          <span class="reviwscss">Service</span>
          <app-star-rating
            [rating]="reviewData?.service"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex; align-items: center">
          <span class="reviwscss">Quality</span>
          <app-star-rating
            [rating]="reviewData?.quality"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex; align-items: center">
          <span class="reviwscss">Price</span>
          <app-star-rating
            [rating]="reviewData?.price"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
        <p style="display: flex; align-items: center">
          <span class="reviwscss">Overall</span>
          <app-star-rating
            [rating]="reviewData?.overall"
            [starCount]="starCount"
            [color]="starColor"
          ></app-star-rating>
        </p>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <div class="col-sm-2 my-4" style="text-align: center">
        <img
          src="../../../assets/images/grey.jpg"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="!reviewData?.listingProfileUrl"
        />
        <img
          src="{{ reviewData?.listingProfileUrl }}"
          style="height: 40px; width: 40px; border-radius: 30px"
          *ngIf="reviewData?.listingProfileUrl"
        />
        <p>{{ primary?.listingName }}</p>
      </div>
      <div class="col-sm-10 my-4">
        <div *ngIf="!reviewDetails?.replyContent || isEdit">
          <form [formGroup]="submitReplyForm" (ngSubmit)="onReplySubmit()">
            <p class="txt-captial">
              Reply to {{ reviewData?.customerUserName }}
            </p>
            <mat-form-field
              class="example-full-width custom-field"
              appearance="outline"
            >
              <textarea
                matInput
                placeholder="Enter Reply"
                formControlName="replyContent"
              ></textarea>
            </mat-form-field>
            <button mat-raised-button class="submit-reply mx-2" type="submit">
              Submit Reply
            </button>
            <button
              mat-stroked-button
              class="cancel-reply"
              type="button"
              [routerLink]="['/s/pages/reviews']"
              routerLinkActive="active"
            >
              Cancel
            </button>
          </form>
        </div>
        <div *ngIf="!isEdit && reviewDetails?.replyContent">
          <p class="txt-captial">
            <b>Reply to {{ reviewData?.customerUserName }}</b>
          </p>
          <!-- <h3>{{reviewDetails?.replyContent}}</h3> -->
          <p [innerHTML]="formattedReplyContent"></p>
          <!-- <span><mat-icon *ngIf="reviewDetails?.replyContent" style="float: right; display: flex; align-items: center;">more_vert</mat-icon></span> -->
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            style="float: right; margin-top: -45px"
          >
            <span class="material-icons"> more_vert </span>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editReply(reviewDetails.id)">
              Edit
            </button>
            <button
              mat-menu-item
              (click)="deleteReply(reviewDetails.id, reviewDetails.i)"
            >
              Delete
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<ng-template #callDeleteReviewReplyDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation!</b>
    </p>
    <mat-dialog-content>
      <p style="text-align: center; padding: 20px; font: 400 20px;">
        Are you sure you want to delete this review reply?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
