<div class="container-fluid" style="padding-top: 10px;">
  <mat-dialog-content>
    <div class="row">
      <div class="col col-9">
        <h1>Contact {{currentListing}}</h1>
      </div>
      <div class="col col-3">
        <button mat-mini-fab mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div>
      <form [formGroup]="contactForm" class="my-4" (ngSubmit)="onContactFormSubmit()">
        <div class="row">

          <div class="col-md-6">
            <p style="margin-bottom: 0px;">First Name</p>
            <mat-form-field appearance="outline" class="example-full-width custom-field">
              <input matInput formControlName="firstName" placeholder="First Name" readonly/>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <p style="margin-bottom: 0px;">Last Name</p>
            <mat-form-field class="example-full-width custom-field" appearance="outline">
              <input matInput formControlName="lastName" placeholder="First Name" readonly/>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p style="margin-bottom: 0px;">Subject</p>
            <mat-form-field  class="example-full-width custom-field" appearance="outline">
                <input matInput formControlName="subjectContent" placeholder="Enter subject">
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <p style="margin-bottom: 0px;">Message</p>
          <mat-form-field
          class="example-full-width custom-field"
          appearance="outline"
        >
          <textarea
            matInput
            placeholder="Enter Message"
            rows="6"
            formControlName="messageContent"
          ></textarea>
          <!-- <mat-error
          *ngIf="submitted && myForm?.['listingTitle']?.errors?.['required']"
        >
          Message is Required
        </mat-error> -->
        </mat-form-field>
      </div>
        </div>

        <button mat-raised-button type="submit" style="float: right; background-color: #525EB1; color:#fff; border-radius: 10px;" mat-dialog-close>
          Send Message
        </button>
        <button mat-raised-button mat-dialog-close style="float: right; border-radius: 10px;" class="mx-2">
          Cancel
        </button>
      </form>
    </div>
  </mat-dialog-content>
</div>
