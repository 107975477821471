
<div class="container-fluid" style="padding-top: 10px; background-color: #fff;">
  <mat-dialog-content>
    <div class="row">
      <div class="col col-9">
        <h1>All Activity</h1>
      </div>
      <div class="col col-3">
        <button mat-mini-fab  mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
<br>
    <div class="row" >
      <ng-container *ngFor="let column of activityColumns">
      <div class="col-md-4">
        <section *ngFor="let active of column">
          <div class="col-md-12" *ngIf="active?.activityImageURL">
            <div class="container1"  (click)="openImageDetails(active)" style="background-color: #F1F0F5; border-radius: 10px;">
              <img src="{{ active?.activityImageURL }}" class="image"
             />
              <div class="overlay">
                <div class="text">
                  <span class="title">
                    <p>{{ active?.title  }}</p>
                  </span>
                  <span class="description">
                    <p [innerHTML]="active?.description | lineBreak"></p>
                  </span>
                  <span>
                    <p> {{ active?.createdDate | date : "dd-MMM-yyyy" }} </p>
                  </span>
              </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="!active?.activityImageURL" (click)="openImageDetails(active)">
            <div class="container2">
              <div class="text2">
              <span class="title">
                <p>{{ active?.title }}</p>
              </span>
              <span class="description">
                <p [innerHTML]="active?.description | lineBreak"></p>
              </span>
              <span>
                <p>{{ active?.createdDate | date : "dd-MMM-yyyy" }}</p>
              </span>
          </div>
          </div>
          </div>
        </section>
      </div>
  </ng-container>
    </div>
  </mat-dialog-content>
</div>


<ng-template #callActivityImageDialog>
  <body>
  <div>
    <form #userForm="ngForm" style="overflow-x: hidden; overflow-y: scroll; scrollbar-width: none;">
      <div class="row">
        <div class="col-md-6" *ngIf="activeImageData.activityImageURL">
            <img [src]="activeImageData.activityImageURL"
             class="activity-img-style" style="object-fit: contain; background-color: #F1F0F5;"/>
        </div>
        <div class="col-md-6" *ngIf="activeImageData.activityImageURL">
          <div class="row">
            <div class="col-8">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                <p style=" margin-top: 15px;"><b class="activity-head">Activity</b></p>
              </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-center">
              <span class="material-icons-outlined" style="cursor: pointer;" mat-dialog-close>cancel</span>
            </div>
          </div>
          <hr style="width: 90%; margin: 0 auto;">
          <h2 class="p-3">{{activeImageData.title}}</h2>
          <p class="p-3 desc-height" [innerHTML]="activeImageData.description | lineBreak" ></p>
          <hr style="width: 90%; margin: 0 auto;">
          </div>
          <div class="col-md-12" *ngIf="!activeImageData.activityImageURL">
            <div class="row">
              <div class="col-8">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                  <p style=" margin-top: 15px; margin-left: 10px;" class="p-3"><b>Activity Details</b></p>
                </div>
                </div>
              </div>
              <div class="col-3 d-flex justify-content-end align-items-center">
                <span class="material-icons-outlined" style="cursor: pointer;"  mat-dialog-close>cancel</span>
              </div>
            </div>
            <hr style="width: 95%; margin: 0 auto;">
            <h2 class="p-3" style="margin-left: 10px;">{{activeImageData.title}}</h2>
            <p class="p-3 desc-height" style=" margin-left: 10px; margin-top: -5px;" [innerHTML]="activeImageData.description | lineBreak"></p>
            <hr style="width: 95%; margin: 0 auto;">
            </div>
      </div>
    </form>
  </div>
</body>
</ng-template>
