import { DynamicSearchService } from 'src/app/_services/dynamic-search.services';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.css',
})
export class SearchResultsComponent {
  listings: any = [];
  searchValue: any;
  latitude: any;
  longitude: any;
  listingUserName: string;
  givenDataTotal = 0;
  pageSize = 20;
  pageIndex = 0;
  individual: any;
  business:any;
  education: any;
  student: any;
  noDataFoundDivDisplayStyle: any="none";
  searchOptions: any;
  sortedDataList: any;
  filterListingsData: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dynamicSearchService: DynamicSearchService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private listingInformationService: ListingInformationService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.searchValue = params['value'];
      this.getSearchData();
    });
  }

  pageChange(pageSize: any, pageIndex: any) {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex + 1;
    if (this.searchOptions != '' && this.searchOptions != undefined)
    {
      this.onFilterApply();
    }
    else
    {
      this.getSearchData();
    }
  }
  filterForm = this._formBuilder.group({
    Individual: ['', Validators.required],
    Business: ['', Validators.required],
    Education: ['', Validators.required],
    Student: ['', Validators.required],
  });

  onFilterApply(){
    
    this.individual = this.filterForm.get('Individual')?.value ? 'Individual' : '';
    this.business = this.filterForm.get('Business')?.value ? 'Business' : '';
    this.education = this.filterForm.get('Education')?.value ? 'Education' : '';
    this.student = this.filterForm.get('Student')?.value ? 'Student' : '';

    if (this.business === '' && this.individual === '' && this.education === '' && this.student === '')
    {
      this.filterListingsData = [];
      this.searchOptions = '';
      this.getSearchData();
    }
    else
    {
        this.searchOptions = '';
        if (this.individual != '' && this.individual != undefined)
          this.searchOptions = this.individual + ",";
        if (this.business != '' && this.business != undefined)
          this.searchOptions += this.business + ",";
        if (this.education != '' && this.education != undefined)
          this.searchOptions += this.education + ",";
        if (this.student != '' && this.student != undefined)
          this.searchOptions += this.student; 
    
        if (this.searchOptions != '')
        {
          this.listings = [];
          this.searchOptions = this.searchOptions.replace(/,$/, ''); 
          console.log('search Options text is... ', this.searchOptions);
          const order = 'DESC'
          if (this.pageIndex > 0)
            this.pageIndex=this.pageIndex-1;
          this.dynamicSearchService.filterListingsbyOption(this.searchValue, this.searchOptions, order, this.pageSize, this.pageIndex).subscribe((data: any)=>{
            this.filterListingsData=data?.list;
            this.listings = data?.list;
            this.givenDataTotal = data?.total;
            
            if (data?.list === undefined)
              this.noDataFoundDivDisplayStyle="block";
            else if (data?.list.length > 0)
              this.noDataFoundDivDisplayStyle="none";
          })

          if (this.filterListingsData && this.filterListingsData.length === 0)
            {
              this.pageSize=20;
              this.pageIndex=0;
              this.givenDataTotal = 0;
            }
        }
    }
  }

  getSearchData() {
    if (this.pageIndex > 0)
      this.pageIndex=this.pageIndex-1;
    const order = 'DESC'
    this.dynamicSearchService
      .createSearch(this.searchValue, order, this.pageSize, this.pageIndex)
      .subscribe((data) => {
        this.getListings(data);
      });
  }


  getListings(data: any) {
    this.listings = [];
    if (data?.list.length === undefined)
      this.noDataFoundDivDisplayStyle="block";
    else if (data?.list.length > 0)
      this.noDataFoundDivDisplayStyle="none";
    let sortedDataList = data?.list
    this.listings = sortedDataList;
    this.givenDataTotal = data?.total;
  }


  back() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }
}
