import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreService } from 'src/app/_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrl: './video-dialog.component.css',
})
export class VideoDialogComponent {
  @ViewChild('youTubePlayer') youTubePlayer: ElementRef<HTMLDivElement>;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLDivElement>;

  videoHeight: number | undefined;
  videoWidth: number | undefined;
  profile: any;
  displayURL: any;
  apiLoaded = false;
  videoId: any;
  height = 510;
  width = 900;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private listingInformationService: ListingInformationService,
    private coreService: CoreService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getListingsbyBusiness();
  }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onResize(): void {
    // you can remove this line if you want to have wider video player than 1200px
    this.videoWidth = Math.min(
      this.youTubePlayer.nativeElement.clientWidth,
      1200
    );

    // this.videoWidth = Math.min(
    //   this.videoPlayer.nativeElement.clientWidth,
    //   1200
    // );
    // so you keep the ratio
    this.videoHeight = this.videoWidth * 0.6;

    if (window.innerWidth < 600) {
      this.videoHeight = this.videoWidth * 0.6;
    }
    this.changeDetectorRef.detectChanges();
  }

  getListingsbyBusiness() {
    this.listingInformationService
      .getListing(this.data.listingId)
      .subscribe((data) => {
        this.profile = data?.data;
        this.displayURL = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.profile.introVideoURL
        );
        let youtubevideoURL = this.profile?.introYoutubeLink;
        let searchLink = youtubevideoURL?.split('v=');
        let searchlink = 'https://www.youtube.com/watch?';

        if (searchLink[0] == searchlink) {
          let splited = youtubevideoURL?.split('v=');
          console.log(splited, 'v=')
          let splitedAgain = splited[1]?.split('&');
          console.log(splited, '&')
          this.videoId = splitedAgain[0];
          console.log(this.videoId, 'id')
        } else {
          let splited1 = youtubevideoURL?.split('https://youtu.be/');
          let splitedAgain1 = splited1[1]?.split('?si=');
          this.videoId = splitedAgain1[0];
        }
      });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
}
