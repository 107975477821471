import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { IGallery } from '../_models/gallery.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(private httpClient: HttpClient) {}

  getMyBusinessGallery(order: any, pageSize: any, pageIndex: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/gallery/images?order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getBusinessGallery(){
    return this.httpClient
      .get<any>(`${environment.apiUrl}/gallery/images`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getBusinessFeaturedGallery(){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/gallery/images`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getBusinessFeaturedGalleryData(order: any, pageSize: any, pageIndex: any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/gallery/images?order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getListingGallery(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/gallery/images?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getListingGalleryById(listingId: string){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/publicUri/gallery/provider/listing?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }


  getImageById(galleryId: string) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/gallery/find/image/${galleryId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  updateGalleryDetails(galleryInfo: IGallery): Observable<IGallery> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/gallery/provider`, galleryInfo, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteGalleryImage(galleryId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/gallery/image/${galleryId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  addFeatureImage(imageId: string, featured: boolean): Observable<any> {
      const headers = { 'content-type': 'application/json' };
      return this.httpClient
        .put<any>(`${environment.apiUrl}/gallery/image/update/featured?imageId=${imageId}&featured=${featured}` , { headers })
        .pipe(
          catchError((err) => {
            console.error(err);
            throw err;
          })
        );
  }

  customerGallery(customerId: string){
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/gallery/customer/${customerId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }


  customerGalleryImg(customerId: string){
    return this.httpClient
      .get<any>(`${environment.apiUrl}/gallery/images/customer/profile?customerId=${customerId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  taggeduser(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/tagged/create/request`, obj, {
        headers,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getTaggedPost(){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=approved`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }


  getTaggedPostPagination(order: any, pageSize: any, pageIndex: any){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=approved&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getTaggedPostbyId(listingId: any){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts/listingId?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getTaggedRequest(){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=pending`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getTaggedRequestData(order: any, pageSize: any, pageIndex: any){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/publicUri/gallery/tagged-posts?tagStatus=pending&order=${order}&pageSize=${pageSize}&pageIndex=${pageIndex}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  likeImage(

    imageId: string,
    isLiked: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();


    formData.append('imageId', imageId);
    formData.append('isLiked', isLiked);

    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/gallery/image/likes`,
      formData
    );

    return this.httpClient.request(req);
  }

  // updateRequest(requestId: string, status: string): Observable<any> {
  //   const headers = { 'content-type': 'application/json' };
  //   return this.httpClient
  //     .put<any>(`${environment.apiUrl}/gallery/update/tag-requests?imageId=${requestId}&tagStatus=${status}` , { headers })
  //     .pipe(
  //       catchError((err) => {
  //         console.error(err);
  //         throw err;
  //       })
  //     );
  //   }

  customerlikeImage(

    imageId: string,
    isLiked: any,
  ): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('imageId', imageId);
    formData.append('isLiked', isLiked);

    const req = new HttpRequest(
      'PUT',
      `${environment.apiUrl}/gallery/image/likes`,
      formData
    );

    return this.httpClient.request(req);
  }

  updateRequest(imageId: string, taggedId: string, status: string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/gallery/update/tag-requests?imageId=${imageId}&taggedId=${taggedId}&tagStatus=${status}` , { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
    }



  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
