import { Country } from 'country-state-city';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivitiesService } from 'src/app/_services/activity.services';
import { CoreService } from 'src/app/_services/core.services';
import { EndorsementsService } from 'src/app/_services/endorsement.services';
import { GalleryService } from 'src/app/_services/gallery.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { ReviewService } from 'src/app/_services/review.services';
import { TeamService } from 'src/app/_services/team.service';
import { PromotionsService } from './../../_services/promotions.services';
import { DisplayActivityDialogComponent } from './display-activity-dialog/display-activity-dialog.component';
import { DisplayPromotionsDialogComponent } from './display-promotions-dialog/display-promotions-dialog.component';
import { SubmitReviewComponent } from './submit-review/submit-review.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { SavedService } from 'src/app/_services/saved.service';
import { ReadMoreDialogComponent } from './read-more-dialog/read-more-dialog.component';
import { FavoriteListingService } from 'src/app/_services/favoriteListing.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { ContactComponent } from './contact/contact.component';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { Subject } from 'rxjs';
import { DisplayReviewContentComponent } from './display-review-content/display-review-content.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  styleUrls: ['./personal-profile.component.css'],
})
export class PersonalProfileComponent {
  @ViewChild('callFeatureImageDialog')
  callFeatureImageDialog: TemplateRef<any>;
  @ViewChild('ReadMoreReceivedEndorsementDescriptionDialog')
  ReadMoreReceivedEndorsementDescriptionDialog: TemplateRef<any>;

  panelOpenState = false;
  activities: any;

  selectedTab: string = 'Profile';
  selectedTab1: string = 'My Posts';
  businessid: any;
  profileid: any;
  listingUserName: any;
  listings: any;
  profile: any;
  profilePromotion: any;
  teams: any;
  givenEndorsement: any[] = [];
  listingEndorsementid: string;
  endorsementGivens: any[] = [];
  getreceivedendorsements: any[] = [];
  received: any;
  gallery: any[] = [];
  taggedImg: any[] = [];
  Array = Array;
  listingidforgallery: string;
  gallerydata: any;
  reviews: any;
  coverUrl: any;
  featureImages: any;
  reviewsCount: any;
  endorsementCount: any;
  totalCustomers: any;
  newHashTags: any[] = [];
  newvalue: any;
  alreadySaved: any;
  singleReceivedEndormentData: any;
  messagedata: any;
  givenData: any = [];
  featureImgData: any;
  TaggedgalleryColumns: any;
  primaryListing: any;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  taggedData: any;
  longitude: any;
  latitude: any;
  newEndorsementHashTags: any[]=[];

  starColor: StarRatingColor = StarRatingColor.accent;

  searchQuery: any = '';
  numberLength: number = 0;
  mainEndGivenData: any = [];
  readMore_ReceivedEndorsement_Description: any;
  endorsementProfileUrl_Value: any;
  endorsementListingName_Value: any;
  endorsementListingCityName_Value: any;
  endorsementLiistingStateName_Value: any;
  customerBaseUrl: string;

  private searchSubject: Subject<string> = new Subject<string>();
  constructor(
    private router: Router,
    private teamService: TeamService,
    private activatedRoute: ActivatedRoute,
    private activitiesService: ActivitiesService,
    private listingInformationService: ListingInformationService,
    private promotionsService: PromotionsService,
    private endorsementsService: EndorsementsService,
    private galleryService: GalleryService,
    public dialog: MatDialog,
    public reviewService: ReviewService,
    private coreService: CoreService,
    private savedService: SavedService,
    private favoriteListingService: FavoriteListingService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard
  ) {}

  truncateText(text: string, maxLength: number): string {
    if (!text) return '';
    if (text.length > maxLength) {
      // Add ellipsis (...) if the text exceeds maxLength
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  ngOnInit() {
    this.customerBaseUrl = environment.customerBaseUrl;
    this.listingUserName = this.activatedRoute.snapshot.params['listingUserName'];
    // this.profileid = this.activatedRoute.snapshot.params['id'];
    // this.profileid = this.activatedRoute.snapshot.params['providerPrimaryListingId'];
    this.businessid = this.coreService.getBusinessId();
    let obj = { viewedListing: this.profileid };
    this.listingInformationService.getView(obj).subscribe((data) => {});
    this.getListingIdByUserName();
  }
  getDataByProfileID() {
    this.getPromotion();
    this.getActivities();
    this.getGalleryImages();
    this.readTeam();
    this.getReceivedEndorsByListingId();
    this.readReview();
    this.getListingDetails();
    this.getFeatureImages();
    this.getProfileInfo();
    this.displayGivenEndorsement();
    this.taggedPost();
    this.getGalleryImages();
    this.showTotalCustomers();
  }
  getListingDataById(profileid: any) {
    this.profileid = profileid;
    this.getDataByProfileID();
  }
  getTeamId(requestToPrimaryListingId: any) {
    this.profileid = requestToPrimaryListingId;
    this.getDataByProfileID();
  }

  openTeamListing(requestToPrimaryListingId: any) {
    if (requestToPrimaryListingId) {
      this.getTeamId(requestToPrimaryListingId);
    } else {
    }
  }

  getListingIdByUserName() {
    this.listingInformationService
      .getListingByUserName(this.listingUserName)
      .subscribe((response) => {
        if (response && response.data) {
          this.profileid = response.data.id;
          this.getDataByProfileID();
        }
      });
  }

  clearFilter(): void {
    this.searchQuery = '';
    //this.givenData = [];
    //this.displayGivenEndorsement();
    let searchQueryInputObj = document.getElementById(
      'searchQueryInput'
    ) as HTMLInputElement;
    searchQueryInputObj.value = '';
    this.givenData = this.mainEndGivenData;
  }

  performSearch(): void {
    let filteredData: any = [];
    if (this.searchQuery.length === 0) {
      this.displayGivenEndorsement();
    } else {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      filteredData = this.givenData.filter((item: any) =>
        item?.listingName.toLowerCase().includes(lowerCaseQuery)
      );
      if (filteredData.length > 0) this.givenData = filteredData;
    }
  }

  onSearch(event: Event): void {
    let searchQueryInputObj = document.getElementById(
      'searchQueryInput'
    ) as HTMLInputElement;
    let searchQueryInputValue = searchQueryInputObj.value;
    this.searchQuery = searchQueryInputValue;
    this.performSearch();
  }

  checkListing() {
    this.alreadySaved = [];
    this.listingInformationService
      .checkAlreadySave(this.profileid)
      .subscribe((data: any) => {
        this.alreadySaved = data?.data;
      });
  }

  getProfileInfo() {
    this.profile = [];
    this.listingInformationService
      .getListingByid(this.profileid)
      .subscribe((res) => {
        let resData = res.data;
        if(resData?.countryCode){
        resData['flag'] = `./././assets/images/flags/${resData?.countryCode}.svg`;
        }if(resData?.countryCodeNoAddressLine1){
          resData['flag'] = `./././assets/images/flags/${resData?.countryCodeNoAddressLine1}.svg`;
        }
        this.profile = resData;
        let oldArray = this.profile?.hashTags
        this.newHashTags = oldArray.slice(0,5)
        if(this.businessid !== this.profile?.providerId && !this.alreadySaved?.saved){
         this.checkListing();
        }
      });
  }
  isValidJSON(str: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  showTotalCustomers() {
    this.totalCustomers = 0;
    this.savedService
      .memberSavedMeCountByid(this.profileid)
      .subscribe((data) => {
        if (data >= 0) {
          this.totalCustomers = data;
        }
      });
  }



  saveListing() {
    let saveListingBtnObj = document.getElementById(
      'saveListingBtn'
    ) as HTMLInputElement;
    saveListingBtnObj.disabled = true;
    const saved = true;
    this.favoriteListingService
      .createFavoriteListing(this.profileid, saved)
      .subscribe((data) => {
        this.checkListing();
        this.showSnackbarTopPosition(
          'Listing saved successfully',
          'Done',
          '2000'
        );
      });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top', // Allowed values are  'top' | 'bottom'
      horizontalPosition: 'right', // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  getPromotion() {
    this.profilePromotion = [];
    this.promotionsService
      .getPromotionByListingId(this.profileid)
      .subscribe((data) => {
        this.profilePromotion = data?.items;
      });
  }

  getActivities() {
    this.activities = [];
    this.activitiesService
      .getActivitiesByListingId(this.profileid)
      .subscribe((data) => {
        this.activities = data?.items;
      });
  }

  readTeam() {
    this.teams = [];
    this.teamService.getTeamByListing(this.profileid).subscribe((data: any) => {
      if (data?.items && data?.items.length) {
        // this.teams = data?.items;
        this.teams = data?.items.filter((r: any) => r.status === 'approved');
      }
    });
  }

  getReceivedEndorsByListingId() {
    // this.getreceivedendorsements = [];
    this.endorsementsService
      .getEndorsementByListing(this.profileid)
      .subscribe((data) => {
        this.received = data?.items;
        this.received = this.received.filter((r: any) => r.visibility === true);
        this.singleReceivedEndormentData = this.received.filter(
          (r: any) => r.visibility === true
        );
        this.messagedata = this.singleReceivedEndormentData;
        this.singleReceivedEndormentData.forEach((el: any) => {
          this.getReceivedEndorsementListing(
            el.endorsementsFromListingId,
            el.endorsementDescription
          );
        });
      });
  }

  getReceivedEndorsementListing(id?: string, msg?: string) {
    //this.getreceivedendorsements = [];
    this.listingInformationService.getListing(id).subscribe((data) => {
      if (data && data?.data) {
        let objData = data?.data;
        objData['endorsementDescription'] = msg;
        this.getreceivedendorsements.push(objData);
      }
      // this.getreceivedendorsements = objData;
    });
  }

  getListingDetails() {
    this.listingInformationService.getListings().subscribe((data) => {
      this.listings = data;
    });
  }


  // onImageLike(data: any) {
  //   this.galleryService.likeImage(data.id, !data.liked).subscribe((data) => {
  //     if (data) {
  //     this.getGalleryImages();
  //     this.galleryColumns();
  //     this.taggedPost();
  //     }
  //   });
  // }

  getGalleryImages() {
    this.galleryService
      .getListingGalleryById(this.profileid)
      .subscribe((data: any) => {
        this.gallery = data?.items;
      });
  }

  getFeatureImages() {
    this.galleryService
      .getListingGalleryById(this.profileid)
      .subscribe((data: any) => {
        this.featureImages = data?.items.filter(
          (r: any) => r.featured === true
        );
      });
  }

  likeImage(gall: any) {
    this.galleryService.likeImage(gall.id, !gall.liked).subscribe((data) => {
      if (data) {
        this.getGalleryImages();
        this.galleryColumns();
        this.getFeatureImages();
        this.taggedImageLikeUpdate();
      }
    });
  }

  taggedImageLikeUpdate() {
    this.galleryService
      .getTaggedPostbyId(this.profileid)
      .subscribe((data: any) => {
        this.taggedImg = data?.items;
        this.TaggedgalleryColumns = this.taggedImg?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      });
  }

  galleryColumns() {
    const len = this.gallery?.length || 0;
    return this.gallery.reduce(
      (acc, item, index) => {
        const accIndex = index % 4;
        acc[accIndex].push(item);
        return acc;
      },
      [[], [], [], []]
    );
  }

  deletePicture() {}

  public changeTab(status: string) {
    this.selectedTab = status;
  }

  public changeTab1(status: string) {
    this.selectedTab1 = status;
  }

  seeAllActivity() {
    const dialogRef = this.dialog.open(DisplayActivityDialogComponent, {
      data: {
        listingId: this.profileid,
      },
      height: '75vh',
      width: '80vw',
      disableClose: true,
    });
  }

  seeAllPromotion() {
    const dialogRef = this.dialog.open(DisplayPromotionsDialogComponent, {
      data: {
        listingId: this.profileid,
      },
      height: '75vh',
      width: '80vw',
      disableClose: true,
    });
  }

  video() {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      data: {
        listingId: this.profileid,
      },
      height: 'auto',
      width: '930px',
      disableClose: true,
    });
  }
  readReview() {
    this.reviews = [];
    this.reviewsCount = [];
    this.reviewService.reviewsByListing(this.profileid).subscribe((data) => {
      if (data && data?.list) {
        this.reviews = data?.list;
        this.reviewsCount = data?.list?.length;
      }
    });
  }

  submitreview() {
    const dialogRef = this.dialog.open(SubmitReviewComponent, {
      data: {
        listingData: this.listings,
        listingId: this.profileid,
      },
      height: '75vh',
      width: '80vw',
      disableClose: true,
    });
  }

  readmore(boxType: string) {
    let profileData: any;

    if (boxType === 'Introduction') {
      profileData = this.profile.introduction;
    } else if (boxType === 'Experience') {
      profileData = this.profile.experience;
    } else if (boxType === 'Training') {
      profileData = this.profile.training;
    }

    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: { profileData: profileData, boxType: boxType },
      height: '70vh',
      width: '50vw',
      disableClose: true,
    });
  }
  copyLink() {
    // const browserLink = window.location.href;
    this.listingUserName = this.activatedRoute.snapshot.params['listingUserName'];
    const browserLink = `${this.customerBaseUrl}/${this.listingUserName}`;
    const copySuccess = this.clipboard.copy(browserLink);

    if (copySuccess) {
      this.showSnackbarTopPosition('Link Copied Successfully', 'OK', 1000);
    } else {
      this.showSnackbarTopPosition('Error copying link', 'OK', 3000);
    }
  }

  displayGivenEndorsement() {
    this.givenData = [];
    this.endorsementsService
      .getGivenEndorsements(this.profileid)
      .subscribe((data) => {
        let givenData1 = data?.items;
        givenData1.forEach((el: any) => {
          this.getNewGivenEndorsementListing(el.endorsementsToListingId);
        });
      });
  }

  getNewGivenEndorsementListing(id?: any) {
    this.givenData = [];
    this.listingInformationService.getListing(id).subscribe((data) => {
      let objData1 = data?.data;
      let oldArray = objData1?.hashTags
        this.newEndorsementHashTags = oldArray.slice(0,5)
      this.givenData.push(objData1);
    });
    this.mainEndGivenData = this.givenData;
  }

  openImageDetails(featureImg: any) {
    if (featureImg) {
      this.featureImgData = featureImg;
    }
    const dialogRef = this.dialog.open(this.callFeatureImageDialog, {
      height: '486px',
      width: '873px',
      disableClose: true,
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  taggedPost() {
    this.taggedImg = [];
    this.TaggedgalleryColumns = [];
    this.galleryService
      .getTaggedPostbyId(this.profileid)
      .subscribe((data: any) => {
        this.taggedImg = data?.items;
        this.TaggedgalleryColumns = this.taggedImg?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      });
  }

  contactUs(data: any) {
    const dialogRef = this.dialog.open(ContactComponent, {
      data: { profileData: data },
      height: '70vh',
      width: '50vw',
      disableClose: true,
    });
  }

  responsiveOptions: any[] = [
    {
      breakpoint: '1200px',
      numVisible: 4,
      numScroll: 4,
    },
    {
      breakpoint: '992px',
      numVisible: 3,
      numScroll: 3,
    },

    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];


  back() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }

  openReadReviewDialog(info: any) {
    const dialogRef = this.dialog.open(DisplayReviewContentComponent, {
      data: {
        reviewData: info,
      },
      width: '1000px',
      height: '75vh',
      disableClose: true,
    });
  }

  openMap() {
    let url = `${this.profile.googleMapURL}`;
    window.open(url, '_blank');
  }

  openPortfolio(url: any) {
    let urle = `https://${url}`;
    window.open(urle, '_blank');
  }

  openLink(url: any) {
    let url1 = url?.split('https://');
    if (url1[1]) {
      let url2 = url1[1]?.split('&');
      if (url2) {
        let urlNew = url2[0];
        if (urlNew) {
          let urle = `https://${urlNew}`;
          window.open(urle, '_blank');
        }
      }
    } else {
      let urle = `https://${url1[0]}`;
      window.open(urle, '_blank');
    }
  }

  openReadMoreRecvdEndorsementDescritionDialog(
    endorsementDescription: any,
    endorsementProfileUrl: any,
    endorsementListingName: any,
    endorsementListingCityName: any,
    endorsementLiistingStateName: any
  ) {
    this.readMore_ReceivedEndorsement_Description = endorsementDescription;
    this.endorsementProfileUrl_Value = endorsementProfileUrl;
    this.endorsementListingName_Value = endorsementListingName;
    this.endorsementListingCityName_Value = endorsementListingCityName;
    this.endorsementLiistingStateName_Value = endorsementLiistingStateName;
    const dialogRef = this.dialog.open(
      this.ReadMoreReceivedEndorsementDescriptionDialog,
      {
        height: '400px',
        width: '700px',
        disableClose: true,
      }
    );
  }
}
