import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FavoriteListingService {
  constructor(private httpClient: HttpClient) {}


  createFavoriteListing(savedListingId: string, saved:boolean ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/favoritelistings/create`, {savedListingId, saved}, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  checkAlreadySave(listingId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/favoritelistings/existingcheck?listingId=${listingId}`)
    .pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  checkCustomerAlreadySave(customerId: any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/favoritecustomers/existingcheck?customerId=${customerId}`)
    .pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
