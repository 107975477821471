import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SavedService } from 'src/app/_services/saved.service';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';

@Component({
  selector: 'app-send-message-dialog',
  templateUrl: './send-message-dialog.component.html',
  styleUrl: './send-message-dialog.component.css'
})
export class SendMessageDialogComponent {

  sendMessageForm: FormGroup;
  customerSavedMe: any;
  sendMessageData: any;
  savedByUserType: any;
  senderListingId: any;
  senderListingName:any;
  providerPrimaryLIstingId: any;
  providerPrimaryLIstingName: any;
  displayCustomerName: any;
  displayProviderName: any;

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
  public _formBuilder: FormBuilder,
  private savedService: SavedService,
  private snackBar: MatSnackBar,
  private listingInformationService: ListingInformationService
  ){
  }

  ngOnInit(){
    this.displayCustomerName=this.data.customerName;
    this.displayProviderName=this.data.providerName;

    this.mainForm();
    this.settingSavedMeName();
    this.getPrimaryListing();
 
  }

  mainForm() {
    this.sendMessageForm = this._formBuilder.group({
      firstName: [''],
      subjectContent: ['',Validators.required],
      messageContent: ['',Validators.required],

    });
  }

  settingSavedMeName(){
      this.savedByUserType=this.data.savedByUserType;
      if (this.savedByUserType === "Customer")
      {
        this.sendMessageForm.patchValue({
          firstName: this.data.customerName
          })
      }
      else if (this.savedByUserType === "Provider")
      {
        this.sendMessageForm.patchValue({
        firstName: this.data.providerName,
        })
      } 
  }

  onMessageFormSubmit(){
    if (this.savedByUserType === "Customer")
      {
          let obj =  {
            senderName: this.senderListingName,
            receiverName: this.data.customerName,
            subjectContent: this.sendMessageForm.value.subjectContent,
            messageContent: this.sendMessageForm.value.messageContent,
            receiverId: this.data.customerId,
            senderId: this.senderListingId
          }
          this.savedService.sendMessageTo(obj).subscribe((data)=>{
            this.showSnackbarTopPosition('Message Sent successfully', 'Done', '1000');
          })
      }
      else if (this.savedByUserType === "Provider")
        {
            let obj =  {
              senderName: this.senderListingName,
              receiverName: this.data.providerPrimaryListingName,
              subjectContent: this.sendMessageForm.value.subjectContent,
              messageContent: this.sendMessageForm.value.messageContent,
              receiverId: this.data.providerPrimaryListingId,
              senderId: this.senderListingId
            }
            this.savedService.sendMessageTo(obj).subscribe((data)=>{
              this.showSnackbarTopPosition('Message Sent successfully', 'Done', '1000');
            })
        }
  }

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }

  getPrimaryListing() {
    this.listingInformationService.getPrimaryListing().subscribe((data) => {
      if (data && data.listingName) {
        this.senderListingName = data.listingName;
        this.senderListingId = data.id;
      } else {
        console.log('Primary Listing not found.');
      }
    });
  }
  
}
