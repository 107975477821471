<div class="m-4">
  <div class="card-orview">
    <button
      class="routerLink"
      [routerLink]="['/s/pages/dashboard']"
      routerLinkActive="active"
    >
      <mat-icon>chevron_left</mat-icon>
      <h1 class="mb-0" style="font-weight: 500; margin-top: -0.8%">
        Update Profile Details
      </h1>
    </button>
  </div>

  <div class="my-4">
    <p style="font-size: 17px"><b>UPLOAD PICTURE</b></p>

    <div class="row">
      <div class="col-md-2 mt-2 mb-2">
        <div style="font-size: 12px">
          <label
            for="fileDropRef1"
            *ngIf="profileimage == '' && !profileImageURL"
            ><img
              src="../../../assets/images/imgcont.svg"
              for="fileDropRef1"
              style="width: 100px; height: 100px"
          /></label>
          <img
            [src]="profileimage"
            *ngIf="profileimage !== ''"
            style="height: 150px; width: 150px; border-radius: 100px"
          />
        </div>
        <img
          [src]="profileImageURL"
          *ngIf="profileImageURL && profileimage == ''"
          alt="Profile"
          class="rounded-circle w-60"
          style="object-fit: cover"
        />
      </div>
      <div class="col-lg">
        <h3>
          Update your photo manually. If the photo is not set, the default
          Gravatar will be the same as your login email account.
        </h3>
        <div class="button-spacing">
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="deleteImage()"
            *ngIf="profileImageURL"
          >
            Delete Photo
          </button>
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="delImg()"
            *ngIf="profileimage !== '' && !profileImageURL"
          >
            Delete Photo
          </button>

          <button
            type="button"
            class="btn btn-custom-color btn-lg w-25"
            (click)="uploadPhoto()"
            *ngIf="!profileImageURL && profileimage == ''"
          >
            Upload Photo
          </button>
          <button
            type="button"
            class="btn btn-custom-color btn-lg w-25"
            (click)="uploadPhoto()"
            *ngIf="profileImageURL"
          >
            Change Photo
          </button>
          <input
            class="file-container"
            type="file"
            #fileDropRef1
            style="display: none"
            id="fileDropRef1"
            (change)="fileBrowseHandler($event)"
            required
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h2>
        <b>PERSONAL INFORMATION</b>
      </h2>
    </div>
  </div>
  <form [formGroup]="updateForm" (ngSubmit)="onSubmit()" class="example-form">
    <div class="row">
      <div class="col-md-6">
        <p style="margin-bottom: 0px">First Name</p>
        <mat-form-field
          class="example-full-width custom-field"
          appearance="outline"
        >
          <input
            matInput
            formControlName="firstName"
            placeholder="First name"
            (input)="handleInput($event, 'firstName')"
          />
          <mat-error
            *ngIf="
              updateForm.get('firstName')?.hasError('required') &&
              (updateForm.get('firstName')?.dirty ||
                updateForm.get('firstName')?.touched)
            "
          >
            First Name is required
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('firstName')?.hasError('minlength') &&
              !updateForm.get('firstName')?.hasError('required') &&
              (updateForm.get('firstName')?.dirty ||
                updateForm.get('firstName')?.touched)
            "
          >
            First Name must be at least 2 characters long
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('firstName')?.hasError('maxlength') &&
              !updateForm.get('firstName')?.hasError('required') &&
              !updateForm.get('firstName')?.hasError('minlength') &&
              (updateForm.get('firstName')?.dirty ||
                updateForm.get('firstName')?.touched)
            "
          >
            First Name cannot be more than 50 characters long
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('firstName')?.hasError('pattern') &&
              !updateForm.get('firstName')?.hasError('required') &&
              !updateForm.get('firstName')?.hasError('minlength') &&
              !updateForm.get('firstName')?.hasError('maxlength') &&
              (updateForm.get('firstName')?.dirty ||
                updateForm.get('firstName')?.touched)
            "
          >
            Please enter a valid First Name (only letters & spaces are allowed,
            and at least one letter)
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <p style="margin-bottom: 0px">Last Name</p>
        <mat-form-field
          class="example-full-width custom-field"
          appearance="outline"
        >
          <input
            matInput
            formControlName="lastName"
            placeholder="Last name"
            (input)="handleInput($event, 'lastName')"
          />
          <mat-error
            *ngIf="
              updateForm.get('lastName')?.hasError('required') &&
              (updateForm.get('lastName')?.dirty ||
                updateForm.get('lastName')?.touched)
            "
          >
            Last Name is required
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('lastName')?.hasError('minlength') &&
              !updateForm.get('lastName')?.hasError('required') &&
              (updateForm.get('lastName')?.dirty ||
                updateForm.get('lastName')?.touched)
            "
          >
            Last Name must be at least 2 characters long
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('lastName')?.hasError('maxlength') &&
              !updateForm.get('lastName')?.hasError('required') &&
              !updateForm.get('lastName')?.hasError('minlength') &&
              (updateForm.get('lastName')?.dirty ||
                updateForm.get('lastName')?.touched)
            "
          >
            Last Name cannot be more than 50 characters long
          </mat-error>
          <mat-error
            *ngIf="
              updateForm.get('lastName')?.hasError('pattern') &&
              !updateForm.get('lastName')?.hasError('required') &&
              !updateForm.get('lastName')?.hasError('minlength') &&
              !updateForm.get('lastName')?.hasError('maxlength') &&
              (updateForm.get('lastName')?.dirty ||
                updateForm.get('lastName')?.touched)
            "
          >
            Please enter a valid Last Name (only letters & spaces are allowed,
            and at least one letter)
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p style="margin-bottom: 0px">Email</p>
        <mat-form-field
          class="example-full-width custom-field"
          appearance="outline"
          [hideRequiredMarker]="disableSelect.value"
        >
          <input
            matInput
            formControlName="emailAddress"
            placeholder="Enter your email address"
            [disabled]="true"
            readonly
            style="color: grey"
          />
          <mat-error
            *ngFor="let validation of validation_messages.emailAddress"
          >
            <mat-error
              class="error-message"
              *ngIf="
                updateForm.get('emailAddress')?.hasError(validation.type) &&
                (updateForm.get('emailAddress')?.dirty ||
                  updateForm.get('emailAddress')?.touched)
              "
            >
              {{ validation.message }}</mat-error
            >
          </mat-error>
          <mat-error
            class="error-message"
            *ngIf="updateForm.get('emailAddress')?.hasError('pattern')"
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <p style="margin-bottom: 0px">Phone</p>
        <mat-form-field
          class="example-full-width custom-field"
          appearance="outline"
          [hideRequiredMarker]="disableSelect.value"
        >
          <input
            matInput
            formControlName="phoneNumber"
            placeholder="Enter your Phone"
            (input)="formatPhoneNumber($event)"
            (blur)="updatePhoneNumberValidity()"
          />
          <mat-error *ngFor="let validation of validation_messages.phoneNumber">
            <mat-error
              class="error-message"
              *ngIf="
                updateForm.get('phoneNumber')?.hasError(validation.type) &&
                (updateForm.get('phoneNumber')?.dirty ||
                  updateForm.get('phoneNumber')?.touched)
              "
            >
              {{ validation.message }}</mat-error
            >
          </mat-error>
          <mat-error
            class="error-message"
            *ngIf="updateForm.get('phoneNumber')?.hasError('pattern')"
          >
            Please enter a valid phone number
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p style="margin-bottom: 0px">Address 1st Line</p>
        <mat-form-field
          appearance="outline"
          class="example-full-width custom-field"
        >
          <!-- <input
            matInput
            [options]="options"
            ngx-gp-autocomplete
            #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)"
            formControlName="addressline1"
            name="addressline1"
            required
          /> -->
          <input
          matInput
          [options]="options"
          ngx-gp-autocomplete
          #placesRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          formControlName="addressline1"
          name="addressline1"
        />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <p style="margin-bottom: 0px">Country</p>
        <mat-form-field
          appearance="outline"
          class="example-full-width custom-field"
        >
          <input
            matInput
            formControlName="country"
            name="addressline1"
            required
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="margin-bottom: 40px">
      <div class="col-md-4">
        <p style="margin-bottom: 0px" for="state">State</p>
        <mat-form-field
          appearance="outline"
          class="example-full-width custom-field"
        >
          <input
            matInput
            formControlName="state"
            name="addressline1"
            required
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <p style="margin-bottom: 0px" for="city">City</p>
        <mat-form-field
          appearance="outline"
          class="example-full-width custom-field"
        >
          <input matInput formControlName="city" name="addressline1" required />
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <p style="margin-bottom: 0px">Zip Code</p>
        <mat-form-field
          appearance="outline"
          class="example-full-width custom-field"
        >
          <input
            matInput
            placeholder="Enter Zipcode"
            formControlName="zipcode"
            maxlength="7"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div style="margin-bottom: 40px" class="col-md-12 example-button-spacing">
        <button type="button" class="btn btn-outline-dark" (click)="onCancel()">
          Cancel
        </button>
        <button class="btn btn-custom-color" type="submit">
          Update Profile
        </button>
      </div>
    </div>
  </form>

  <div>
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="onChangePasswordSubmit()"
    >
      <div class="row">
        <div class="col-md-12">
          <h2>
            <b>CHANGE PASSWORD</b>
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <p style="margin-bottom: 0px">User Name</p>
          <mat-form-field
            class="example-full-width custom-field"
            appearance="outline"
            [hideRequiredMarker]="disableSelect.value"
          >
            <input
              matInput
              formControlName="userName"
              placeholder="User name"
              [disabled]="true"
              readonly
              style="color: grey"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p style="margin-bottom: 0px">Old Password</p>
          <mat-form-field
            class="example-full-width custom-field"
            appearance="outline"
            [hideRequiredMarker]="disableSelect.value"
          >
            <input
              matInput
              placeholder="Enter old password"
              formControlName="currentPassword"
              [type]="hideCurrentPassword ? 'password' : 'text'"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="toggleVisibility('currentPassword')"
              type="button"
            >
              <mat-icon>{{
                hideCurrentPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
            <mat-error
              *ngIf="
                changePasswordForm.get('currentPassword')?.hasError('required')
              "
            >
              Old Password is required to set a new password
            </mat-error>
            <mat-error
              *ngIf="
                changePasswordForm
                  .get('currentPassword')
                  ?.hasError('incorrectOldPassword')
              "
            >
              Old password is incorrect
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin-bottom: 40px">
        <div class="col-md-6">
          <p style="margin-bottom: 0px">New Password</p>
          <mat-form-field
            class="example-full-width custom-field"
            appearance="outline"
            [hideRequiredMarker]="disableSelect.value"
          >
            <input
              matInput
              placeholder="Enter new password"
              formControlName="newPassword"
              [type]="hideNewPassword ? 'password' : 'text'"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="toggleVisibility('newPassword')"
              type="button"
            >
              <mat-icon>{{
                hideNewPassword ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
            <mat-error
              *ngFor="let validation of validation_messages.newPassword"
            >
              <mat-error
                class="error-message"
                *ngIf="
                  changePasswordForm
                    .get('newPassword')
                    ?.hasError(validation.type) &&
                  (changePasswordForm.get('newPassword')?.dirty ||
                    changePasswordForm.get('newPassword')?.touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
            <mat-error
              class="error-message"
              *ngIf="changePasswordForm.get('newPassword')?.hasError('pattern')"
            >
              Please enter Minimum of 8 characters (At least one lowercase
              letter, At least one uppercase letter At least one digit. At least
              one special character)
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <p style="margin-bottom: 0px">Confirm New Password</p>
          <mat-form-field
            class="example-full-width custom-field"
            appearance="outline"
            [hideRequiredMarker]="disableSelect.value"
          >
            <button
              mat-icon-button
              matSuffix
              (click)="toggleVisibility('confirmation')"
              type="button"
            >
              <mat-icon>{{
                hideConfirmation ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
            <input
              matInput
              placeholder="Enter new password again"
              formControlName="confirmation"
              [type]="hideConfirmation ? 'password' : 'text'"
            />
          </mat-form-field>
          <div class="error-container">
            <mat-error
              *ngFor="let validation of validation_messages.confirmation"
            >
              <mat-error
                class="error-message"
                *ngIf="
                  changePasswordForm
                    .get('confirmation')
                    ?.hasError(validation.type) &&
                  (changePasswordForm.get('confirmation')?.dirty ||
                    changePasswordForm.get('confirmation')?.touched)
                "
              >
                {{ validation.message }}</mat-error
              >
            </mat-error>
            <div class="errormsg">
              <mat-error
                class="error-message"
                *ngIf="
                  changePasswordForm.get('confirmation')?.value?.length > 0 &&
                  changePasswordForm.get('confirmation')?.value !==
                    changePasswordForm.get('newPassword')?.value
                "
              >
                Password & Confirm Password are not the same
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div style="margin-bottom: 40px" class="col-md-6 example-button">
          <button
            class="btn btn-danger"
            type="submit"
            (click)="onDeleteAccount()"
          >
            Delete Account
          </button>
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="onPauseAccount()"
          >
            Pause Account
          </button>
        </div>
        <div
          style="margin-bottom: 40px"
          class="col-md-6 example-button-spacing"
        >
          <button
            type="button"
            class="btn btn-outline-dark"
            (click)="onCancel()"
          >
            Cancel
          </button>
          <button class="btn btn-custom-color" type="submit">
            Change Password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #callMessageDialog>
  <form #userForm="ngForm">
    <h2 matDialogTitle style="text-align: center">Message</h2>
    <mat-dialog-content>
      <p style="font-weight: '400'; text-align: center; padding: 15px">
        You haven't added any listing.<br />
        Do you want to Add New Listing ?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="no"
        style="border-radius: 10px; border: 2px; width: 180px"
      >
        NO
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="yes"
        style="
          background-color: #ec5858;
          border-radius: 10px;
          color: #fff;
          width: 180px;
        "
      >
        YES
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
<ng-template #callDeleteProfileImageDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete profile image?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
