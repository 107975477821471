import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IListingInformations } from 'src/app/_models/listingInformation.model';
import { PromotionsService } from 'src/app/_services/promotions.services';
import { CoreService } from './../../../_services/core.services';

@Component({
  selector: 'app-display-promotions-dialog',
  templateUrl: './display-promotions-dialog.component.html',
  styleUrl: './display-promotions-dialog.component.css'
})
export class DisplayPromotionsDialogComponent {
  @ViewChild('callPromotionImageDialog')
  callPromotionImageDialog: TemplateRef<any>;

  businessid: string;
  listingid: string;
  listings: IListingInformations[] = [];
  promotions: any = [];
  profile: any;
  profileid: any;
  promotionImageData: any;
  profilePromotion: any[] = [];
  Array = Array;

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
    private promotionsService: PromotionsService,
    private coreService: CoreService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.businessid = this.coreService.getBusinessId();
    this.PromotionByListingId();
  }

  PromotionByListingId() {
    this.promotionsService.getPromotionByListingId(this.data.listingId).subscribe((data) => {
      this.profilePromotion = data?.items;
    })
  }


  get galleryColumns() {
    // const len = this.profilePromotion?.length || 0;
    return this.profilePromotion.reduce((acc, item, index) => {
      const accIndex = index % 4;
      acc[accIndex].push(item);
      return acc;
    }, [[], [], [], []])
  }


  openImageDetails(promotion: any){
    if(promotion){
      this.promotionImageData = promotion;
    }
      const dialogRef = this.dialog.open(this.callPromotionImageDialog, {
        height: '486px',
        width: '873px',
        disableClose: true
      });
  }

}
