import { ReviewService } from './../../_services/review.services';
import { GalleryService } from './../../_services/gallery.services';
import { SavedService } from 'src/app/_services/saved.service';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersInfoService } from 'src/app/_services/customers.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';
import { FavoriteListingService } from 'src/app/_services/favoriteListing.services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrl: './customer-profile.component.css',
})
export class CustomerProfileComponent {
  @ViewChild('callReviewProfileDialog')
  callReviewProfileDialog: TemplateRef<any>;
  selectedTab: string = 'Profile';
  customerid: string;
  customer: any;
  listingInfo: any;
  customerData: any;
  viewlistingid: string;
  customerId: string;
  customerReviews: any;
  galleryColumns: any;
  customerGalleryImage: any[] = [];
  alreadySaved: any;
  taggedImg: any[] = [];
  selectedTab1: string = 'My Posts';
  TaggedgalleryColumns: any;
  datafromIdBased: any;
  customerProfileData: any;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customersInfoService: CustomersInfoService,
    private savedService: SavedService,
    private snackBar: MatSnackBar,
    private galleryService: GalleryService,
    private reviewService: ReviewService,
    private favoriteListingService: FavoriteListingService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.customerId = this.activatedRoute.snapshot.params['id'];
    this.viewlistingid = this.activatedRoute.snapshot.params['viewlistid'];
    this.getProfileDetails();
    this.getProfileViewDetails();
    this.getCustomerGalleryImage();
    this.getCustomerReviews();
    this.checkSaved();
    this.taggedPost();
  }

  deletePicture() {}

  public changeTab(status: string) {
    this.selectedTab = status;
  }

  public changeTab1(status: string) {
    this.selectedTab1 = status;
  }

  getProfileDetails() {
    this.customersInfoService
      .getCustomerByCustomerID(this.customerId)
      .subscribe((data) => {
        if (data && data?.data) {
          let resData = data?.data;
          resData[
            'flag'
          ] = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${resData.countryCode}.svg`;
          this.customerProfileData = resData;
        }
      });
  }

  checkSaved() {
    this.favoriteListingService
      .checkCustomerAlreadySave(this.customerId)
      .subscribe((data) => {
        if (data && data?.data) {
          this.alreadySaved = data?.data;
        }
      });
  }

  getProfileViewDetails() {
    this.customersInfoService.getCustomer(this.customerId).subscribe((data) => {
      this.customerData = data;
    });
  }

  customerlikeImage(gall: any) {
    this.galleryService
      .customerlikeImage(gall.id, !gall.liked)
      .subscribe((data) => {
        this.getCustomerGalleryImage();
      });
  }

  saveCustomer(cid: any) {
    const saved = true;
    this.savedService.createFavoriteCustomer(cid, saved).subscribe((data) => {
      this.showSnackbarTopPosition(
        'Customer saved successfully',
        'Done',
        '2000'
      );
      this.checkSaved();
    });
  }

  getCustomerReviews() {
    this.reviewService.customerReviews(this.customerId).subscribe((data) => {
      let sortedDataList = data?.list;
      sortedDataList[
        'flag'
      ] = `http://purecatamphetamine.github.io/country-flag-icons/3x2/${sortedDataList.countryCode}.svg`;
      this.customerReviews = sortedDataList;
    });
  }

  getCustomerGalleryImage() {
    this.galleryService
      .customerGalleryImg(this.customerId)
      .subscribe((data: any) => {
        this.customerGalleryImage = data?.items;
        const len = this.customerGalleryImage?.length || 0;
        this.galleryColumns = this.customerGalleryImage?.reduce(
          (acc, item, index) => {
            const accIndex = index % 4;
            acc[accIndex].push(item);
            return acc;
          },
          [[], [], [], []]
        );
      });
  }

  taggedPost() {
    this.taggedImg = [];
    this.TaggedgalleryColumns = [];
    this.galleryService.getTaggedPost().subscribe((data: any) => {
      this.taggedImg = data?.list;
      this.TaggedgalleryColumns = this.taggedImg?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
    });
  }

  onImageLike(data: any) {
    this.galleryService.likeImage(data.id, !data.liked).subscribe((data) => {
      this.getCustomerGalleryImage();
    });
  }

  reviewsPage(item: any) {
    if (item) {
      this.datafromIdBased = item;
    }

    const dialogRef = this.dialog.open(this.callReviewProfileDialog, {
      height: '611px',
      width: '850px',
      disableClose: true,
    });
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }
  back() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }
}
