export const environment = {
  production: false,
  wsUrl: "https://api.dev.qup.global/api/web-socket",
  paymentUrl: "https://api.dev.qup.global/api/payment-services",
  apiUrl: "https://api.dev.qup.global/api/qup-services",
  customerBaseUrl: 'https://dev.qup.global',
  businessBaseUrl: 'https://business.dev.qup.global'
  // httpInterceptor: {
  //   allowedList: [`/api/*`],
  // },
};
