import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { SavedService } from 'src/app/_services/saved.service';
import { SendMessageDialogComponent } from './send-message-dialog/send-message-dialog.component';
import { SavedbymeMessageDialogComponent } from './savedbyme-message-dialog/savedbyme-message-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-saved',
  templateUrl: './saved.component.html',
  styleUrls: ['./saved.component.css'],
})
export class SavedComponent implements OnInit {

  @ViewChild('callDeleteSavedCustomerDialog')
  callDeleteSavedCustomerDialog: TemplateRef<any>;

  @ViewChild('callDeleteSavedListingDialog')
  callDeleteSavedListingDialog: TemplateRef<any>;

  businessId: string;
  favcustomer: any[] = [];
  favcustomerId: string;
  selectedTab: string = 'Saved me';
  customer: any;
  customerSavedMe: any[] = [];
  customerId: string;
  listings: any;
  listingid: string;
  listingTitle: string;
  isloader: any = true;
  deleteSavedMemberName: any;
  deleteSavedMeUserName: any;
  selectedCustomerLabel: string = 'Customer';
  userType: any;
  customerSavedta: any;
  loggedInProviderId: any;
  searchQuery: any = '';
  numberLength: number = 0;

  private searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private savedService: SavedService,
    public dialog: MatDialog,
    private listingInformationService: ListingInformationService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.savedMe(this.selectedCustomerLabel);
    this.savedCustomer(this.selectedCustomerLabel);
    this.getListingDetails();
    this.searchSubject.subscribe(query => {
      if (this.selectedTab === 'Saved me') {
        this.savedMe(this.selectedCustomerLabel);
      } else {
        this.savedCustomer(this.selectedCustomerLabel);
      }
    });
    this.activatedRoute.params.subscribe(params => {
      this.searchQuery = params['value'] || '';
    });
  }

  savedCustomer(selectedCustomerLabel: any) {
    this.favcustomer = [];
    let customerLocation: any;
    let providerLocation: any;
    this.savedService.getFavoriteCustomerstab(selectedCustomerLabel).subscribe((data) => {
      if (data?.list && data?.list.length > 0) {
        let sortedDataList = data?.list.map((element: any) => {
          if (element.savedMemberType == 'Provider') {
            let location = element.savedMemberLocation;
            const index = location.indexOf('{');
            const local = location.split('{')[0]

            if (local.trim() === "") {
              providerLocation=`${this.getSortedData(location, index)}`;  
            }
            else {
              providerLocation=`${local}, ${this.getSortedData(location, index)}`;
            }
            providerLocation=providerLocation.trim();
            element['savedMemberLocation'] = providerLocation.replace(/,$/, '');
          } else if (element.savedMemberType == 'Customer') {
            let location = element.savedMemberLocation
            const index = location.indexOf('{');
            const local = location.split('{')[0]
            if (local.trim() === "") {
              customerLocation=`${this.getSortedData(location, index)}`;  
            }
            else {
              customerLocation=`${local}, ${this.getSortedData(location, index)}`;
            }
            customerLocation=customerLocation.trim();
            element['savedMemberLocation'] = customerLocation.replace(/,$/, '');
          }
          else {
            if (element.cityName && this.isValidJSON(element.cityName)) {
              element.cityName = JSON.parse(element.cityName).name
            }
            if (element.state && this.isValidJSON(element.state)) {
              element.state = JSON.parse(element.state).name
            }
            if (element.country && this.isValidJSON(element.country)) {
              element.country = JSON.parse(element.country).name
            }
          }
          return element;
        });
        this.favcustomer = sortedDataList;
      }
    });
  }
  
  getSortedData(element: any, index: any) {
    const newStr = element.substring(index);
    const jsonObjects = newStr.split('}').map((item: any) => {
      try {
        return JSON.parse(item + '}');
      } catch (e) {
        return null;
      }
    });
    const names = jsonObjects.map((obj: any) => obj ? obj.name : null);
    return names;
  }

  savedMe(selectedCustomerLabel: any) {
    this.customerSavedMe = [];
    let customerLocation: any;
    let providerLocation: any;
    this.savedService.customerSavedMeNew(selectedCustomerLabel).subscribe((data) => {
      if (data?.list && data?.list.length > 0)
      {
            let sortedDataList = data?.list.map((element:any) => {
            if(element.savedByUserType == 'Provider'){
              let location = element.providerLocation.replace(/(\r)/gm, "");
              const index = location.indexOf('{');
              if(index <= 0){
                const local = location.split('[')[0];
                element['providerLocation'] =`${local}`;
              }else{
                const local = location.split('{')[0]
                if (local.trim() === "") {
                    providerLocation=`${this.getSortedData(location, index)}`;  
                  }
               else {
                    providerLocation=`${local}, ${this.getSortedData(location, index)}`;
                  }
                  providerLocation=providerLocation.trim();
                  element['providerLocation'] = providerLocation.replace(/,$/, '');
              }
            }else
            if(element.savedByUserType == 'Customer'){
              let location = element.customerLocation.replace(/(\r)/gm, "");
              const index = location.indexOf('{');
              const local = location.split('{')[0]
              if (local.trim() === "") {
                  customerLocation=`${this.getSortedData(location, index)}`;  
                }
              else {
                  customerLocation=`${local}, ${this.getSortedData(location, index)}`;
                }
              customerLocation=customerLocation.trim();
              element['customerLocation'] = customerLocation.replace(/,$/, '');
            }
            else {
              if (element.cityName && this.isValidJSON(element.cityName)) {
                element.cityName = JSON.parse(element.cityName).name
              }
              if (element.state && this.isValidJSON(element.state)) {
                element.state = JSON.parse(element.state).name
              }
              if (element.country && this.isValidJSON(element.country)) {
                element.country = JSON.parse(element.country).name
              }
            }
          return element;
        });
        this.customerSavedMe = sortedDataList;
      }
    });
  }
  isValidJSON(str: any) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  changeCustomerDataStatus(m: any) {
    this.selectedCustomerLabel = m.tab.textLabel;
    if (this.selectedCustomerLabel == 'Customer') {
      this.savedMe(this.selectedCustomerLabel);
    } else {
      this.savedMe(this.selectedCustomerLabel);

    }
    this.searchQuery = '';
    this.performSearch();
  }

  changeProviderDataStatus(m: any) {
    this.selectedCustomerLabel = m.tab.textLabel;
    if (this.selectedCustomerLabel == 'Customer') {
      this.savedCustomer(this.selectedCustomerLabel)
    } else {
      this.savedCustomer(this.selectedCustomerLabel)
    }
    this.searchQuery = '';
    this.performSearch();
  }

  deleteSavedListing(id: string, savedByUserTypeParam: any, typeUserNameParam: any) {
    if (savedByUserTypeParam === 'Customer') {
      this.deleteSavedMeUserName = typeUserNameParam;
      this.userType = "customer";
    }
    else if (savedByUserTypeParam === 'Provider') {
      this.deleteSavedMeUserName = typeUserNameParam;
      this.userType = "provider"
    }
    let dialogRef = this.dialog.open(this.callDeleteSavedListingDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.savedService.deleteFavoriteListing(id).subscribe((data) => {
          this.showSnackbarTopPosition('Provider deleted successfully', 'Close', '2000');
          this.savedMe(this.selectedCustomerLabel);
        })
      } else {
        if (result === 'cancel') {
          this.savedCustomer(this.selectedCustomerLabel);
        }
      }
    })
  }

  deleteSavedCustomer(id: string,savedMemberName:string, savedMemberType: string) {
    this.deleteSavedMemberName=savedMemberName;
    let dialogRef = this.dialog.open(this.callDeleteSavedCustomerDialog, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        if (savedMemberType === "Customer")
        {
            this.savedService.deleteFavoriteCustomer(id).subscribe((data) => {
              this.showSnackbarTopPosition('Customer deleted successfully', 'Close', '2000');
            this.savedCustomer(this.selectedCustomerLabel);
            })
        }
        else if (savedMemberType === "Provider")
          {
            this.savedService.deleteFavoriteListing(id).subscribe((data) => {
              this.showSnackbarTopPosition('Listing deleted successfully', 'Close', '2000');
              this.savedCustomer(this.selectedCustomerLabel);
              })
          }
      } else {
        if (result === 'cancel') {
          this.savedCustomer(this.selectedCustomerLabel);
        }
      }
    })
  }
  // deleteSavedListings(){
  //   let dialogRef = this.dialog.open(this.callDeleteFavCustomerDialog);
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result === 'delete') {
  //       this.savedService.deleteFavoriteListings().subscribe((data) => {
  //         this.savedCustomer();
  //       })
  //     } else {
  //       if (result === 'cancel') {
  //         this.savedCustomer();
  //       }
  //     }
  //   })
  // }


  changeTab(status: string) {
    this.selectedTab = status;
    if (this.selectedTab === 'Saved by me') {
      this.savedCustomer(this.selectedCustomerLabel = 'Customer');
    } else {
      this.savedMe(this.selectedCustomerLabel = 'Customer');
    }
    this.searchQuery = '';
    this.performSearch();
  }

  savedMeSendMessageToCustomer(customerIdParam: any, customerNameParam: any, savedByUserTypeParam: any) {
    const dialogRef = this.dialog.open(SendMessageDialogComponent, {
      data: {
        customerId: customerIdParam,
        customerName: customerNameParam,
        savedByUserType: savedByUserTypeParam
      },
      height: '75vh',
      width: '60vw',
      disableClose: true
    });
  }
  savedMeSendMessageToProvider(providerNameParam: any, providerPrimaryListingIdParam: any, providerPrimaryListingNameParam: any, savedByUserTypeParam: any) {
    const dialogRef = this.dialog.open(SendMessageDialogComponent, {
      data: {
        providerName: providerNameParam,
        providerPrimaryListingId: providerPrimaryListingIdParam,
        providerPrimaryListingName: providerPrimaryListingNameParam,
        savedByUserType: savedByUserTypeParam
      },
      height: '75vh',
      width: '60vw',
      disableClose: true
    });
  }
  savedByMeMessage(id: any, usertype: any) {
    const dialogRef = this.dialog.open(SavedbymeMessageDialogComponent, {
      data: {
        savedByMeid: id,
        savedMemberType: usertype
      },
      height: '75vh',
      width: '60vw',
      disableClose: true
    });
  }

  getListingDetails() {
    this.listingInformationService
      .getListings()
      .subscribe((data) => {
        this.listings = data;
      });
  }

  onListChange(event: any) {
    this.listingid = event.value;
    this.sortSavedByMeByListing();
  }

  sortSavedByMeByListing() {
    this.savedService.getSavedByMeListing(this.listingid).subscribe((data) => {
      this.favcustomer = data?.items;
    })
  }

  savedmeChange(event1: any) {
    this.listingid = event1.value;
    this.sortSavedMeByListing();
  }

  sortSavedMeByListing() {
    this.savedService.getSavedMeListing(this.listingid).subscribe((data) => {
      this.customerSavedMe = data?.list;
    })
  }

  allSavedByMe() {
    this.savedCustomer(this.selectedCustomerLabel);
  }

  allSaved() {
    this.savedMe(this.selectedCustomerLabel);
  }

  performSearch() {
    if (this.selectedTab === 'Saved me') {
      if (this.searchQuery.length === 0) {
        this.savedMe(this.selectedCustomerLabel);
      } else {
        if (this.selectedCustomerLabel === 'Customer') {
          this.customerSavedMe = this.customerSavedMe.filter((item: any) =>
            item.customerName && item.customerName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        } else {
          this.customerSavedMe = this.customerSavedMe.filter((item: any) =>
            item.providerName && item.providerName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
      }
    } else {
      if (this.searchQuery.length === 0) {
        this.savedCustomer(this.selectedCustomerLabel);
      } else {
        if (this.selectedCustomerLabel === 'Customer') {
          this.favcustomer = this.favcustomer.filter((item: any) =>
            item.savedMemberName && item.savedMemberName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        } else {
          this.favcustomer = this.favcustomer.filter((item: any) =>
            item.savedMemberName && item.savedMemberName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
      }
    }
  }

  search(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const query = inputElement.value;

    this.searchQuery = query;
    this.performSearch();
  }


  clearSearch() {
    this.searchQuery = '';
    this.performSearch();
  }
  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }


}
