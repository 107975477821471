<div class="container-fluid" style="padding-top: 10px; background-color: #fff;">
  <mat-dialog-content>
    <div class="row">
      <div class="col col-9">
        <h1>All Promotions</h1>
      </div>
      <div class="col col-3">
        <button mat-mini-fab  mat-dialog-close style="float: right; background-color: #fff; color: #333333;">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
<br>
    <div class="row" >
      <ng-container *ngFor="let column of galleryColumns">
      <div class="col-md-4">
        <section *ngFor="let gall of column">
          <div class="col-md-12" *ngIf="gall?.imagePromotionURL">
            <div class="container1"  (click)="openImageDetails(gall)" style="background-color: #F1F0F5; border-radius: 10px; margin-right: 50px;">
              <img src="{{ gall?.imagePromotionURL }}" style="object-fit: contain;" class="image"
             />
              <div class="overlay">
                <div class="text">  <p>
                  <span class="title">
                    {{ gall?.promotionTitle }}</span><br />
                    <span class="description">
                      <p [innerHTML]="gall?.details | lineBreak"></p>
                    </span>
                  <span class="description">
                    {{ gall?.startDateTime | date : "dd-MMM-yyyy" }}
                    to
                    {{
                    gall.endDateTime | date : "dd-MMM-yyyy"
                    }}</span>
                </p>
             </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  </ng-container>
    </div>
  </mat-dialog-content>
</div>


<ng-template #callPromotionImageDialog>
  <body>
  <div>
    <form #userForm="ngForm" style="overflow-x: auto; overflow: hidden;">
      <div class="row">
        <div class="col-md-6" *ngIf="promotionImageData.imagePromotionURL">
            <img [src]="promotionImageData.imagePromotionURL"
             class="activity-img-style" style="object-fit: contain; background-color: #F1F0F5;"/>
        </div>
        <div class="col-md-6" *ngIf="promotionImageData.imagePromotionURL">
          <div class="row">
            <div class="col-8">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                <p style=" margin-top: 15px;"><b class="activity-head">Promotion</b></p>
              </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-end align-items-center">
              <span class="material-icons-outlined" style="cursor: pointer;" mat-dialog-close>cancel</span>
            </div>
          </div>
          <hr style="width: 90%; margin: 0 auto;">
          <h2 class="p-3">{{promotionImageData.promotionTitle}}</h2>
          <p style="color: grey; font-size: 14px; margin-left: 18px; margin-top: -30px;">{{ promotionImageData?.startDateTime | date : "dd-MMM-yyyy" }} to {{ promotionImageData.endDateTime | date : "dd-MMM-yyyy" }}</p>
          <p class="p-3 desc-height" [innerHTML]="promotionImageData.details | lineBreak"></p>
          <hr style="width: 90%; margin: 0 auto;">
          </div>
      </div>
    </form>
  </div>
</body>
</ng-template>

