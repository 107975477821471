<nav class="navbar navbar-dark bg-dark navbar-style">
  <!-- <nav class="navbar navbar-light" style="z-index:1000; background-color: #fff; box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;"> -->
  <a class="navbar-brand" href="https://qupstyle.co/">
    <img src="./././assets/QUP-signup.png" style="width: 80px; margin-top: -8px;" alt="">
  </a>
  <ul class="nav justify-content-end">
    <li class="nav-item">
      <p class="nav-link linkStyle"
        style="z-index: 1500; opacity: 1; cursor: pointer; margin-top: 4px; font-weight: 600;"><a
          href="https://qupstyle.co/" style="text-decoration: none; color: #fff;">Home</a></p>
    </li>
    <li class="nav-item">
      <p class="nav-link linkStyle"
        style="z-index: 1500; opacity: 1; cursor: pointer; margin-top: 4px; font-weight: 600;"><a
          href="https://qupstyle.co/about/" style="text-decoration: none; color: #fff;">About</a></p>
    </li>
    <li class="nav-item">
      <button mat-button [matMenuTriggerFor]="menu" class="linkStyle"
        style="margin-top: 7px; z-index: 1500; opacity: 1; cursor: pointer; font-weight: 600;"
        *ngIf="UserLoggedIn == 'No'">Log In <span
          style="float: right;"><mat-icon>arrow_drop_down</mat-icon></span></button>
      <mat-menu #menu="matMenu" style="margin-top: 60px;">
        <button mat-menu-item><a href="{{customerBaseUrl}}/s/pages"
            style="text-decoration: none; color: black;">Customer Log In</a></button>
        <button mat-menu-item><a href="{{businessBaseUrl}}" style="text-decoration: none; color: black;">Provider Log
            In</a></button>
      </mat-menu>
    </li>
    <li class="nav-item">
      <button *ngIf="UserLoggedIn == 'No'" class="btn" type="button" (click)="signUp()"
        style=" color: #fff; z-index: 2500; opacity: 1; cursor: pointer; width: 150px; height:50px; font-weight: 600;">Sign
        Up</button>
      <!-- <button  *ngIf="UserLoggedIn == 'Yes'" class="btn" type="button" (click)="dashboard()" style="  color: #fff; z-index: 2500; opacity: 1; cursor: pointer; width: 150px; height:50px">Dashboard</button> -->


      <button mat-flat-button [matMenuTriggerFor]="menu1" class="button-profile"
        (click)="getUpdatedUserFirstNameLastName()" *ngIf="UserLoggedIn == 'Yes'" style="background: transparent;">
        <img id="navBarProfileImage" src="{{profileData?.profileImageURL}}"
          style="height: 40px; width: 40px; border-radius: 50px; margin-right:10px; object-fit: cover;"
          *ngIf="profileData?.profileImageURL">
        <img id="navBarProfileImage" src="../../../assets/images/grey.jpg"
          style="height: 40px; width: 40px; border-radius: 50px; margin-right:10px; object-fit: cover;"
          *ngIf="!profileData?.profileImageURL">
        <!-- <mat-icon> arrow_drop_down </mat-icon> -->
      </button>
      <mat-menu #menu1="matMenu" xPosition="before">
        <mat-card style="background-color: #333333; color: #fff; border-radius: 20px 20px 0px 0px; margin-top: -8px;">
          <span style="padding: 10px; text-align: center;">{{ firstNameLastName }}</span>
        </mat-card>
        <button mat-menu-item (click)="goTODashboard()">
          <mat-icon> dashboard </mat-icon>Dashboard
        </button>
        <button mat-menu-item (click)="myActivity()">
          <mat-icon> layers </mat-icon>My Activity
        </button>
        <button mat-menu-item (click)="recentFeed()">
          <mat-icon> layers </mat-icon>Recent Feed
        </button>
        <button mat-menu-item (click)="goToupdateprofile()">
          <mat-icon> edit </mat-icon>Update My Info
        </button>
        <button mat-menu-item (click)="goTOSettings()">
          <mat-icon> settings </mat-icon>Settings
        </button>
        <!-- <button mat-menu-item (click)="blockedUser()">
                  <mat-icon> blocked </mat-icon>Blocked Users
                </button> -->
        <button mat-menu-item (click)="logout()">
          <mat-icon> logout </mat-icon>Logout
        </button>
      </mat-menu>
      <!-- <p  class="nav-link linkStyle">Sign Up</p> -->
    </li>
  </ul>
</nav>
<div class="container-fulid">
  <video muted autoplay
  class="video-container"
  onloadedmetadata="this.muted = true" oncanplay="this.play()">
    <source [src]="displayURL" type="video/mp4">
  </video>
  <div class="centered">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="searchForm" (ngSubmit)="searchResult()">
          <mat-form-field class="example-form-field custom-field " appearance="outline"
            class="field-size">
            <input matInput type="text" formControlName="searchText" placeholder="I am searching for ..."
              style="font-weight: 800;">
            <button matSuffix mat-icon-button type="submit" aria-label="Clear">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <!-- <h1 style="color: #fff; text-align: center;">
      <b>Beauty, Fashion & Wellness</b>
      Find your perfect provider
    </h1> -->
        <!-- </mat-card> -->

      </div>
    </div>
  </div>
</div>

<ng-template #callLogoutDialog>
  <form #userForm="ngForm" style="width:400px; height: 200px; border-radius:10px">
    <p matDialogTitle style="text-align: left; font-size: 15px; margin-top:-10px"><b>Logout Confirmation!</b></p>
    <mat-divider style="margin-top:-20px"></mat-divider>
    <p style="text-align: center; padding: 20px;font: 400 20px;">
      Are you sure you want to logout ?
    </p>

    <mat-dialog-actions align="center">
      <button mat-raised-button type="button" matDialogClose="no"
        style="border-radius: 10px; border: 2px; width: 100px;">No</button>
      <button class="btn-primary" type="button" mat-raised-button matDialogClose="yes"
        style="background-color: #525EB1 ; border-radius: 10px; color: #fff; width: 100px;">
        Yes
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
