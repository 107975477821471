import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";



export const matchpassword : ValidatorFn =  (control: AbstractControl): ValidationErrors|null => {
    let newPassword = control.get('newPassword');
    let confirmation = control.get('confirmation');

    if(newPassword && confirmation && newPassword?.value != confirmation?.value) {
        return {
            passwordmatcherror : true
        }
        
    }
    return null;
    
}


