<div class="container" style="padding-top: 10px;
background-color: black;
width: 100%;

overflow: hidden
">

    <!-- <video style="width: 100%; height:100%; padding-top: 10px; background-color: black;" controls>
      <source src="{{profile?.videoURL}}" video type="video/mp4">
    </video> -->

    <div id="visor-video" *ngIf="!videoId">
      <span class="material-icons-outlined" style="color: white; position: absolute;
      right: 10px; cursor: pointer; float:right; margin-right:-2px; z-index: 99999;" (click)="closeDialog()">
        highlight_off
    </span>
    <div #videoPlayer>
      <iframe [width]="videoWidth" [height]="videoHeight" style="margin-top: 5px;"
        [src]="displayURL" contorls>
      </iframe>
    </div>
    </div>

    <span class="material-icons-outlined" *ngIf="videoId" style="color: white; position: absolute;
    right: 10px; cursor: pointer; float:right; margin-right:-2px; z-index: 99999;" (click)="closeDialog()">
      highlight_off
  </span>
  <div #youTubePlayer>
    <youtube-player videoId="{{videoId}}" [height]="videoHeight" [width]="videoWidth" *ngIf="videoId">
    </youtube-player></div>

    <!-- <iframe width="900" height="480" src="https://www.youtube.com/embed/Oa9aWdcCC4o" title="The World in 2050: Future Technology" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
</div>
