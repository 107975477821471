import { animate, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GalleryService } from 'src/app/_services/gallery.services';

interface Item {
  galleryImageUrl: string;
  imageUrl: string;
  galleryDescription: string;
  imageDescription: string;
  imageAlt: string;
  imageLikes: any;
  likesCount: any;
  mediaType: any;
}

@Component({
  selector: 'app-tagged-image-lightbox',
  templateUrl: './tagged-image-lightbox.component.html',
  styleUrl: './tagged-image-lightbox.component.css',
  animations:[
    trigger('animation', [
      transition('void => visible', [
        style({tranform: 'scale(0.5)'}),
        animate('150ms', style({transform:'scale(1)'}))
      ]),
      transition('visible => void', [
        style({tranform: 'scale(1)'}),
        animate('150ms', style({transform:'scale(0.5)'}))
      ]),
    ]),
    trigger('animation2' ,[
      transition(':leave',[
        style({opacity: 1}),
        animate('50ms', style({opacity:0.8}))
      ])
  ])
]
})
export class TaggedImageLightboxComponent {
  @Input() taggedData: Item[]= [];
  @Input() showCount = false;
  @Input() profileid: string;
  @Output() onImageChange = new EventEmitter<any>();



  previewImage = false;
  showMask = false;
  currentLightboxImage: Item = this.taggedData[0];
  currentIndex = 0;
  controls = true;
  totalImageCount= 0;
  value: any;
  taggedImg: any[] = [];
  TaggedgalleryColumns: any;


  constructor(public galleryService: GalleryService,
    public router: Router,
  ){
  }

  ngOnInit(): void {
  this.totalImageCount = this.taggedData?.length
  if(!this.profileid){
    this.profiletaggedPost();
  }else{
    this.taggedPost();
  }
  }

  onPreviewImage(index: number): void{
    this.showMask = true;
    this.previewImage = true;
  this.currentIndex = index;
  this.currentLightboxImage = this.taggedData[index]
  }

  onAnimationEnd(event: AnimationEvent){
    if(event.toState === 'void'){
      this.showMask = false;
    }
  }

  onClosePreview(){
    this.previewImage = false;
  }

  next():void{
  this.currentIndex = this.currentIndex +1;
  if(this.currentIndex > this.taggedData?.length - 1)
    {
      this.currentIndex = 0;
    }
    this.currentLightboxImage = this.taggedData[this.currentIndex]
  }

  prev():void{
  this.currentIndex = this.currentIndex -1;
  if(this.currentIndex < 0){
    this.currentIndex = this.taggedData?.length - 1;
  }
  this.currentLightboxImage = this.taggedData[this.currentIndex]

  }

  taggedPost() {
    this.taggedImg = [];
    this.TaggedgalleryColumns = [];
    this.galleryService.getTaggedPostbyId(this.profileid).subscribe((data: any) => {
      this.taggedImg = data?.items;
      // this.isloader = false;
      this.TaggedgalleryColumns = this.taggedImg?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
    });
    // this.isloader = true;
  }

  profiletaggedPost() {
    this.taggedImg = [];
    this.TaggedgalleryColumns = [];
    this.galleryService.getTaggedPost().subscribe((data: any) => {
      this.taggedImg = data?.list;
      // const len = this.TaggedgalleryColumns?.length || 0;
      this.TaggedgalleryColumns = this.taggedImg?.reduce(
        (acc, item, index) => {
          const accIndex = index % 4;
          acc[accIndex].push(item);
          return acc;
        },
        [[], [], [], []]
      );
    });
  }

  likeImage(data: any) {
  this.onImageChange.emit(data);

    }
}
