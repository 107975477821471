import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-overall-star',
  templateUrl: './overall-star.component.html',
  styleUrl: './overall-star.component.css'
})
export class OverallStarComponent {
  @Input('reviews') public reviews: any = 0;
  @Input('starCount') public starCount: number = 5;
  @Input('isReviewShow') public isReviewShow = false;
  @Input('rating') public rating: number = 0;
  @Input('color') public color: string = 'accent';
  @Output() public ratingUpdated = new EventEmitter();

  private snackBarDuration: number = 2000;
  public ratingArr: any[] = [];

  constructor() {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(review: number) {
    this.ratingUpdated.emit(review);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}

export enum StarRatingColor {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn',
}
