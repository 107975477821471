<div class="container" style="padding: 20px;">
  <div class="row">
    <div class="col-md-12" style="text-align: center;">
      <span class="material-icons-outlined" style="text-align: center;">
        error_outline
        </span>
      <h1>Report this Review</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
  <form>
    <mat-form-field appearance="outline" class="example-full-width custom-field">
      <mat-label>Reason for reporting this review</mat-label>
      <input matInput placeholder="Enter your reason here">
    </mat-form-field>
    <button
    mat-raised-button

    type="submit"
    style="border-radius: 10px; width:150px; height: 50px; text-align: center; background-color: #525EB1; color:#fff"
    class="mx-2"
    mat-dialog-close
    >
    Submit
    </button>

    <button mat-raised-button type="button" mat-dialog-close style=" border-radius: 10px; width:150px; height: 50px; text-align: center;">
    Cancel
    </button>
  </form>
</div>
</div>
</div>
