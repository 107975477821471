import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ITeam } from '../_models/team.model';
import { ResponseModal } from '../_models/response-modal';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private httpClient: HttpClient) {}
  createTeam(
    teammembersInfo: ITeam,
    businessId: string,
    listingId: string,
    category: string,
    listingName: string
  ): Observable<ITeam> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/teammembers`,
        { ...teammembersInfo, businessId, listingId, category, listingName },
        {
          headers,
        }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //all
  getTeamMembers() {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/teammembers/provider`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getTeamByListing(listingId?: any){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/publicUri/teammembers/listing?listingId=${listingId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getTeamMember(teammemberId: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/teammembers/${teammemberId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getbusinessProvider(businessId: string){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/businessproviderbyid/${businessId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getAllbusinessProvider(){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/businessprovider/all`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  checkRequest(requestTo: string){
    return this.httpClient
    .get<ResponseModal>(
      `${environment.apiUrl}/teammembers/request/existing-check?requestTo=${requestTo}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  updateTeam(teamInfo: ITeam): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/teammembers`, teamInfo, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // getTeamMemberRequest(providerId?: any){
  //   return this.httpClient
  //   .get<any>(
  //     `${environment.apiUrl}/teammembers/request?businessId=${providerId}`
  //   )
  //   .pipe(
  //     map((response) => {
  //       return response || {};
  //     }),
  //     catchError(this.errorMgmt)
  //   );
  // }

  getTeamMemberRequest(){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/teammembers/request`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getApprovedTeamMembersData(providerId?: any){
    return this.httpClient
    .get<any>(
      `${environment.apiUrl}/teammembers/request/approved/list?businessId=${providerId}`
    )
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  memberStatusUpdate( teammemberId: string, status: string): Observable<any> {
    const headers = { 'content-type': 'application/json'};
    return this.httpClient
      .put<any>(`${environment.apiUrl}/teammembers/statusupdate?teammemberId=${teammemberId}&status=${status}`,  { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getmemberByStatus( businessId: string, status: string): Observable<any> {
    const headers = { 'content-type': 'application/json'};
    return this.httpClient
      .get<any>(`${environment.apiUrl}/teammembers/provider?status=${status}`,  { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteTeamMember(teammemberid: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/teammembers/${teammemberid}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
