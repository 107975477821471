import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';

@Injectable({
  providedIn: 'root',
})
export class SavedService {
  constructor(private httpClient: HttpClient) {}

  getFavoriteCustomers(tabWord: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedbyme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getFavoriteCustomerstab(tabWord: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedbyme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  createFavoriteCustomer(
    savedCustomerId: string,
    saved: boolean
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/favoritecustomers/create`,
        { savedCustomerId, saved },
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  customerSavedMe() {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=customer`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customerSavedMeMessage(tabWord: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customersavedMeforDashboard() {
    return this.httpClient
      .get<ResponseModal>(`${environment.apiUrl}/favoritemembers/savedme`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customersavedMetotal() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/favoritemembers/savedme`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  listingssavedMeforDashboard() {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=provider`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customerSavedMeNew(tabWord: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  displayCustomer(tabWord: any) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?tabWord=${tabWord}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  customerSavedMeByid(listingId: string) {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/favoritemembers/savedme?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  memberSavedMeCountByid(listingId: string) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/publicUri/favoritemembers/savedme/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getSavedByMeListing(listingId: any) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/favoritecustomers/savedbyme?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getSavedMeListing(listingId: any) {
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/favoritecustomers/savedme?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  deleteFavoriteListing(favCustomerId: string) {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/favoritelistings/${favCustomerId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  // deleteFavoriteListings(){
  //   return this.httpClient
  //   .delete<any>(`${environment.apiUrl}/favoritelistings/delete`)
  //   .pipe(
  //     catchError((err) => {
  //       console.error(err);
  //       throw err;
  //     })
  //   );
  // }

  deleteFavoriteCustomer(favCustomerId: string) {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/favoritecustomers/${favCustomerId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  sendMessageTo(obj: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/message/compose`, obj, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  //getAll Promotions
  getFavorite() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/favoritelistings/provider`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }
}
