<div class="star-rating">
  <!-- style="display: flex; align-items: center; height: 30px;  width: 20px;" -->

  &nbsp;&nbsp;&nbsp; <span class="body-2" *ngIf="isReviewShow">{{ rating }}</span><span class="body-2"  *ngIf="isReviewShow">/</span>
 <span class="body-2" *ngIf="isReviewShow">{{ starCount }}</span>
 &nbsp;&nbsp;&nbsp;
  <button
  class="star-button"
    mat-icon-button
    [color]="color"
    *ngFor="let ratingId of ratingArr; index as i"
    [id]="'star_' + i"
    (click)="onClick(i + 1)"
  >
    <mat-icon>
      {{ showIcon(i) }}
    </mat-icon>
  </button>
</div>
