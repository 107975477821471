import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResponseModal } from '../_models/response-modal';
import { IListingInformations } from '../_models/listingInformation.model';
import { IEndorsements} from '../_models/endorsement.model';

@Injectable({
  providedIn: 'root',
})
export class EndorsementsService {
  constructor(private httpClient: HttpClient) {}

  createEndorsement(
    endorsementsInfo: IEndorsements
  ): Observable<IEndorsements> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/endorsements/given/create`, endorsementsInfo, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateEndorsement(endorsementsInfo: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/endorsements/given/update`, endorsementsInfo, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  createRequestEndorsement(
    endorsementsReqInfo: any
  ): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .post<any>(`${environment.apiUrl}/endorsements/request/create`, endorsementsReqInfo, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //getAll
  getEndorsement(pageSize: any, pageIndex: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/endorsements/received?pageSize=${pageSize}&pageIndex=${pageIndex}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getTotalEndorsement() {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/endorsements/received`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getEndorsementByListing(listingId: string) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/publicUri/endorsements/received/listing?listingId=${listingId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getReceivedByStatus(status:string): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .get<any>(
        `${environment.apiUrl}/endorsements/received/?status=${status}`, { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateGivenEndorsement(endorsementId: string){
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/endorsements/given/update`, { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  sortEndorsementByListing(listingId?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/endorsements/given/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  sortreceivedEndorsementsByListing(listingId?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/endorsements/received/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  sortrequestEndorsementsByListing(listingId?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/endorsements/myrequests/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  updateVisibilityStatus(endorsementId: string, visibility:boolean){
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/endorsements/received/visibility?endorsementId=${endorsementId}&visibility=${visibility}`, { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  givenEndorsementByListing(listingId: any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/given/listing?listingId=${listingId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getGivenEndorsements(listingId: any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/publicUri/endorsements/given/listing?listingId=${listingId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }


  getallGivenEndorsmenttofilter(){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/given`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getAllGivenEndorsement(pageSize: any, pageIndex: any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/given?pageSize=${pageSize}&pageIndex=${pageIndex}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  updateEndorsementStatus(endorsementId: string, status: any): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/endorsements/request/statusupdate?endorsementId=${endorsementId}&status=${status}`, { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  MyRequestEndorsement(pageSize: any, pageIndex:any){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/myrequests?pageSize=${pageSize}&pageIndex=${pageIndex}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  getRequestEndorsement(){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/received/requests`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  postRequestEndorsementPage(pageSize: any, pageIndex:any  ): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/endorsements/received/requests?pageSize=${pageSize}&pageIndex=${pageIndex}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  MyRequestByStatus(status:string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/myrequests?status=${status}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  RequestByStatus(status:string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/received/requests?status=${status}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  deleteMyRequestEndorsement(endorsementId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/endorsements/request/delete/${endorsementId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  deleteGivenEndorsement(endorsementId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/endorsements/given/delete/${endorsementId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  checkEndorsementAlreadyGiven(endorsementToProviderId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/given/existing-check?endorsementToProviderId=${endorsementToProviderId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  checkEndorsementAlreadyReceived(endorsementFromProviderId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/endorsements/received/existing-check?endorsementFromProviderId=${endorsementFromProviderId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }


}
