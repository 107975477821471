import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { CoreService } from 'src/app/_services/core.services';

@Component({
  selector: 'app-router',
  templateUrl: './router.component.html',
  styleUrl: './router.component.css',
})
export class RouterComponent implements OnInit {
  title = 'QUP Style';
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  userInfo: any;

  constructor(
    private readonly keycloak: KeycloakService,
    protected readonly router: Router,
    private url: LocationStrategy,
    private coreService: CoreService
  ) {}

  public async ngOnInit() {
    let isUserLoggedIn = await this.keycloak.isLoggedIn();
    if (isUserLoggedIn) {
      this.isLoggedIn = true;
      if (!this.coreService.isProvider()) {
        this.router.navigate(['register']);
      } else {
        this.keycloak.keycloakEvents$.subscribe({
          next: (e) => {
            if (e.type == KeycloakEventType.OnTokenExpired) {
              this.keycloak
                .updateToken(100)
                .then((refreshed) => {
                  if (!refreshed) {
                    this.login();
                  }
                })
                .catch(() => {
                  this.login();
                });
            }
          },
        });
        this.router.navigate(['/s/pages']);
      }
    } else {
      // this.router.navigate(['/search']);
      this.isLoggedIn = false;
      this.login();
    }
  }

  public login() {
    localStorage.clear();
    this.keycloak.login({
      redirectUri: window.location.origin + '/',
    });
  }
}
