import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { RouterComponent } from './components/router/router.component';
import { ContactComponent } from './pages/personal-profile/contact/contact.component';
import { CustomerProfileComponent } from './pages/customer-profile/customer-profile.component';
import { PersonalProfileComponent } from './pages/personal-profile/personal-profile.component';
import { ReviewCreateComponent } from './pages/reviews/review-create/review-create.component';
import { ReviewEditComponent } from './pages/reviews/review-edit/review-edit.component';
import { ReviewsComponent } from './pages/reviews/reviews.component';
import { SavedComponent } from './pages/saved/saved.component';
import { UpdateProfileComponent } from './pages/update-profile/update-profile.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './shared/signup/signup.component';
import { GlobalSearchPageComponent } from './non-auth-module/global-search-page/global-search-page.component';
const routes: Routes = [
  // { path: '',   redirectTo: '/complete-profile', pathMatch: 'full' },
  // { path: 'complete-profile',   component: CompleteProfileComponent },
  { path: '', redirectTo: '/router', pathMatch: 'full' },
  {
    path: 'router',
    component: RouterComponent,
  },

  {
    path: '',
    loadChildren: () =>
      import('./non-auth-module/non-auth-module.module').then(
        (m) => m.NonAuthModuleModule
      ),
  },

  {
    path: 's',
    component: RouterComponent,
    children: [
      {
        path: 'pages',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
      },
      {
        path: 'personal-profile',
        component: PersonalProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: ':id',
        component: PersonalProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'update-profile',
        component: UpdateProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'saved',
        component: SavedComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'create-reviews',
        component: ReviewCreateComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'edit-reviews/:id',
        component: ReviewEditComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'customer-profile/:id',
        component: CustomerProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'customer-profile',
        component: CustomerProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'search-results/:value',
        component: SearchResultsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'search-results',
        component: SearchResultsComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'signin',
        component: LoginComponent,
        canActivate: [AuthGuard],
        data: { roles: ['provider'] },
      },
      {
        path: 'provider/register',
        component: SignupComponent,
        canActivate: [AuthGuard],
        data: { roles: ['customer'] },
      },
    ],
  },
  { path: '**', component: GlobalSearchPageComponent },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
