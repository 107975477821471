  import { Injectable } from '@angular/core';
  import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
  import { Observable } from 'rxjs';
  import { environment } from 'src/environments/environment';
  import { catchError, map, throwError } from 'rxjs';
  import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
  import { UpdateProfile } from '../_models/update-profile.model';
  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProfileService {
    UpdateProfile: any;
    headers = new HttpHeaders().set('Content-Type', 'application/json');
    httpClient: any;
    getProfile: any;
    profileImageURL: any;


    constructor(private http: HttpClient) { }

    getProfileDetails(businessId: string): Observable<UpdateProfile> {
      // console.log(businessId,'business id data')
      const url = `${environment.apiUrl}/businessprovider/details`;
      return this.http.get<UpdateProfile>(url).pipe(
        catchError(this.errorMgmt)
      );
    }

    updateProfile(
      id: string,
      // data: any,
      firstName: string,
      lastName: string,
      userName: string,
      emailAddress: string,
      phoneNumber: string,
      // profileImageURL:string,
      addressline1: string,
      addressline2: string,
      city: string,
      state: string,
      zipcode: string,
      country: string,
      status: string,
      category:string,
      file: File


    ): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('id', id);
      // formData.append('data', data);
      formData.append('userName', userName);
      formData.append('addressline1', addressline1);
      formData.append('addressline2', addressline2);
      formData.append('city', city);
      formData.append('state', state);
      formData.append('zipcode', zipcode);
      formData.append('country', country);
      formData.append('status', status);
      formData.append('emailAddress', emailAddress);
      formData.append('phoneNumber', phoneNumber);
      formData.append('category', category);
      // formData.append('profileImageURL', profileImageURL);


      if (file) {
        formData.append('file', file);
      }


      const req = new HttpRequest(
        'PUT',
        `${environment.apiUrl}/businessprovider/profile`,
        formData
      );

      return this.http.request(req).pipe(
        catchError(this.errorMgmt)
      );
    }
    updateProfileImage(businessId: string): Observable<any> {
      const url = `${environment.apiUrl}/businessprovider/clear/profileImage`;
      return this.http.put(url, {}).pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
    }
    updatePassword(currentPassword: string, newPassword: string, confirmation: string): Observable<any> {
      const url = `${environment.apiUrl}/businessprovider/update/password`;
      const body = { currentPassword, newPassword, confirmation };
      return this.http.put(url, body, { headers: this.headers })
        .pipe(
          map((response: any) => {
            return {
              result: response.result,
              message: response.message.text
            };
          }),
          catchError((error: any) => {
            console.error('Error updating password:', error);
            throw error;
          })
        );
    }
    errorMgmt(error: HttpErrorResponse) {
      let errorMessage = ' ';
      if (error.error instanceof ErrorEvent) {
        //get client side error
        errorMessage = error.error.message;
      } else {
        //get serverside error
        errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(errorMessage);
    }


  }
