<div class="container-fluid content-space">
  <div class="conatiner" style="margin-top: 60px">
    <div class="row">
      <div class="col-lg-10">
        <div class="row">
          <ng-container *ngIf="customerProfileData?.profileImageURL">
            <div
              class="col-lg"
              style="margin-top: 80px"
              *ngIf="customerProfileData?.profileImageURL"
            >
              <img
                class="image-style"
                [src]="customerProfileData?.profileImageURL"
              />
            </div>
          </ng-container>
          <div
            class="col-lg-{{
              customerProfileData?.profileImageURL ? '7' : '9'
            }} my-4 profile-details"
          >
            <h1 style="font-size: 25px">
              <b
                >{{ customerProfileData?.firstName }}
                {{ customerProfileData?.lastName }}</b
              >
            </h1>
            <p style="font-size: 14px; font-weight: 400">
              {{ customerProfileData?.city }}, &nbsp;{{
                customerProfileData?.state
              }}

              <img
                *ngIf="customerProfileData?.countryCode"
                alt="Country Flag"
                [src]="customerProfileData?.flag"
                style="width: 25px; height: 15px"
              />
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-2 icon-container"
        *ngIf="customerProfileData?.profileImageURL"
      >
        <button
          mat-mini-fab
          class="icon-btn-style mx-4"
          style="float: right"
          (click)="saveCustomer(customerProfileData?.id)"
          *ngIf="!alreadySaved?.saved && customerProfileData?.privateAccount === false"
        >
          <span class="material-icons-outlined"> bookmark_border </span>
        </button>

        <button
          mat-mini-fab
          class="icon-btn-style mx-4"
          style="float: right"
          disabled
          *ngIf="customerProfileData?.privateAccount === true"
        >
          <span class="material-icons-outlined"> bookmark_border </span>
        </button>
        <button
          *ngIf="alreadySaved?.saved && customerProfileData?.privateAccount === false"
          mat-mini-fab
          disabled
          class="icon-btn-style mx-4"
          style="float: right"
        >
          <span class="material-icons bookmarSave" style="color: #525eb1"> bookmark </span>
        </button>
      </div>

      <div
        class="col-lg-2 icon-container"
        *ngIf="!customerProfileData?.profileImageURL"
      >
        <button
          mat-mini-fab
          class="icon-btn-style mx-4"
          style="float: right"
          *ngIf="!alreadySaved?.saved"
        >
          <span
            class="material-icons-outlined"
            (click)="saveCustomer(customerProfileData?.id)"
          >
            bookmark_border
          </span>
        </button>
        <button
          *ngIf="alreadySaved?.saved"
          matTooltip="This Profile is already saved"
          mat-mini-fab
          disabled
          class="icon-btn-style mx-4"
        >
          <span class="material-icons" style="color: #525eb1;"> bookmark </span>
        </button>
      </div>

      <mat-tab-group dynamicHeight class="my-4">
        <mat-tab
          label="Provider Reviews"
          *ngIf="
            customerProfileData?.privateAccount === false &&
            customerReviews?.length > 0
          "
        >
          <div class="container my-4">
            <div class="col-md-12">
              <mat-card class="card-style">
                <div class="row" style="padding: 15px">
                  <div class="col-md-4">
                    <div style="display: flex; align-items: center">
                      <span class="material-icons-outlined">
                        star_outline
                      </span>
                      <span style="font-size: 20px"
                        >&nbsp;<b>My Reviews</b></span
                      >
                    </div>
                  </div>
                </div>
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex flex-wrap">
                        <div
                          class="card mx-2 mb-4"
                          style="width: 390px"
                          *ngFor="let review of customerReviews"
                        >
                          <div class="card-body">
                            <div class="leaderboard-icon-style">
                              <img
                                src="../../../assets/images/grey.jpg"
                                alt="Avatar"
                                style="
                                  width: 80px;
                                  height: 80px;
                                  border-radius: 10px;
                                "
                                *ngIf="!review.listingProfileUrl"
                              />
                              <img
                                [src]="review.listingProfileUrl"
                                alt="Avatar"
                                style="
                                  width: 80px;
                                  height: 80px;
                                  border-radius: 10px;
                                  object-fit: contain;
                                  background-color: #f0f2f5;
                                "
                                *ngIf="review.listingProfileUrl"
                              />
                            </div>
                            <div class="review-cardstyle">
                              <h4 style="margin-left: 10px; cursor: pointer">
                                <b
                                  [routerLink]="[
                                    '/s/pages/personal-profile',
                                    review.reviewToListingId
                                  ]"
                                  >{{ review.listingName }}</b
                                >
                              </h4>

                              <p
                                *ngIf="review.listingAddress !== ''"
                                class="review-text"
                              >
                                <span
                                  class="material-icons-outlined"
                                  style="color: #cdcfce; font-size: 25px"
                                >
                                  location_on
                                </span>
                                {{ review.listingAddress }}
                              </p>
                              <app-star-rating
                                [rating]="review.overall"
                                [starCount]="starCount"
                                [color]="starColor"
                                >/5</app-star-rating
                              >
                            </div>
                          </div>

                          <div
                            class="row"
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 20px;
                              margin-left: 10px;
                            "
                          >
                            <div class="col">
                              <h4>
                                <b>"{{ review.reviewTitle }}"</b>
                              </h4>
                              <p>{{ review.reviewDescription }}</p>
                            </div>
                          </div>
                          <div class="review-images d-flex flex-wrap">
                            <div
                              class="review-image-container"
                              *ngFor="let imageUrl of review.reviewImageUrls"
                            >
                              <img
                                [src]="imageUrl"
                                style="
                                  height: 80px;
                                  width: 80px;
                                  border-radius: 10px;
                                  margin-bottom: 10px;
                                  object-fit: contain;
                                  background-color: #f0f2f5;
                                "
                              />
                            </div>
                          </div>

                          <hr style="width: 90%; margin: 0 auto" />

                          <div class="row m-2">
                            <div>
                              <span
                                class="seeall"
                                style="display: flex; cursor: pointer"
                                (click)="reviewsPage(review)"
                              >
                                <p>Read {{ review.listingName }} Reply</p>
                                <span
                                  class="material-icons read"
                                  style="font-size: large; margin-top: 2px"
                                >
                                  arrow_forward
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </mat-tab>

        <ng-container
          *ngIf="
            customerProfileData?.privateAccount === false &&
            customerGalleryImage &&
            customerGalleryImage.length > 0
          "
        >
          <mat-tab label="Gallery">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <mat-card class="card-style" style="padding: 5px">
                    <div class="row" style="padding: 15px">
                      <div class="col-md-9">
                        <div style="display: flex; align-items: center">
                          <span class="material-icons-outlined"> image </span>
                          <span style="font-size: 20px"
                            >&nbsp;<b>Gallery</b></span
                          >
                        </div>
                      </div>

                      <div class="col-md-3">
                        <mat-chip-list aria-label="Tab Selection">
                          <button
                            (click)="changeTab1('My Posts')"
                            [style.color]="
                              selectedTab1 === 'My Posts' ? '#fff' : '#333333'
                            "
                            class="mx-2 mat-stroked-button"
                            style="
                              border-radius: 30px;
                              height: 40px;
                              width: 100px;
                              margin: 10px;
                              cursor: pointer;
                            "
                            [selected]="selectedTab1 === 'My Posts'"
                            [style.background]="
                              selectedTab1 === 'My Posts' ? '#333333' : ''
                            "
                            *ngIf="customerGalleryImage.length > 0"
                          >
                            My Posts
                          </button>

                          <button
                            (click)="changeTab1('Tagged Posts')"
                            [style.color]="
                              selectedTab1 === 'Tagged Posts'
                                ? '#fff'
                                : '#333333'
                            "
                            class="mx-2 mat-stroked-button"
                            style="
                              border-radius: 30px;
                              height: 40px;
                              width: 140px;
                              cursor: pointer;
                            "
                            [selected]="selectedTab1 === 'Tagged Posts'"
                            [style.background]="
                              selectedTab1 === 'Tagged Posts' ? '#333333' : ''
                            "
                            *ngIf="taggedImg && taggedImg.length > 0"
                          >
                            Tagged Posts
                          </button>
                        </mat-chip-list>
                      </div>
                    </div>

                    <div class="row">
                      <div *ngIf="selectedTab1 === 'My Posts'">
                        <app-gallery-lightbox
                          [galleryData]="customerGalleryImage"
                          [showCount]="true"
                          [profileid]="customerId"
                          (onImageChange)="onImageLike($event)"
                        >
                        </app-gallery-lightbox>
                      </div>
                      <div class="row" *ngIf="selectedTab1 === 'Tagged Posts'">
                        <app-tagged-image-lightbox
                          [taggedData]="taggedImg"
                          [showCount]="true"
                          (onImageChange)="onImageLike($event)"
                        >
                        </app-tagged-image-lightbox>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div
        style="margin-top: 50px"
        *ngIf="customerProfileData?.privateAccount === true"
      >
        <mat-card class="card-style2">
          <div style="height: 150px; text-align: center; margin-top: 15px">
            <span
              class="material-icons read"
              style="color: #cdcfce; font-size: 100px"
            >
              lock
            </span>
            <h1><b>This account is private</b></h1>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>

<ng-template #callReviewProfileDialog>
  <form #userForm="ngForm">
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-4">
          <div class="d-flex justify-content-between align-items-center">
            <h1 class="mt-4">
              Review by {{ datafromIdBased?.customerUserName }}
            </h1>
            <button
              mat-mini-fab
              mat-dialog-close
              style="float: right; background-color: #fff; color: black"
            >
              <mat-icon style="margin-left: -5px; margin-top: -5px"
                >close</mat-icon
              >
            </button>
          </div>
          <hr style="width: 90%; margin: 0 auto" />
          <div class="card-body mt-4">
            <div class="leaderboard-icon-style">
              <img
                [src]="datafromIdBased?.listingProfileUrl"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="datafromIdBased?.listingProfileUrl"
              />
              <img
                src="../../../assets/images/grey.jpg"
                alt="Avatar"
                style="width: 80px; height: 80px; border-radius: 10px"
                *ngIf="!datafromIdBased?.listingProfileUrl"
              />
            </div>
            <div class="review-cardstyle">
              <h4>
                <b>{{ datafromIdBased?.listingName }}</b>
              </h4>
              <p style="display: flex; align-items: center; font-size: 16px">
                <span
                  class="material-icons-outlined"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ datafromIdBased?.listingAddress }}
              </p>
              <div class="d-flex">
                <app-star-rating
                  [rating]="datafromIdBased?.overall"
                  [starCount]="starCount"
                  [color]="starColor"
                >
                </app-star-rating>
                <span style="margin-left: 152px; margin-top: 5px"
                  ><b>{{ datafromIdBased?.overall }}/5</b></span
                >
              </div>
            </div>
          </div>
          <h3>
            <b>"{{ datafromIdBased?.reviewTitle }}"</b>
          </h3>
          <p>{{ datafromIdBased?.reviewDescription }}</p>
          <div class="review-images d-flex flex-wrap">
            <div
              class="review-image-container"
              *ngFor="let reviewImageUrl of datafromIdBased?.reviewImageUrls"
            >
              <img
                [src]="reviewImageUrl"
                alt="Review Image"
                class="review-image"
                style="
                  width: 80px;
                  height: 80px;
                  border-radius: 10px;
                  background-color: #f0f2f5;
                  object-fit: contain;
                "
              />
            </div>
          </div>
          <hr style="width: 90%; margin: 0 auto" />
          <div style="display: flex">
            <div class="col-md-4 mt-4">
              <div class="leaderboard-icon-style">
                <img
                  [src]="datafromIdBased?.listingProfileUrl"
                  alt="Avatar"
                  style="width: 80px; height: 80px; border-radius: 10px"
                  *ngIf="datafromIdBased?.listingProfileUrl"
                />
                <img
                  src="../../../assets/images/grey.jpg"
                  alt="Avatar"
                  style="width: 80px; height: 80px; border-radius: 10px"
                  *ngIf="!datafromIdBased?.listingProfileUrl"
                />
              </div>
              <p style="margin-left: 10px; margin-top: 70px">
                {{ datafromIdBased?.listingName }}
              </p>
            </div>
            <div class="vl mt-3">
              <p style="margin-left: 10px" class="mt-4">
                Reply to {{ datafromIdBased?.customerUserName }}
              </p>
              <p style="margin-left: 10px" class="mt-1">
                Thank you so much for your kind reply!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
