<div class="container-fluid" style="padding-top: 10px">
  <h1 class="heading mb-4">Saved</h1>
  <div class="row">
    <div
      class="col-md-9"
      style="padding: 10px; display: flex; align-items: flex"
    >
      <mat-chip-list aria-label="Tab Selection">
        <button
          (click)="changeTab('Saved me')"
          [style.color]="selectedTab === 'Saved me' ? '#fff' : '#333333'"
          class="mx-2 mat-stroked-button"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          [selected]="selectedTab === 'Saved me'"
          [style.background]="selectedTab === 'Saved me' ? '#333333' : ''"
        >
          Saved me
        </button>

        <button
          (click)="changeTab('Saved by me')"
          [style.color]="selectedTab === 'Saved by me' ? '#fff' : '#333333'"
          class="mat-stroked-button"
          style="
            border-radius: 30px;
            height: 40px;
            width: 100px;
            cursor: pointer;
          "
          [selected]="selectedTab === 'Saved by me'"
          [style.background]="selectedTab === 'Saved by me' ? '#333333' : ''"
        >
          Saved by me
        </button>
      </mat-chip-list>
    </div>
    <!-- <div class="col-md-3" *ngIf="selectedTab === 'Saved by me'">
      <mat-form-field
      class="field-style custom-field example-full-width"
      style="float: right"
      appearance="outline"
    >
      <mat-label>All Business Listings</mat-label>
      <mat-select
          formControlName="listingTitle" (selectionChange)="onListChange($event)"
          >
          <mat-option (click)="allSavedByMe()">All Business Listings</mat-option>
            <mat-option *ngFor="let list of listings" [value]="list.id">
            {{list.listingName}}</mat-option>
          </mat-select>
    </mat-form-field>
    </div> -->
    <!-- <div class="col-md-3" *ngIf="selectedTab === 'Saved me'">
      <mat-form-field
      class="field-style custom-field example-full-width"
      style="float: right"
      appearance="outline"
    >
      <mat-label>All Business Listings</mat-label>
      <mat-select
          formControlName="listingTitle" (selectionChange)="savedmeChange($event)"
          >
          <mat-option (click)="allSaved()">All Business Listings</mat-option>
            <mat-option *ngFor="let list of listings" [value]="list.id">
            {{list.listingName}}</mat-option>
          </mat-select>
    </mat-form-field>
    </div> -->
    <div class="col-md-3">
      <mat-form-field
        style="float: right; display: flex; align-items: center"
        appearance="outline"
        class="custom-form-field custom-field example-full-width displaySearch"
        appearance="outline"
      >
        <input
          matInput
          placeholder="Search"
          (keyup)="search($event)"
          [(ngModel)]="searchQuery"
          class="example-form-field example-full-width custom-field search-box"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
          *ngIf="searchQuery !== ''"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="search(searchQuery)"
          *ngIf="searchQuery === ''"
        >
          <mat-icon *ngIf="searchQuery === ''">search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="selectedTab === 'Saved me'">
    <div class="col-sm-3">
      <mat-tab-group (selectedTabChange)="changeCustomerDataStatus($event)">
        <mat-tab label="Customers"> </mat-tab>
        <mat-tab label="Provider"></mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="selectedTab === 'Saved me'">
    <!-- <mat-spinner *ngIf="isloader" style="margin: 0 auto; justify-content: center; align-items: center; display: flex;"></mat-spinner> -->
    <div class="col-xl-4" *ngFor="let savedme of customerSavedMe">
      <mat-card class="card-style" style="margin-bottom: 25px">
        <mat-card-header>
          <div class="col-1" *ngIf="savedme.savedByUserType === 'Customer'">
            <img
              src="../../../assets/images/grey.jpg"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="!savedme.customerProfileUrl"
            />

            <img
              src="{{ savedme.customerProfileUrl }}"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="savedme.customerProfileUrl"
            />
          </div>
          <div class="col-1" *ngIf="savedme.savedByUserType === 'Provider'">
            <!-- <img src="../../../assets/images/grey.jpg" alt="Avatar" style="height: 80px; width: 80px; border-radius: 10px; float: left;"  *ngIf="!savedme.providerProfileUrl"/> -->
            <img
              src="../../../assets/images/grey.jpg"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="!savedme.providerPrimaryListingProfileUrl"
            />

            <img
              src="{{ savedme.providerPrimaryListingProfileUrl }}"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="savedme.providerPrimaryListingProfileUrl"
            />

            <!-- <img src="{{savedme.providerProfileUrl}}" alt="Avatar" style="height: 80px; width: 80px; border-radius: 10px; float: left;" *ngIf="savedme.providerProfileUrl" /> -->
          </div>
          <div class="col-11">
            <span
              *ngIf="savedme.savedByUserType === 'Customer'"
              class="material-icons"
              style="float: right; color: hsl(150, 2%, 81%); cursor: pointer"
              (click)="
                deleteSavedListing(
                  savedme.savedObjectId,
                  savedme.savedByUserType,
                  savedme.customerName
                )
              "
            >
              highlight_off
            </span>
            <span
              *ngIf="savedme.savedByUserType === 'Provider'"
              class="material-icons"
              style="float: right; color: hsl(150, 2%, 81%); cursor: pointer"
              (click)="
                deleteSavedListing(
                  savedme.savedObjectId,
                  savedme.savedByUserType,
                  savedme.providerName
                )
              "
            >
              highlight_off
            </span>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="row" *ngIf="savedme.savedByUserType === 'Customer'">
            <div class="col-10 mx-4">
              <h2 class="savedName">
                <span
                  [routerLink]="[
                    '/s/pages/customer-profile',
                    savedme.customerId
                  ]"
                  >{{ savedme.customerName }}</span
                >
              </h2>
              <p
                *ngIf="savedme.customerLocation !== ''"
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -15px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ savedme.customerLocation }}
              </p>

              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -10px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  diamond
                </span>
                {{ savedme.forWhichListing }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="savedme.savedByUserType === 'Provider'">
            <div class="col-10 mx-4">
              <h2 class="savedName">
                <span
                  [routerLink]="[
                    '/',
                    savedme.providerPrimaryListingName
                  ]"
                  >{{ savedme.providerName }}</span
                >
              </h2>
              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -15px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ savedme.providerLocation }}
              </p>

              <p
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -10px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  diamond
                </span>
                {{ savedme.forWhichListing }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-chip
                style="
                  align-items: center;
                  display: inline;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
                >{{ savedme.createdDate | date : "dd-MMM-yy" }}</mat-chip
              >
              <button
                *ngIf="savedme.savedByUserType === 'Customer'"
                mat-raised-button
                style="
                  float: right;
                  background-color: #525eb1;
                  color: #fff;
                  border-radius: 10px;
                "
                (click)="
                  savedMeSendMessageToCustomer(
                    savedme.customerId,
                    savedme.customerName,
                    savedme.savedByUserType
                  )
                "
              >
                Send Message
              </button>
              <button
                *ngIf="savedme.savedByUserType === 'Provider'"
                mat-raised-button
                style="
                  float: right;
                  background-color: #525eb1;
                  color: #fff;
                  border-radius: 10px;
                "
                (click)="
                  savedMeSendMessageToProvider(
                    savedme.providerName,
                    savedme.providerPrimaryListingId,
                    savedme.providerPrimaryListingName,
                    savedme.savedByUserType
                  )
                "
              >
                Send Message
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row" *ngIf="selectedTab === 'Saved by me'">
    <div class="col-sm-3">
      <mat-tab-group (selectedTabChange)="changeProviderDataStatus($event)">
        <mat-tab label="Customers"> </mat-tab>
        <mat-tab label="Listings"></mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <br />
  <div class="row" *ngIf="selectedTab === 'Saved by me'">
    <!-- <mat-spinner *ngIf="isloader" style="margin: 0 auto; justify-content: center; align-items: center; display: flex;"></mat-spinner> -->

    <div class="col-xl-4" *ngFor="let saved of favcustomer">
      <mat-card class="card-style" style="margin-bottom: 25px">
        <mat-card-header>
          <div class="col-1">
            <img
              src="../../../assets/images/grey.jpg"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="!saved.savedMemberProfileUrl"
            />
            <img
              src="{{ saved.savedMemberProfileUrl }}"
              alt="Avatar"
              style="
                height: 80px;
                width: 80px;
                border-radius: 10px;
                float: left;
                object-fit: cover;
              "
              *ngIf="saved.savedMemberProfileUrl"
            />
          </div>
          <div class="col-11">
            <span
              class="material-icons"
              style="float: right; color: hsl(150, 2%, 81%); cursor: pointer"
              (click)="
                deleteSavedCustomer(
                  saved.savedObjectId,
                  saved.savedMemberName,
                  saved.savedMemberType
                )
              "
            >
              highlight_off
            </span>
          </div>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-10 mx-4">
              <h2 class="savedName">
                <span
                *ngIf="saved.savedMemberType === 'Customer'"
                  [routerLink]="[
                    '/s/pages/customer-profile', saved.savedMemberId
                  ]"
                  >{{ saved.savedMemberName }}</span
                >
                <span
                *ngIf="saved.savedMemberType === 'Provider'"
                  [routerLink]="[
                    '/', saved.savedMemberUserName
                  ]"
                  >{{ saved.savedMemberName }}</span
                >
              </h2>
              <p
                *ngIf="saved.savedMemberLocation !== ''"
                style="
                  display: flex;
                  align-items: center;
                  padding-left: 75px;
                  margin-top: -15px;
                "
              >
                <span
                  class="material-icons-outlined mx-2"
                  style="color: #cdcfce; font-size: 25px"
                >
                  location_on
                </span>
                {{ saved.savedMemberLocation }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-chip
                style="
                  align-items: center;
                  display: inline;
                  padding: 5px;
                  background-color: #f4f5ff;
                "
                >{{ saved.createdDate | date : "dd-MMM-yy" }}</mat-chip
              >
              <button
                mat-raised-button
                style="
                  float: right;
                  background-color: #525eb1;
                  border-radius: 10px;
                  color: #fff;
                "
                (click)="
                  savedByMeMessage(saved.savedMemberId, saved.savedMemberType)
                "
              >
                Send Message
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #callDeleteSavedListingDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete {{ userType }}
        <b>" {{ deleteSavedMeUserName }} "</b>?
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #callDeleteSavedCustomerDialog>
  <form #userForm="ngForm">
    <p
      matDialogTitle
      style="text-align: left; font-size: 15px; margin-top: -10px"
    >
      <b>Delete Confirmation !</b>
    </p>
    <mat-divider style="margin-top: -20px"></mat-divider>
    <mat-dialog-content>
      <p style="text-align: center; padding: 30px; font: 400 20px;">
        Are you sure you want to delete <b>" {{ deleteSavedMemberName }} "</b>?
      </p></mat-dialog-content
    >

    <mat-dialog-actions align="center" class="mb-2">
      <button
        mat-raised-button
        type="button"
        matDialogClose="cancel"
        style="border-radius: 10px; border: 2px; width: 100px"
      >
        Cancel
      </button>
      <button
        class="btn-primary"
        type="button"
        mat-raised-button
        matDialogClose="delete"
        style="
          background-color: #525eb1;
          border-radius: 10px;
          color: #fff;
          width: 100px;
        "
      >
        Delete
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
