<div class="container my-3" style="border-radius: 25px;">
    <form class="example-form">
<div class="row">
    <div class="col-md-12 text-center">
        <mat-icon>error</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
        <h3><b>Are you sure you want to pause</b></h3>
        <h3><b>your account?</b></h3>
    </div>
</div>

<div class="row">
    <div style=" margin-bottom: 40px" class="col-md-12 example-button">
        
            <button type="button" class="btn btn-lg btn-outline-dark w-100" (click)="onCancel()">Cancel</button>
            <button class="btn btn-lg btn-primary w-100" 
            type="submit" 
            (click)="onPause()">Pause</button> 
        
      </div>
</div>
</form>
</div>