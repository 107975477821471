<div class="container-fluid" style="background-color: #f0f2f5">
  <h1 style="margin-top: 30px">Search results for {{ searchValue }}

    <button mat-button [matMenuTriggerFor]="filtermenu"  style="float: right;"><span class="material-icons">
      tune
      </span></button>
      <mat-menu #filtermenu="matMenu">
        <form class="example-section" [formGroup]="filterForm" (ngSubmit)="onFilterApply()">
        <p style="padding-left: 10px; padding-right: 15px;" (click)="$event.stopPropagation();">Business Type</p>
        <p style="padding-left: 10px; padding-right: 15px;" (click)="$event.stopPropagation();"><mat-checkbox  formControlName="Individual">Individual Provider</mat-checkbox></p>
        <p style="padding-left: 10px; padding-right: 15px;" (click)="$event.stopPropagation();"><mat-checkbox  formControlName="Business">Business with employees</mat-checkbox></p>
        <p style="padding-left: 10px; padding-right: 15px;" (click)="$event.stopPropagation();"><mat-checkbox   formControlName="Education" >Educational Institution</mat-checkbox></p>
        <p style="padding-left: 10px; padding-right: 15px;" (click)="$event.stopPropagation();"><mat-checkbox   formControlName="Student" >Student</mat-checkbox></p>
        <button mat-flat-button type="submit" style="background-color: #525eb1; color: #fff; margin-left: 20px; width: 200px; border-radius: 10px;">Apply Filter</button>
      </form>
      </mat-menu>
  </h1>
  <div class="row" *ngIf="this.listings?.length > 0">
    <div class="col-lg-3" *ngFor="let list of listings">
      <mat-card class="card-style">
        <img
          src="{{ list.profileURL }}"
          alt="Image"
          class="rounded-top"
          style="width: 100%; height: 300px; object-fit: contain"
          [routerLink]="['/', list.listingUserName]"
        />
        <!-- <span class="bookmarkicon"> <span class="material-icons-outlined ">
      bookmark_border
      </span>&nbsp;Save</span> -->
        <span
          class="visibleicon"
          [routerLink]="['/', list.listingUserName]"
          style="cursor: pointer"
        >
          <span class="material-icons-outlined"> visibility </span
          >&nbsp;Preview</span
        >
        <div class="p-3 description-text">
          <b
            [routerLink]="['/', list.listingUserName]"
            style="cursor: pointer"
            >{{ list.listingName }}</b
          >
          <p>{{ list.addressLine1 }}</p>
        </div>
        <hr />
        <div class="card-footer">
          <div *ngIf="list.cityName && !list?.country">
            <span class="address-content"
              ><span class="material-icons-outlined"> location_on </span>
              {{ list.cityName }}</span
            >
          </div>
          <div *ngIf="list.cityName && list?.country">
            <span class="address-content"
              ><span class="material-icons-outlined"> location_on </span>
              {{ list.cityName }}, {{ list.country }}</span
            >
          </div>
          <div *ngIf="!list.cityName && list?.country">
            <span class="address-content"
              ><span class="material-icons-outlined"> location_on </span>
              {{ list.country }}</span
            >
          </div>
          <div class="spacer"></div>
          <div class="date">
            <span style="color: rgba(4, 144, 4, 0.929)">Open Now</span>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12" [style.display]="noDataFoundDivDisplayStyle">
      <mat-card class="card-style" style="height: 200px">
        <h1 style="text-align: center; margin-top: 70px">No data found</h1>
      </mat-card>
    </div>
  </div>
</div>
<mat-paginator
[length]="givenDataTotal"
[pageIndex]="pageIndex"
[pageSize]="pageSize"
(page)="pageChange($event.pageSize, $event.pageIndex)"
aria-label="Select page"
>
</mat-paginator>
<!--latest search-->

<!-- <div class="container-fluid" style="padding-top: 10px;">
  <h1>Search results for {{searchValue}}</h1>
  <div class="row" *ngIf="this.listings?.length > 0">
    <div class="col-lg-3 mt-3" *ngFor="let list of listings">
<div class="transbox">
      <mat-card style="border-radius: 10px;">
        <img src="{{list.profileURL}}" style="width: 100%; margin-top: 0px; object-fit: cover; border-radius: 10px; background-color: rgba(0,0,0,0.5);
" />
        <div class="row" class="listingName">
          <button mat-flat-button class="staricon" style="background-color: #fff; border-radius: 20px;">
            Open
          </button>
          <button mat-icon-button class="menuicon">
            <span class="material-icons-outlined">
              directions
              </span>
          </button>
          <div class="col-md-12 listingtext">
          <p [routerLink]="['/s/pages/personal-profile', list.id]">{{list.listingName}}</p>
        </div>
        </div>
      </mat-card>
    </div>
    </div>
  </div>
  <div class="row" *ngIf="this.listings?.length <= 0">
    <div class="col-lg-12">
      <mat-card class="card-style" style="height: 200px;">
     <h1 style="text-align: center; margin-top: 70px;"> No data found</h1>
    </mat-card>
    </div>
    </div>
</div> -->
