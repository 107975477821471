import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateProfile } from 'src/app/_models/update-profile.model';
import { UpdateProfileService } from 'src/app/_services/update-profile.service';
import { UserService } from 'src/app/_services/user.service';
import { CoreService } from 'src/app/_services/core.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteaccountComponent } from './deleteaccount/deleteaccount.component';
import { matchpassword } from './matchpassword.validators';
import { PauseaccountComponent } from './pauseaccount/pauseaccount.component';
import { NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete/lib/ngx-gp-autocomplete-options';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete/lib/ngx-gp-autocomplete.directive';
import heic2any from 'heic2any';
import { ListDataModal } from 'src/app/_models/list-modal';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrl: './update-profile.component.css',
})
export class UpdateProfileComponent {
  @Output() sendFile = new EventEmitter();
  @Input() isReadOnly: boolean;
  @Input() category: string;
  @Input() listingid: string;
  @ViewChild('callMessageDialog')
  callMessageDialog: TemplateRef<any>;
  @ViewChild('callDeleteProfileImageDialog')
  callDeleteProfileImageDialog: TemplateRef<any>;
  @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;

  options: NgxGpAutocompleteOptions = {
    types: ['address'],
  };

  data: ListDataModal = {} as ListDataModal;
  public files: any[] = [];
  onChange: (files: any) => void = () => {};
  isImageSelected: boolean = false;
  uploads: any[] = [];
  fileName: string;
  id: any;
  updateForm: FormGroup;
  ngControl: any;
  profileData: UpdateProfile;
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  profileImageURL: string;
  addressline1: string;
  addressline2: string;
  flagCode: any;
  addressName: any;
  mapUrl: any;
  isShow: boolean;
  profileimage: any = '';
  zipcode: string;
  status = 'updated';
  file: File;
  businessId: string;
  isInputDisabled: boolean = true;
  formId: any;
  listingData: any;
  changePasswordForm: FormGroup;
  updateProfileImageData: any;
  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmation = true;

  deleteImage() {
    let dialogRef = this.dialog.open(this.callDeleteProfileImageDialog, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.updateProfileService.updateProfileImage(this.businessId).subscribe(
          () => {
            this.showSnackbarTopPosition(
              'Image deleted successfully',
              'OK',
              2000
            );
            this.updateForm.patchValue({ profileImageURL: '' });
            this.profileImageURL = '';
            this.profileimage = '';
            this.ngOnInit();
          },
          (error) => {
            this.showSnackbarTopPosition('Error deleting image', 'OK', 2000);
          }
        );
      } else {
        if (result === 'cancel') {
          this.dialog.closeAll();
        }
      }
    });
  }

  disableSelect = new FormControl(false);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogRef: MatDialog,
    private updateProfileService: UpdateProfileService,
    private userService: UserService,
    private coreService: CoreService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private filesApiService: UploadFilesService
  ) {}

  ngOnInit(): void {
    //ChangePassword Form
    this.getProfileDetails();
    this.changePasswordForm = this.formBuilder.group({
      userName: [''],
      currentPassword: ['', Validators.required],
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
          ),
        ]),
      ],
      confirmation: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
          ),
        ]),
      ],
    });
    // this.getListing();
    this.updateForm = new FormGroup(
      {
        firstName: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]*$'),
          ])
        ),
        lastName: new FormControl(
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z ]*$'),
          ])
        ),
        emailAddress: new FormControl(
          '',
          Validators.compose([
            // Validators.required,
            Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z]+.[a-z]{2,4}$'),
          ])
        ),
        phoneNumber: new FormControl(
          '',
          Validators.compose([
            // Validators.required,
            Validators.pattern(/^\d{10}$/),
          ])
        ),
        addressline1: new FormControl(
          '',
          Validators.compose([
            // Validators.required,
            Validators.pattern("^[a-zA-Z0-9 ,.'-]{3,100}$"),
          ])
        ),
        addressline2: new FormControl(
          '',
          Validators.compose([
            // Validators.required,
            Validators.pattern("^[a-zA-Z0-9 ,.'-]{3,100}$"),
          ])
        ),
        zipcode: new FormControl('', [
          Validators.pattern('^[0-9]{5,7}$'),
          Validators.maxLength(7),
        ]),

        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        file: new FormControl(null),
        profileImageURL: new FormControl(''),
        // userName:new FormControl(''),
      },
      {
        validators: matchpassword,
      }
    );
  }

  buildForm() {
    if (this.profileData && this.profileData.data) {
      this.updateForm.patchValue({
        firstName: this.profileData.data.firstName,
        lastName: this.profileData.data.lastName,
        emailAddress: this.profileData.data.emailAddress,
        phoneNumber: this.profileData.data.phoneNumber,
        addressline1: this.profileData.data.addressline1,
        addressline2: this.profileData.data.addressline2,
        zipcode: this.profileData.data.zipcode,
        city: this.profileData.data.city,
        state: this.profileData.data.state,
        country: this.profileData.data.country,
        profileImageURL: this.profileData.data.profileImageURL,
        userName: this.profileData.data.userName,
      });
    }
  }

  public validation_messages = {
    firstName: [{ type: 'required', message: 'First Name is required' }],
    lastName: [{ type: 'required', message: 'Last Name is required' }],
    emailAddress: [{ type: 'required', message: 'Email address is required' }],
    phoneNumber: [{ type: 'required', message: 'Enter valid phone number' }],
    // addressline1: [
    //   { type: 'required', message: 'Address 1st line is required' },
    // ],
    addressline2: [
      { type: 'required', message: 'Address 2nd line is required' },
    ],
    zipcode: [
      { type: 'maxlength', message: 'Zipcode cannot be more than 7 digits' },
    ],
    currentPassword: [{ type: 'required', message: 'oldpassword is required' }],
    newPassword: [{ type: 'required', message: 'newpassword is required' }],
    confirmation: [
      { type: 'required', message: 'confirmpassword is required' },
    ],
  };

  uploadPhoto() {
    const fileInput = document.getElementById(
      'fileDropRef1'
    ) as HTMLInputElement;
    fileInput.click();
    this.isShow = true;
  }

  onCancel() {
    this.router.navigateByUrl('/s/pages/dashboard');
  }

  onDeleteAccount() {
    this.dialogRef.open(DeleteaccountComponent);
  }

  onPauseAccount() {
    this.dialogRef.open(PauseaccountComponent);
  }

  formatPhoneNumber(event: Event) {
    const phoneNumberControl = this.updateForm.get('phoneNumber');
    if (phoneNumberControl) {
      let input = (event.target as HTMLInputElement).value.replace(/\D/g, '');

      // Limit input to 10 digits
      if (input.length > 10) {
        input = input.substring(0, 10);
      }

      phoneNumberControl.setValue(input, { emitEvent: false });
    }
  }

  handleAddressChange(address: google.maps.places.PlaceResult) {
    let addressline1: string = '';
    this.addressName = '';
    // this.updateForm.reset();

    this.mapUrl = address.url;
    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          addressline1 = `${component.long_name} ${addressline1}`;
          this.addressName = addressline1;
          break;
        }

        case 'route': {
          addressline1 = component.long_name;
          this.addressName += addressline1;

          break;
        }

        case 'locality':
          //city
          this.updateForm.get('city')?.setValue(component.long_name);
          break;

        case 'administrative_area_level_1': {
          //state
          this.updateForm.get('state')?.setValue(component.long_name);
          break;
        }
        case 'country': {
          this.updateForm.get('country')?.setValue(component.long_name);
          this.flagCode = component.short_name;
          break;
        }

        case 'postal_code': {
          this.updateForm.get('zipcode')?.setValue(component.long_name);
          break;
        }
      }
    }
    this.updateForm.get('addressline1')?.setValue(this.addressName);
  }

  getProfileDetails() {
    this.userService.getUser().subscribe((data: any) => {
      this.id = this.coreService.getBusinessId();
      this.updateProfileService.getProfileDetails(this.id).subscribe(
        (data: UpdateProfile) => {
          this.profileData = data;
          this.profileImageURL = this.profileData.data.profileImageURL;
          this.userName = this.profileData.data.userName;
          this.buildForm();
          this.updateForm.patchValue(this.profileData);
          this.changePasswordForm.patchValue({
            userName: this.profileData?.data.userName,
          });
        },
        (error) => {
          console.error('Error fetching profile details:', error);
        }
      );
    });
  }

  onSubmit(): void {
    // Check if the form is valid
    if (this.updateForm.valid) {
      const updateData = {
        id: this.id,
        firstName: this.updateForm.value.firstName,
        lastName: this.updateForm.value.lastName,
        userName: this.changePasswordForm.value.userName,
        emailAddress: this.updateForm.value.emailAddress,
        phoneNumber:
          this.updateForm.value.phoneNumber !== undefined
            ? this.updateForm.value.phoneNumber
            : '',
        // profileImageURL: this.profileImageURL,
        addressline1:
          this.updateForm.value.addressline1 !== undefined
            ? this.updateForm.value.addressline1
            : '',
        addressline2:
          this.updateForm.value.addressline2 !== undefined
            ? this.updateForm.value.addressline2
            : '',
        city:
          typeof this.updateForm.value.city === 'object'
            ? ''
            : this.updateForm.value.city,
        state:
          typeof this.updateForm.value.state === 'object'
            ? ''
            : this.updateForm.value.state,
        country:
          typeof this.updateForm.value.country === 'object'
            ? ''
            : this.updateForm.value.country,
        zipcode:
          this.updateForm.value.zipcode !== undefined
            ? this.updateForm.value.zipcode
            : '',
        status: 'updated',
        category: 'providerProfile',
        file: this.file,
      };

      // Update data since the form is valid
      this.updateProfileService
        .updateProfile(
          updateData.id,
          // updateData,
          updateData.firstName,
          updateData.lastName,
          updateData.userName,
          updateData.emailAddress,
          updateData.phoneNumber,
          // updateData.profileImageURL,
          updateData.addressline1,
          updateData.addressline2,
          updateData.city,
          updateData.state,
          updateData.zipcode,
          updateData.country,
          updateData.status,
          updateData.category,
          updateData.file
        )
        .subscribe(
          (response: any) => {
            this.showSnackbarTopPosition(
              'Profile updated successfully',
              'OK',
              1000
            );
            this.ngOnInit();
            this.updateNavBarProfileImage();
            let that = this;
            setTimeout(function () {
              that.router.navigateByUrl('/s/pages/dashboard');
            }, 1500);
          },
          (error: any) => {
            this.showSnackbarTopPosition('Error updating profile', 'OK', 2000);
          }
        );
    } else {
      console.log('Form is not valid. Data not updated.');
    }
  }

  showSnackbarTopPosition(
    content: string,
    action: string | undefined,
    duration: any
  ) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }
  onChangePasswordSubmit(): void {
    if (this.changePasswordForm.valid) {
      const currentPassword = this.changePasswordForm.value.currentPassword;
      const newPassword = this.changePasswordForm.value.newPassword;
      const confirmation = this.changePasswordForm.value.confirmation;

      this.updateProfileService
        .updatePassword(currentPassword, newPassword, confirmation)
        .subscribe(
          (response: any) => {
            if (response && response.result) {
              this.showSnackbarTopPosition(
                'Password updated successfully',
                'OK',
                2000
              );
              this.changePasswordForm.reset();
              this.router.navigateByUrl('/s/pages/dashboard');
            } else {
              this.showSnackbarTopPosition(
                'Old password is incorrect',
                'OK',
                2000
              );
              this.changePasswordForm
                .get('currentPassword')
                ?.setErrors({ incorrectOldPassword: true });
            }
          },
          (error: any) => {
            this.showSnackbarTopPosition('Error updating password', 'OK', 2000);
          }
        );
    }
  }

  updateNavBarProfileImage() {
    this.updateProfileService.getProfileDetails(this.id).subscribe((data) => {
      this.updateProfileImageData = data?.data;
      if (this.updateProfileImageData) {
        let setProfileImage = top?.document.getElementById(
          'navBarProfileImage'
        ) as HTMLImageElement;

        if (
          this.updateProfileImageData.profileImageURL &&
          this.updateProfileImageData.profileImageURL !== undefined
        ) {
          if (this.updateProfileImageData.profileImageURL !== '')
            setProfileImage.src = this.updateProfileImageData.profileImageURL;
        } else {
          setProfileImage.src = '../../../assets/images/grey.jpg';
        }
      }
    });
  }
  toggleVisibility(field: string): void {
    if (field === 'currentPassword') {
      this.hideCurrentPassword = !this.hideCurrentPassword;
    } else if (field === 'newPassword') {
      this.hideNewPassword = !this.hideNewPassword;
    } else if (field === 'confirmation') {
      this.hideConfirmation = !this.hideConfirmation;
    }
  }
  updatePhoneNumberValidity() {
    const phoneNumberControl = this.updateForm.get('phoneNumber');
    if (phoneNumberControl) {
      phoneNumberControl.updateValueAndValidity();
    }
  }
  onKeyPress(event: KeyboardEvent): void {
    const pattern = /^[0-9]$/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  handleInput(event: any, formControlName: string) {
    const input = event.target;
    const value = input.value;
    const filteredValue = value.replace(/[^a-zA-Z ]/g, '');
    if (value !== filteredValue) {
      this.updateForm.get(formControlName)?.setValue(filteredValue);
    }
  }

  fileBrowseHandler(event: Event, category?: any) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.handleFile(input.files[0], category, input.files);
    }
  }

  async convertHeicToJpeg(file: File): Promise<Blob> {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
      });
      return convertedBlob as Blob;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      throw error;
    }
  }

  private async handleFile(
    file: File,
    category: any,
    files: any
  ): Promise<void> {
    if (
      file?.name?.split('.')[1] === 'HEIC' ||
      file?.name?.split('.')[1] === 'heic'
    ) {
      try {
        const jpegBlob = await this.convertHeicToJpeg(file);
        this.profileimage = URL.createObjectURL(jpegBlob);
        const filesJpeg = new File([jpegBlob], file?.name?.split('.')[0]);
        this.uploadFiles(filesJpeg, category);
      } catch (error) {
        console.error('Error processing file:', error);
      }
    } else if (file.type.match('image.*')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.profileimage = event.target?.result;
      };
      reader.readAsDataURL(file);
      this.prepareFilesList(files, category);
    } else {
      console.error('Unsupported file type');
    }
  }

  prepareFilesList(files: Array<any>, category?: any) {
    let fileUploadSizeExceeds = false;
    let fileUploadSizeFailedList = '';
    for (const item of files) {
      item.completed = false;
      if (Number(item.size) < 8e11) {
        this.files.push(item);
        this.uploadFiles(item, category);
      } else {
        if (fileUploadSizeExceeds) {
          fileUploadSizeFailedList += ',  ';
        }
        fileUploadSizeFailedList += item.name;
        fileUploadSizeExceeds = true;
      }
    }
    if (fileUploadSizeExceeds) {
      let snackBarMessage =
        fileUploadSizeFailedList +
        ' is greater than 10 MB. Please upload file less than 100 MB ';
      this.snackBar.open(snackBarMessage, 'X', {
        duration: 7 * 1000,
        panelClass: ['error-snackbar'],
      });
    }
    this.handleFormValue();
  }

  formatBytes(bytes: number, decimals: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 100240;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  handleFormValue() {
    if (this.files.length + this.data.total > 0) {
      this.onChange(this.files.length + this.data.total);
    } else {
      this.onChange(null);
    }
  }

  displayDragAndDropArea() {
    if (this.isReadOnly) {
      return false;
    } else {
      return true;
    }
  }

  getFilesUploaded() {
    if (this.listingid) {
      this.filesApiService.getFiles(this.listingid).subscribe((response) => {
        if (response && response?.data && response?.data?.galleryImageUrl) {
          this.profileimage = response?.data?.galleryImageUrl;
        }
        this.handleFormValue();
      });
    }
  }

  uploadFiles(file: any, category: any) {
    this.file = file;
    this.fileName = file.name;
    this.sendFile.emit(file);
    this.getFilesUploaded();
  }

  onFileDropped(file: any, category?: any) {
    this.handleFile(file?.[0], category, file);
  }

  delImg() {
    this.profileimage = '';
    this.isShow = false;
  }
}
