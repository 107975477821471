import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from 'src/app/_services/core.services';
import { SavedService } from 'src/app/_services/saved.service';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';

@Component({
  selector: 'app-savedbyme-message-dialog',
  templateUrl: './savedbyme-message-dialog.component.html',
  styleUrl: './savedbyme-message-dialog.component.css'
})
export class SavedbymeMessageDialogComponent {
  sendMessageForm: FormGroup;
  customerSavedMe: any;
  sendMessageData: any;
  sendListingEmail: any;
  userInfo: any;
  savedMemberType: any;
  memberType: any;
  listingId: any;
  listingName:any;

  constructor(@Inject(MAT_DIALOG_DATA)
  private data: any,
  public _formBuilder: FormBuilder,
  private savedService: SavedService,
  private snackBar: MatSnackBar,
  private coreService: CoreService,
  private listingInformationService: ListingInformationService
  ){
  }

  ngOnInit(){
    this.savedMemberType=this.data.savedMemberType;
    
    this.mainForm();
    this.userInfo = this.coreService.getUserDetails();
    this.settingSavedByMeName();
    this.getPrimaryListing();

    console.log(this.savedMemberType,'dat')
  }

  mainForm() {
    this.sendMessageForm = this._formBuilder.group({
      firstName: [''],
      subjectContent: ['',Validators.required],
      messageContent: ['',Validators.required],
      
    });
  }

  settingSavedByMeName(){
      if (this.data.savedMemberType === "Customer")
        this.memberType="Customer"
      else if (this.data.savedMemberType === "Provider")
        this.memberType="Listings"
      this.savedService.getFavoriteCustomers(this.memberType).subscribe((res) => {
        if (res?.list && res?.list.length > 0)
          {
              this.customerSavedMe = res?.list;
              this.sendMessageData = this.customerSavedMe.filter((r: any) => r.savedMemberId === this.data.savedByMeid);
              if (this.sendMessageData[0].savedMemberType === "Customer")
                {
                    this.sendMessageForm.patchValue({
                    firstName: this.sendMessageData[0].savedMemberName
                    })
                }
                else if (this.sendMessageData[0].savedMemberType === "Provider")
                {
                    this.sendMessageForm.patchValue({
                    firstName: this.sendMessageData[0].savedMemberName
                    })
                }
            }
      })
      this.onMessageFormSubmit();
  }

  onMessageFormSubmit(){
    if (this.userInfo && this.sendMessageData)
      {
        let obj =  {
          //senderEmail: this.userInfo.email,
          senderName: this.listingName,
          receiverName: this.sendMessageData[0].savedMemberName,
          subjectContent: this.sendMessageForm.value.subjectContent,
          messageContent: this.sendMessageForm.value.messageContent,
          receiverId: this.data.savedByMeid,
          senderId: this.listingId
        }
        this.savedService.sendMessageTo(obj).subscribe((data)=>{
            this.showSnackbarTopPosition('Message Sent successfully', 'Done', '1000');
        })
      }
  }

  showSnackbarTopPosition(content: string, action: string | undefined, duration: any) {
    this.snackBar.open(content, action, {
      duration: 2000,
      verticalPosition: "top", // Allowed values are  'top' | 'bottom'
      horizontalPosition: "right" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
    });
  }
  getPrimaryListing() {
    this.listingInformationService.getPrimaryListing().subscribe((data) => {
      if (data && data.listingName) {
        this.listingName = data.listingName;
        this.listingId = data.id;
        console.log('Primary Listing:', this.listingName);
        console.log('Sender ID:', this.listingId);
        this.sendMessageForm.patchValue({
          senderName: this.listingName
        });
      } else {
        console.log('Primary Listing not found.');
      }
    });
  }
}
