<div class="row" style="position: relative;">
  <div class="col-md-12" >
    <p>Upload Images</p>
    <button mat-raised-button style="float: right;" type="button" *ngIf="galleryimage.length !== 0" (click)="delImg()"><mat-icon>delete</mat-icon></button>
    <div
  *ngIf="displayDragAndDropArea()"
  multipleAppDragAndDropImg
  class="file-container col-md-12"
  (fileDropped)="onFileDropped($event)"
>


  <span class="material-icons" *ngIf="galleryimage.length == 0">cloud_upload</span>
  <div style="font-size: 12px" *ngIf="galleryimage.length == 0">Drag and Drop here</div>

  <div style="font-size: 12px" *ngIf="galleryimage.length == 0">or</div>
  <input
    class="file-container"
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="fileBrowseHandler($event)"
    *ngIf="!galleryimage.length"
    multiple
  />

  <label for="fileDropRef" *ngIf="galleryimage.length == 0">BROWSE FILES</label>

  <div style="font-size: 12px" >(Max file size: 100MB)</div>
  <ng-container *ngIf="galleryimage.length !== 0">
    <div class="row">
<div class="col-md-3" *ngFor="let image of galleryimage">
  <img [src]="image"  style="height: 300px; width: 100%;">
</div>
</div>
</ng-container>
  <!-- <img [src]="bannerimagurl" alt="banner image" *ngIf="bannerimagurl !=='' && categoryType =='Cover' " style="height: 500px; width: 100%; object-fit: cover;"> -->

  <!-- <mat-icon  (click)="openDeleteConfirmationDialog(listingid)">delete</mat-icon> -->
</div>
<ng-template *ngIf="data.total !== 0">
<div class="files-list" >
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="file-icon" style="width: 50px">
      <span class="material-icons">attachment</span>
    </div>

    <div class="item-info" *ngIf="!file?.completed">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size, file?.decimal) }}
      </p>
      <div class="row">
        <div class="progress-cont" *ngIf="!file?.completed">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="file?.completed">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <h4 class="Success">Uploaded Successfully</h4>
    </div>
  </div>
</div>
</ng-template>
  </div>
</div>

<!--
<ng-template #callDeleteConfirmationDialog>
  <form #userForm="ngForm">
    <h2 matDialogTitle style="text-align: center">Delete Confirmation</h2>
    <p style="font-weight: '400'; text-align: center">
      Are you sure you want to delete the file: "{{ fileToBeDeleted }}" ?
    </p>
    <mat-dialog-actions allign="center" style="justify-content: center">
      <button mat-raised-button matDialogClose="no">No</button>
      <button
        class="btn-primary"
        type="submit"
        mat-raised-button
        matDialogClose="yes"
      >
        Yes
      </button>
    </mat-dialog-actions>
  </form>
</ng-template> -->
