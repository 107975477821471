import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CoreService } from 'src/app/_services/core.services';
import { ListingInformationService } from 'src/app/_services/listingInformation.service';
import { ReviewService } from 'src/app/_services/review.services';
import { StarRatingColor } from 'src/app/shared/star-rating/star-rating.component';

@Component({
  selector: 'app-display-review-content',
  templateUrl: './display-review-content.component.html',
  styleUrl: './display-review-content.component.css'
})
export class DisplayReviewContentComponent {

  reviewId: any;
  reviewData: any;
  formattedReviewDescription: any;
  listingData: any;
  listingDatas: any;
  alllistings: any;
  reviews: any;
  reviewDetails: any;
  formattedReplyContent: any;
  singleData: any;
  businessid: any;
  primary: any;
  isloader: any = true;
  listingCity: any;
  listingState: any;
  starCount: number = 5;
  starCountOverall: any;
  ratingOverall: any;
  starColor: StarRatingColor = StarRatingColor.accent;



  constructor( @Inject(MAT_DIALOG_DATA)
  private data: any,
  public dialog: MatDialog,
  public reviewService: ReviewService,
  private listingInformationService: ListingInformationService,
  private coreService: CoreService,
  ){
  }

  ngOnInit(){
    this.businessid = this.coreService.getBusinessId();
    this.getReviewById();
    this.getReplyDetails();
    // this.getPrimaryList();
  }

  getReviewById(){
      this.reviewData = this.data.reviewData;
      // let reviewDetails = this.reviewData.listingAddress.split('\n} {\n');
      // const jsonObjects = reviewDetails.map((str:any, index: any, arr: any) => {
      //   if (index === 0) return JSON.parse(str + '\n}');
      //   if (index === arr.length - 1) return JSON.parse('{\n' + str);
      //   return JSON.parse('{\n' + str + '\n}');
      // });
      // this.listingCity = jsonObjects[0].name;
      // this.listingState = jsonObjects[1].name;
      this.formattedReviewDescription=this.reviewData?.reviewDescription.replace(/\n/g, '<br/>');
      // this.getAllListings();
  }

  // getAllListings(){
  //    this.listingInformationService.getListings().subscribe((data)=>{
  //    this.alllistings = data;
  //    this.listingDatas = this.alllistings.filter((r: any) => r.id === this.data.reviewData.reviewToListingId);
  //      let sortedDataList = this.listingDatas.map((element:any) => {
  //        if(element.cityName && this.isValidJSON(element.cityName)){
  //          element.cityName = JSON.parse(element.cityName).name
  //        }
  //        if(element.state && this.isValidJSON(element.state)){
  //          element.state = JSON.parse(element.state).name
  //        }
  //        if(element.country && this.isValidJSON(element.country)){
  //          element.country = JSON.parse(element.country).name
  //        }
  //        return element;
  //      });
  //      this.listingData = sortedDataList;
  //    });
  //  }
  //  isValidJSON(str:any) {
  //  try {
  //    JSON.parse(str);
  //    return true;
  //  } catch (e) {
  //    return false;
  //  }
  //  };

  //  getReplyById(){
  //   this.reviewService.getReview().subscribe((data) => {
  //     this.reviews = data?.list;
  //     this.reviewDetails = this.reviews.filter((r:any) => r.id === this.data.reviewData.id);
  //   });
  // }
  getPrimaryList(){
    this.listingInformationService.getPrimary(this.businessid).subscribe((data)=>{
      setTimeout(() =>{
        this.primary = data;
         this.isloader = false;
        },2000)
    })
  }

  getReplyDetails(){
    this.reviewService.getReviewReply(this.data.reviewData.id).subscribe((data)=>{
      this.reviewDetails = data?.data;
      console.log(this.reviewDetails,'rplydet')
      this.formattedReplyContent=this.reviewDetails?.replyContent.replace(/\n/g, '<br/>');
      this.singleData =  data?.data;

  })
  }

}
