import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IPromotions } from '../_models/promotion.model';
import { ResponseModal } from '../_models/response-modal';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService {
  constructor(private httpClient: HttpClient) {}

  //getAll Promotions
  getPromotions(businessId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/promotions/provider`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getPromotionById(promotionId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/promotionsbyid/${promotionId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getPromotionByListingId(listingId: string){
    return this.httpClient
    .get<any>(`${environment.apiUrl}/publicUri/promotions/listing?listingId=${listingId}`)
    .pipe(
      map((response) => {
        return response || {};
      }),
      catchError(this.errorMgmt)
    );
  }

  //get Promotion
  getPromotion(listingId?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/publicUri/promotions/listing?listingId=${listingId}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //update Promotion
  updatePromotion(id: any, promotionInfo: IPromotions): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(`${environment.apiUrl}/promotions`,  { promotionInfo, id }, { headers })
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  updateStatus(id: string, toggleStatus: boolean): Observable<any> {
    const headers = { 'content-type': 'application/json' };
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/promotions/statusupdate?id=${id}&toggleStatus=${toggleStatus}`,
        { headers }
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  getPromotionByStatus(selectedTab?: any): Observable<any> {
    return this.httpClient
      .get<ResponseModal>(
        `${environment.apiUrl}/promotions/provider?status=${selectedTab}`
      )
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  getProvider(businessId: any) {
    return this.httpClient
      .get<any>(`${environment.apiUrl}/businessproviderbyId/${businessId}`)
      .pipe(
        map((response) => {
          return response || {};
        }),
        catchError(this.errorMgmt)
      );
  }

  //delete Promotion
  deletePromotion(promotionId: string): Observable<any> {
    return this.httpClient
      .delete<any>(`${environment.apiUrl}/promotions/${promotionId}`)
      .pipe(
        catchError((err) => {
          console.error(err);
          throw err;
        })
      );
  }

  //error Handling
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = ' ';
    if (error.error instanceof ErrorEvent) {
      //get client side error
      errorMessage = error.error.message;
    } else {
      //get serverside error
      errorMessage = `Error: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
